<template>
  <div>
    <b-sidebar width="700px" id="ship-details" shadow>
      <div class="px-3 py-2">
        <b-badge variant="light">DETAILS</b-badge><br><br>
        <b-card v-if="selected_row">
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Device ID:</b></b-col>
            <b-col class="listsmall">{{selected_row.deviceId}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Email:</b></b-col>
            <b-col class="listsmall">{{selected_row.email}}</b-col>
          </b-row>
          <hr>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Ship name:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.name}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Category:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.category}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Type:</b></b-col>
            <b-col class="listsmall">{{getAppName(selected_row.applicationId)}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>ECDIS brand:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.ecdisBrand}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>IMO:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.imo}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Callsign:</b></b-col>
            <b-col v-if="selected_row.primarInfo && selected_row.primarInfo.shipInfo" class="listsmall">{{selected_row.primarInfo.shipInfo.callSign}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Permit:</b></b-col>
            <b-col v-if="selected_row.primarInfo" class="listsmall">{{selected_row.primarInfo.primarPermit}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Notifications emails:</b></b-col>
            <b-col v-if="selected_row.primarInfo" class="listsmall">{{selected_row.primarInfo.notificationEmails}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Charts:</b></b-col>
            <b-col v-if="selected_row.primarInfo" class="listsmall">
              <b-table striped :busy="is_busy" :items="$store.state.proto.ecdis_charts" :fields="visible_fields">
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-sidebar>
  </div>
</template>

import {getAppName} from "@/utils/utils";

<script>
import {getAppName} from "@/utils/utils";
import Charts from "@/views/transactions/sidebars/Charts.vue";

export default {
  name: "EcdisDetails",
  components: {Charts},
  props: ["selected_row"],
  computed: {
    is_busy() {
      return this.$store.state.proto.ecdis_charts === 0
    },
    visible_fields() {
      return [
        {key: 'id', label: 'ID', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'title', label: 'Title', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'band', label: 'Band', tdClass: 'small-black', thClass: 'small-gray'}
      ]
    }
  },
  methods: {
    getAppName
  }
}
</script>

<style scoped>

</style>