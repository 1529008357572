<template>
<div>
<!--  <b-sidebar width="700px" id="imported-sidebar" shadow>-->
<!--    <div class="px-3 py-2">-->
<!--      <b-badge variant="light">IMPORTED ORDER</b-badge><br><br>-->
<!--      <b-card>-->
<!--        <b-row class="mb-2">-->
<!--          <b-col sm="12">-->
<!--              <span class="smt2s">-->
<!--                  <b-table striped :items=imported_order :fields="imported_fields"></b-table>-->
<!--              </span>-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-card>-->
<!--    </div>-->
<!--  </b-sidebar>-->

  <ship :data=current.data></ship>
  <payment :data=current.data></payment>
  <price :data=current.data></price>
  <status :data=current.data></status>
  <items :data=current.data></items>
  <items-edit :data=current.data></items-edit>
  <new-order></new-order>
  <charts></charts>
  <messages :data=current.data></messages>
  <delivery :data=current.data></delivery>



<!--  <b-button size="sm" class="mb-3" variant="info" @click="newOrder()">Add order</b-button>-->

<!--  <b-dropdown split size="sm" variant="success" text="Add request">-->
<!--    <b-dropdown-item @click="uploadFile()">From Primar CSV</b-dropdown-item>-->
<!--    <b-dropdown-item>From Excel</b-dropdown-item>-->
<!--    <b-dropdown-item>From Map</b-dropdown-item>-->
<!--  </b-dropdown>-->
  <!--      id="orderstable"-->
  <b-table
      ref="orderstable1"
  class="secondary"
  :items="visibleRows()"
  :fields="visible_fields"
  :sort-by.sync="sortBy"
  :sort-desc.sync="sortDesc"
  :busy="isBusy"
  select-mode="single"
  selectable
  show-empty
  responsive
  sort-icon-left
  small
  hover
  >
    <template #cell(order_date)="data">
      {{data.value}}
    </template>
    <template #cell(ecdis_name)="row">
      <b-link @click="showShip(row.item)">{{ row.item.ecdis_name }}</b-link>
    </template>
    <template #cell(product)="row">
      <b-link @click="showItems(row.item)">{{row.item.product}}</b-link>
    </template>

    <template #cell(messages[0].date)="row">
      <b-link v-if="row.item.messages" @click="showMessages(row.item)">{{row.item.messages[0].date}}</b-link>
    </template>
    <template #cell(delivery[0].date)="row">
      <b-link v-if="row.item.delivery" @click="showDelivery(row.item)">{{row.item.delivery[0].date}}</b-link>
    </template>


    <template #cell(payment)="row">
      <b-link @click="showAgreement(row.item)">{{ row.item.payment }}</b-link>
    </template>
    <template #cell(total_price)="row">
      <b-link @click="showPrice(row.item)">${{ row.item.total_price }}</b-link>
    </template>
    <template #cell(order_status)="row">
      <div v-if="row.item.status !== 'request' && row.item.order_status !== 'order'">
        <b-link @click="showStatus(row.item)">{{ row.item.order_status }}</b-link>
      </div>
      <div v-else>
        <b-link @click="showStatus(row.item)" class="smt12">{{ row.item.status }}</b-link>
      </div>
    </template>
    <template #cell(delivery)="row">
      <b-link target="_blank" href="https://primar.ecc.no/primar/vexport/25ebafa3-f683-4db5-b7e2-ae29d045428a" v-if="row.item.delivery === 'link'">https://primar.ecc.no/primar/vexport/25ebafa3-f683-4db5-b7e2-ae29d045428a</b-link>
    </template>
  </b-table>

</div>
</template>

<script>
import store from "@/store";
import {CSVParse, excelParser, formatDate, getAppName, getAppType} from "@/utils/utils";
import Ship from "@/views/transactions/sidebars/Ship.vue";
import Payment from "@/views/transactions/sidebars/Payment.vue";
import Items from "@/views/transactions/sidebars/Items.vue";
import ItemsEdit from "@/views/transactions/sidebars/ItemsEdit.vue";
import Charts from "@/views/transactions/sidebars/Charts.vue";
import Price from "@/views/transactions/sidebars/Price.vue";
import Status from "@/views/transactions/sidebars/Status.vue";
import Messages from "@/views/transactions/sidebars/Messages.vue";
import Delivery from "@/views/transactions/sidebars/Delivery.vue";
import NewOrder from "@/views/transactions/sidebars/AddOrder.vue";
import {eventBus} from "@/main";

export default {
  name: "Transactions",
  components: {Status, Price, Charts, Items, ItemsEdit, Payment, Ship, Messages, Delivery, NewOrder},
  data() {
    return {
      source1: 'report.pdf',
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      isBusy: false,
      current: {
        status_id: '',
        data: null
      },
    }
  },
  mounted() {
    eventBus.$on('refresh-orders-table', () => {
      this.$refs.orderstable1.refresh();
    })
    // this.$store.dispatch("proto/getProto")
    // this.$store.dispatch("transactions/getTransactions", this.$store.state.auth.user.displayId);
  },
  computed: {
    current_status() {
      let item = this.$store.state.transactions.status.find(obj => {
        return obj.id === this.current.status_id
      })

      return item === undefined ? "" : item.status;
    },
    current_history() {
      let item = this.$store.state.transactions.status.find(obj => {
        return obj.id === this.current.status_id
      })

      return item === undefined ? "" : item.history;
    },
    // imported_order() {
    //   return this.$store.state.transactions.imported_order
    // },
    // history_fields() {
    //   return [
    //     {key: 'timeStamp', label: 'Time', tdClass: 'small-black', thClass: 'small-gray'},
    //     {key: 'status', label: 'Status', tdClass: 'small-black', thClass: 'small-gray'},
    //     {key: 'changedBy', label: 'Changed by', tdClass: 'small-black', thClass: 'small-gray'},
    //   ]
    // },
    imported_fields() {
      return [
        {key: '0', label: 'Item', tdClass: 'small-black', thClass: 'small-gray'},
        {key: '1', label: 'Title', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
    visible_fields() {
      return [
        {key: 'order_number', label: 'ID', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'order_date', label: 'Date', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'order_status', label: 'Status', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'ecdis_name', label: 'Ship', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'product', label: 'Items', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'payment', label: 'Payment', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'total_price', label: 'Price', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'messages[0].date', label: 'Messages', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'delivery[0].date', label: 'Delivery', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray', class: 'truncate_name'},
      ]
    }
  },
  methods: {
    formatDate,
    providers(products) {
      let providers = ''
      for (const element of products) {
        providers += element.provider + ", "
      }
      return providers.slice(0, -2)
    },
    shipName(device) {
      let val = 'unknown'
      if (device !== null)
        val = device.shipName !== null ? device.shipName : device.deviceId

      return val
    },
    newOrder() {
      this.$root.$emit('bv::toggle::collapse', 'new-order-sidebar');
    },
    showItems(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'items-sidebar');
    },

    showMessages(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'messages-sidebar');
    },
    showDelivery(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'delivery-sidebar');
    },


    showPrice(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'price-sidebar');
    },
    showStatus(data) {
      // console.log("aaaaa")
      // console.log(data.id)
      // console.log(data)
      // this.current.status_id = data.id;
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'status-sidebar');
    },
    showStatusRequest(data) {
      // this.current.status_id = data.id;
      this.current.data = this.$store.state.proto.transactions.at(0)
      this.$root.$emit('bv::toggle::collapse', 'status-sidebar');
    },
    showShip(data) {
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'ship-sidebar');
    },
    showAgreement(data) {
      console.log(data.paymentMethod)
      this.current.data = data;
      this.$root.$emit('bv::toggle::collapse', 'payment-sidebar');
    },
    visibleRows() {
      // let orders = this.$store.state.transactions.transactions;
      let orders = this.$store.state.proto.transactions;


      //     [
      //     {a1:"13994", a2:"2023-06-06 00:21", a3:"ANADYR (Ellcom Co.Ltd)", a4:"Primar, Navicom", a5:"agreement", a6:"359.3 ₽", a7:"completed"},
      //     {a1:"14022", a2:"2023-06-06 00:21", a3:"NAVIS 7 (Ellcom Co.Ltd)", a4:"Primar", a5:"agreement", a6:"359.3 ₽", a7:"completed"}
      // ];
      return orders;
    },

    // uploadFile() {
    //   let input = document.createElement('input');
    //   input.type = 'file';
    //   input.onchange = e => {
    //     // getting a hold of the file reference
    //     let file = e.target.files[0];
    //     console.log(file.name)
    //
    //     if (!file.name.endsWith(".csv"))
    //       return
    //
    //     // setting up the reader
    //     let reader = new FileReader();
    //     reader.readAsText(file, 'UTF-8');
    //
    //     // here we tell the reader what to do when it's done reading...
    //     let content;
    //     reader.onload = readerEvent => {
    //       content = readerEvent.target.result; // this is the content!
    //       console.log(content);
    //
    //       let CSVarray = CSVParse(content);
    //       console.log(CSVarray)
    //       this.$store.dispatch("transactions/importOrder", CSVarray);
    //       this.$root.$emit('bv::toggle::collapse', 'imported-sidebar');
    //     }
    //   }
    //   input.click();
    // },

    // uploadFile() {
    //   console.log("aaaaaaaaa")
    //   excelParser()
    // },
  },
}
</script>

<style scoped>
.truncate_name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>