import { render, staticRenderFns } from "./Status.vue?vue&type=template&id=6dc85d54&scoped=true&"
import script from "./Status.vue?vue&type=script&lang=js&"
export * from "./Status.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc85d54",
  null
  
)

export default component.exports