<template>
  <b-sidebar width="700px" id="imported-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">IMPORTED ORDER</b-badge><br><br>
      <b-card>
        <b-row class="mb-2 small-cyan">
          <b-col sm="3">
            <b>Selected ship:</b>
          </b-col>
          <b-col>
            {{ $store.state.transactions.selected_ship.name }}
          </b-col>
        </b-row>
        <br>
        <b-row class="mb-2">
          <b-col sm="12">
              <span class="smt2s">
                  <b-table striped :items=imported_order :fields="imported_fields">
                        <template #cell(edit)="data">
                          <b-icon variant="danger" icon="x" font-scale="1.5"></b-icon>
                        </template>
                  </b-table>
              </span>
          </b-col>
        </b-row>
        <b-button size="sm" @click="placeRequest()" variant="info">Place request</b-button>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "Imported",
  computed: {
    imported_order() {
      return this.$store.state.transactions.imported_order
    },
    imported_fields() {
      return [
        {key: 'edit', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: '0', label: 'Item', tdClass: 'small-black', thClass: 'small-gray'},
        {key: '1', label: 'Title', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    placeRequest() {
      this.$root.$emit('bv::toggle::collapse', 'imported-sidebar');
      this.$store.dispatch("proto/updateProto1")
      this.$root.$emit('bv::refresh::table', 'orders-table');
      // this.$refs.orders-table1.refresh();
      // eventBus.$emit('refresh-orders-table');
    }
  }
}
</script>

<style scoped>

</style>