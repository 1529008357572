<template>
  <div>
    <b-badge v-if="hasNewOrders()" variant="warning">new requests</b-badge><br>
    Agreement: {{ currentAgreement }}
    <b-button v-if="currentAgreement !== 'ALL'" class="border-0 shadow-none" size="sm" variant="outline-danger" @click="clearSelectedAgreement">
               <b-icon icon="x" font-scale="1.5"></b-icon>
    </b-button>
<!--    User: {{ currentUser }}-->
<!--    <b-button v-if="currentUser !== 'ALL'" class="border-0 shadow-none" size="sm" variant="outline-danger" @click="clearSelectedUser">-->
<!--      <b-icon icon="x" font-scale="1.5"></b-icon>-->
<!--    </b-button>-->


    <b-table
        id="ordersTable"
        class="secondary"
        :items="visible_rows()"
        :fields="visible_fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        select-mode="single"
        selectable
        show-empty
        responsive
        sort-icon-left
        small
        hover
        @row-selected="onRowSelected"
    >

      <template #cell(expand)="row">
        <b-button v-if="!row.detailsShowing" class="border-0 shadow-none" size="sm" variant="outline-info" @click="row.toggleDetails">
           <b-icon icon="arrows-expand" font-scale="1.0"></b-icon>
        </b-button>
        <b-button v-else class="border-0 shadow-none" size="sm" variant="outline-info" @click="row.toggleDetails">
          <b-icon icon="arrows-collapse" font-scale="1.0"></b-icon>
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Application:</b></b-col>
            <b-col>{{ getAppName(row.item.device.applicationTypeId) }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Customer:</b></b-col>
            <b-col>{{ row.item.device.shipName }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Email:</b></b-col>
            <b-col>{{ row.item.user.email }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Device ID:</b></b-col>
            <b-col>{{ row.item.device.deviceId }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Agreement ID:</b></b-col>
            <b-col>{{ row.item.agreementId }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Price:</b></b-col>
            <b-col>{{ row.item.priceTotal }} {{ row.item.currency }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Created:</b></b-col>
            <b-col>{{ formatDate(row.item.createdAt).datetime }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Price calculation:</b></b-col>
            <b-col>
              <span class="smt2s">
                <b-table striped :items=price_table></b-table>
              </span>
            </b-col>
          </b-row>


          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Maps:</b></b-col>
            <details>
              <summary>cells ({{row.item.orderItems[0].duration}} months)<span class="small-black"></span>
              </summary>
              <ul v-for="(item, index) in row.item.orderItems">
                <li>
                  {{item.id}}, {{ item.currency === 'RUB' ? item.price + ' ₽' : '$' + item.price}}
                </li>
              </ul>
            </details>
          </b-row>


          <b-row v-if="row.item.status === 'new'" class="mb-2 mt-4">
            <b-col sm="3" class="text-sm-right">
            </b-col>
            <b-col>
              <b-button size="sm" variant="success">Approve</b-button>&nbsp;
              <b-button size="sm" variant="danger">Cancel</b-button>
            </b-col>
          </b-row>

        </b-card>
      </template>

      <template #cell(status)="data">
        <span v-if="data.value === 'request'">
          <b-badge variant="warning">{{data.value}}</b-badge><br>
        </span>
        <span v-if="data.value === 'processing'">
          <b-badge variant="info">{{data.value}}</b-badge><br>
        </span>
        <span v-if="data.value === 'error'">
          <b-badge variant="danger">{{data.value}}</b-badge><br>
        </span>
        <span v-if="data.value === 'complete'">
          <b-badge variant="success">completed</b-badge><br>
        </span>
        <span v-if="data.value === 'ready_to_publish'">
          <b-badge variant="info">publishing</b-badge><br>
        </span>
        <span v-if="data.value === 'cancelled_by_distirbutor'">
          <b-badge variant="dark">cancelled</b-badge><br>
        </span>
      </template>

      <template #cell(device)="data">
        {{ data.value.shipName }}
      </template>

      <template #cell(priceTotal)="row">
        {{ row.item.currency === 'RUB' ? row.item.priceTotal + ' ₽' : '$' + row.item.priceTotal }}
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner small type="grow" variant="warning"></b-spinner>
          <span class="small-gray">  loading...</span>
        </div>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDate(data.value).datetime }}
      </template>


    </b-table>
  </div>
</template>

<script>
import store from "@/store";
import {eventBus} from "@/main";
import {formatDate, getAppName} from "@/utils/utils";

export default {
  name: "Orders",
  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      isBusy: false,
      price_table: [
        {Margin:"Net price", Value:"1000 ₽"},
        {Margin:"Full price", Value:"2000 ₽"},
        {Margin:"Distributor", Value:"200 ₽ (20%)"},
        {Margin:"MIT", Value:"800 ₽ (80%)"},
      ]
    }
  },
  watch: {
    '$store.state.distributor.is_orders_loading': function () {
      this.isBusy = this.$store.state.distributor.is_orders_loading;
    }
  },
  mounted() {
    this.$store.dispatch("distributor/getOrders");
  },
  computed: {
    visible_fields() {
      return [
        { key: 'expand', label: '', sortable: false, tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'device', label: 'Ship', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'priceTotal', label: 'Price', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'createdAt', label: 'Date', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
      ]
    },
    currentAgreement() {
      let currentId = this.$store.state.distributor.selected.agreement_id;
      let currentName = this.$store.state.distributor.selected.agreement_name;
      let currentDistributor = this.$store.state.distributor.selected.agreement_distributor;
      return currentId === '' ? 'ALL' : '(' + currentId + ') ' + currentName + ', ' + currentDistributor;
    },
    currentUser() {
      let currentId = this.$store.state.distributor.selected.user_id;
      let currentEmail = this.$store.state.distributor.selected.user_email;
      return currentId === '' ? 'ALL' : currentEmail + ' (' + currentId + ')';
    }

  },
  methods: {
    getAppName,
    formatDate,
    hasNewOrders() {
      let orders = this.visible_rows();
      let obj = orders.find(o => o.status === 'new');

      return obj !== undefined
    },
    visible_rows() {
      let selectedOrders = this.$store.state.distributor.orders;
      let selectedAgreementId = this.$store.state.distributor.selected.agreement_id;
      // let selectedUserId = this.$store.state.distributor.selected.user_id;

      if (selectedAgreementId !== "") {
        selectedOrders = selectedOrders.filter(function (e) {
          return e.agreementId === selectedAgreementId;
        })
      } else {
        let agreements = [7, 8, 9, 10, 11, 12];
        selectedOrders = selectedOrders.filter(function (e) {
          return agreements.includes(e.agreementId);
        })
      }

      // if (selectedUserId !== "") {
      //   selectedOrders = selectedOrders.filter(function (e) {
      //     return e.user_id === selectedUserId;
      //   });
      // }
      return selectedOrders;
    },
    onRowSelected(items) {
      if (items.length > 0) {
        store.commit("distributor/setCurrentOrder", {id: items[0].id });
      } else {
        store.commit("distributor/clearCurrentOrder");
      }


    },
    clearSelectedAgreement() {
      eventBus.$emit('clearAgreementsTableSelection');
      store.commit("distributor/clearCurrentAgreement");
    },
    clearSelectedUser() {
      eventBus.$emit('clearUsersTableSelection');
      store.commit("distributor/clearCurrentUser");
    }

  }
}
</script>

<style scoped>

</style>