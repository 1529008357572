<template>
  <b-sidebar width="700px" id="items-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">ITEMS</b-badge><br><br>
      <b-card v-if="data">
<!--        <div v-for="(item, index) in data.cells">-->
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-left"><b>Primar:</b></b-col>
            <b-col class="listsmall">
              <b-table striped :items="data.cells" :fields="visible_fields"></b-table>
            </b-col>
          </b-row>
<!--        </div>-->
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: "Items",
  props: ["data"],
  computed: {
    visible_fields() {
      return [
        {key: 'id', label: 'ID', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'name', label: 'Name', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  }
}
</script>

<style scoped>

</style>