import myAxios from "@/services/myAxios";
import {file} from "paths.macro";
import json from "@/store/modules/_a_installations.json";
import json1 from "@/store/modules/_a_installations.json";
import {eventBus} from "@/main";
import json2 from "@/store/modules/_a_transactions.json";
import Vue from "vue";
import {addIndexToArray} from "@/utils/utils";
import * as utils from "@/utils/utils";
import store from "@/store";

const initialState = {
    order: {
        step: "",
        order_status: "Request",
        order_number: null,
        order_date: null,
        device_id: null,
        application_id: null,
        ecdis_name: null,
        ecdis_imo: null,
        total_price: null,
        total_priceNet: null,
        total_priceAdd: null,
        total_priceRUB: null,
        cells: [],
        period: null,
        customer: null,
        product: null,
        payment: null,
        bands: [],
        agreement: {},
        products: [],
    },
    agreement: {
        exist: false,
        buzy: true,
        id: -1,
        distributor: '',
        validUntil: '',
        currency: ''
    },
    ecdis_charts: [],
    ecdis_charts1: [],


    temp: 1,
    installations: [],
    transactions: [],
    a1: [],
    a2: [],
    selected_ecdis: null,
    order_number: null,
    order_status: "Request",
    order_state: "sumbit",
    request_placed: false,
    show: false,
}

export const proto = {
    namespaced: true,
    state: initialState,
    actions: {
        getInstallations({dispatch, commit}) {
            let URL = "api/v1/installations";
            myAxios.get(URL).then(resp => {
                let instList = resp.data.userInfos;
                instList = addIndexToArray(instList);
                commit("setInstallations", instList)
            });
        },
        getAgreement({dispatch, commit}, payload) {
            commit("setAgreementBuzy", true)

            let URL = "api/v1/agreement/" + payload.deviceId + "/" + payload.appType;
            myAxios.get(URL).then(resp => {
                commit("setAgreement", resp.data)
                commit("setAgreementBuzy", false)
            });
        },

        // let chartsCatalog = this.$store.state.charts.s63.primar_map_info
        // let objIndex, band
        // this.cellsBands = [0,0,0,0,0,0]
        // cellsList.forEach((item) => {
        //     objIndex = chartsCatalog.findIndex((obj => obj.id === item.id));
        //     band = chartsCatalog[objIndex].usageBand
        //     // let bandName = utils.getBandName(band)
        //     // console.log(band, bandName)
        //
        //     this.cellsBands[band - 1] = this.cellsBands[band - 1] + 1
        //
        // });

        getECDISCharts({dispatch, commit}, payload) {
            let URL = "api/v1/primar-charts/" + payload.deviceId;
            myAxios.get(URL).then(resp => {

                let charts = [];
                let chartsCatalog = store.state.charts.s63.primar_map_info


                resp.data.forEach((element, i) => {
                    let objIndex = chartsCatalog.findIndex((obj => obj.id === element));

                    console.log(chartsCatalog.length)
                    console.log(chartsCatalog[0])
                    console.log(element, objIndex)

                    let title = chartsCatalog[objIndex].title
                    let band = chartsCatalog[objIndex].usageBand
                    let bandName = utils.getBandName(band)


                    charts.push({id: element, title: title, band: bandName})
                })

                commit("setECDISCharts", charts)
            });
        },

        updateOrder({dispatch, commit}, payload) {
            commit("setOrder", payload)
        },
        cancelOrder({dispatch, commit}) {
            commit("cancelOrder")
        },
        addTransaction({dispatch, commit}, order) {
            commit("setTransactions", order)
        },
        updateProto1({dispatch, commit}) {
            let json1 = require("./_a_transactions1.json")
            // commit("setTransactions", json1)
            eventBus.$emit('refresh-orders-table');
        },
        updateProto2({dispatch, commit}) {
            let json1 = require("./_a_transactions2.json")
            // commit("setTransactions", json1)
            eventBus.$emit('refresh-orders-table');
        },
        // updateSelectedEcdis({dispatch, commit}) {
        //
        // }
    },
    mutations: {
        setOrder(state, payload) {

            console.log("88888888888")
            console.log(payload)
            console.log(payload.application_id)

            if (payload.editing)
                state.order.editing = payload.editing
            if (payload.order_number)
                state.order.order_number = payload.order_number
            if (payload.order_date)
                state.order.order_date = payload.order_date
            if (payload.order_status)
                state.order.order_status = payload.order_status
            if (payload.order_date)
                state.order.order_date = payload.order_date
            if (payload.device_id)
                state.order.device_id = payload.device_id
            if (payload.application_id !== undefined)
                state.order.application_id = payload.application_id
            if (payload.ecdis_name)
                state.order.ecdis_name = payload.ecdis_name
            if (payload.ecdis_imo)
                state.order.ecdis_imo = payload.ecdis_imo
            if (payload.cells)
                state.order.cells = payload.cells
            if (payload.period)
                state.order.period = payload.period
            if (payload.total_price)
                state.order.total_price = payload.total_price
            if (payload.total_priceNet)
                state.order.total_priceNet = payload.total_priceNet
            if (payload.total_priceAdd)
                state.order.total_priceAdd = payload.total_priceAdd
            if (payload.total_priceRUB)
                state.order.total_priceRUB = payload.total_priceRUB
            if (payload.step)
                state.order.step = payload.step
            if (payload.customer)
                state.order.customer = payload.customer
            if (payload.product)
                state.order.product = payload.product
            if (payload.payment)
                state.order.payment = payload.payment
            if (payload.bands)
                state.order.bands = payload.bands

            state.order.agreement = {
                "currency": "RUB",
                "distributorName": "Ellcom Co.Ltd",
                "id": 12,
                "name": null,
                "signedAt": "2023-06-23T10:37:19.687",
                "type": "com",
                "validTill": "2024-02-24T21:00:00"
            }

            state.order.products[0] =
                {
                    "id": 5105,
                    "items": [
                        {}
                    ],
                    "netPrice": 0,
                    "price": 0,
                    "provider": "Primar",
                    "type": "s63 maps"
                }


        },
        cancelOrder(state) {
            state.order.step = "",
            state.order.order_status = "Request",
            state.order.order_number = null,
            state.order.order_date = null,
            state.order.device_id = null,
            state.order.application_id = null,
            state.order.ecdis_name = null,
            state.order.ecdis_imo = null,
            state.order.total_price = null,
            state.order.total_priceNet = null,
            state.order.total_priceAdd = null,
            state.order.total_priceRUB = null,
            state.order.cells = [],
            state.order.period = null,
            state.customer = null,
            state.product = null,
            state.payment = null,
            state.bands = [],
            state.agreement = {},
            state.products = []
        },
        setOrderStep(state, step) {
            if (step === "editing")
                state.order.step
        },
        setInstallations(state, json) {
            state.installations = json;
        },
        setECDISCharts(state, data) {
            state.ecdis_charts = data;
        },
        setAgreementBuzy(state, flag) {
            state.agreement.buzy = flag;
        },
        setAgreement(state, agreement) {
            if (agreement.length > 0) {
                Vue.set(state.agreement, "exist", true);
                state.agreement.exist = true;
                state.agreement.id = agreement.at(0).id;
                state.agreement.distributor = agreement.at(0).distributorTitle;
                state.agreement.validUntil = agreement.at(0).validTill;
                state.agreement.currency = agreement.at(0).currency;
            } else {
                Vue.set(state.agreement, "exist", false);
            }
        },
        setTransactions(state, order) {
            console.log("ddddddddddddd")
            console.log("ddddddddddddd")
            console.log(order)
            return new Promise(resolve => {
                console.log("--------------- a2")
                console.log(order)

                let cloneOrder = Object.assign({}, order);

                state.transactions.push(cloneOrder);
                resolve()
            })


        },
        setSelectedECDIS(state, payload) {
            state.selected_ecdis = payload;
        },
        setOrderNumber(state) {
            state.order_number = "1248"
        },
        setOrderStatus(state, status) {
            state.order.order_status = status;
        },
        // setOrderState(state, status) {
        //     state.order_state = status;
        // },
        setRequestPlaced(state, flag) {
            state.request_placed = flag;
        },
        setA1(state) {
            state.transactions[0].order_status = 'Order'
            state.transactions[0].messages = [{date: '18-07-23', text: "The request changed to order"}]
            state.a1 = [{date: '18-07-23', text: "The request changed to order"}]
            state.temp = 2
            // Vue.set(state.transactions, 0, x);
            eventBus.$emit('refresh-messages-table')
        },
        setA2(state) {
            state.transactions[0].order_status = 'Completed'
            state.transactions[0].messages = [{date: '18-07-23', text: "The request changed to order"}, {date: '18-07-23', text: "The order was paid"}]
            state.transactions[0].delivery = [{date: '18-07-23', link: "https://primar.ecc.no/primar/vexport/25ebafa3-f683-4db5-b7e2-ae29d045428a"}]
            state.a1 = [{date: '18-07-23', text: "The request changed to order"}, {date: '18-07-23', text: "The order was paid"}]
            state.a2 = [{date: '18-07-23', link: "https://primar.ecc.no/primar/vexport/25ebafa3-f683-4db5-b7e2-ae29d045428a"}]
            state.temp = 3
            eventBus.$emit('refresh-messages-table')
            eventBus.$emit('refresh-delivery-table')
        }
    }
}