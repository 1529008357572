<template>
  <div>
  <ecdis-details :selected_row="selected_row"></ecdis-details>

    <b-row>
      <b-col class="listsmall">
        <b-table selected-variant="info" id="orders-ecdis-table" class="mt-2" :busy="is_busy" striped sticky-header thead-class="d-none" selectable select-mode="single" @row-selected="onRowSelected" :items="visibleRows()" :fields="visible_fields">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>
          </template>
          <template #cell(view)="row">
            <b-button class="unstyled-button" size="sm" title="Details" @click="showDevice(row.item)">
              <b-icon variant="dark" icon="gear" aria-hidden="true"></b-icon>
            </b-button>
          </template>
          <template #cell(appState.shipInfo.imo)="data">
            IMO: {{data.value}}
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>

<!--    <b-dropdown split size="sm" variant="info" text="Add request">-->
<!--      <b-dropdown-item @click="uploadFile()">From Primar CSV</b-dropdown-item>-->
<!--      <b-dropdown-item>From Excel</b-dropdown-item>-->
<!--      <b-dropdown-item>From Map</b-dropdown-item>-->
<!--    </b-dropdown>-->
  </div>
</template>

<script>
import {eventBus} from "@/main";
import {getAppName} from "@/utils/utils";
import EcdisDetails from "@/views/transactions/tabs/EcdisDetails.vue";

export default {
  name: "EcdisList",
  components: {EcdisDetails},
  data() {
    return {
      selected_row: null,
      ship: {
        order_number: null,
        order_date: null,
        ecdis_name: null,
        ecdis_imo: null,
        device_id: null,
        application_id: null
      },
    }
  },
  props: ["ship_type"],
  computed: {
    is_busy() {
      return this.$store.state.proto.installations.length === 0
    },
    visible_fields() {
      if (this.ship_type === 'ecdis') {
        return [
          {key: 'view', label: '', tdClass: 'small-black', thClass: 'small-gray'},
          {key: 'selected', label: '', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'aindex', label: '', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'applicationId', label: '1', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'deviceId', label: '', tdClass: 'small-black', thClass: 'small-gray'},
          {key: 'appState.shipInfo.name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
          {key: 'appState.shipInfo.imo', label: 'IMO', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'appState.programInfo.activeEmail', label: 'Email', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'appState', label: '1', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        ]
      }

      if (this.ship_type === 'devices') {
        return [
          {key: 'selected', label: '', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'aindex', label: '', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'applicationId', label: '1', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'deviceId', label: '', tdClass: 'small-black', thClass: 'small-gray'},
          {key: 'appState.shipInfo.name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
          {key: 'deviceId', label: 'Device Id', tdClass: 'small-black', thClass: 'small-gray'},
          {key: 'platformName', label: 'Platform', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'appState.programInfo.activeEmail', label: 'Email', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
          // {key: 'appState', label: '1', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        ]
      }

    },


  },
  methods: {
    getAppName,
    showDevice(row) {
      this.selected_row = row
      this.$root.$emit('bv::toggle::collapse', 'ship-details');

      this.$store.dispatch('proto/getECDISCharts',
          {
            deviceId: row.deviceId,
          })
    },
    visibleRows() {

      // return this.$store.state.proto.installations;

      // let allList = this.$store.state.manage.installations.at(0)
      let allList = this.$store.state.proto.installations
      let listWithAppstate;
      if (allList) {

        if (this.ship_type === 'ecdis') {
          listWithAppstate = allList.filter(function (e) {
            return e.appState !== null && e.applicationId === 11
                // && e.appState.shipInfo.name !== ''
          });
        }

        if (this.ship_type === 'devices') {
          listWithAppstate = allList.filter(function (e) {
            return e.appState !== null && e.applicationId !== 11
          });
        }

        return listWithAppstate;
      }
    },
    onDone() {

      console.log("aaaaaaaaa")
      console.log("aaaaaaaaa")
      console.log("aaaaaaaaa")
      console.log("aaaaaaaaa")
      console.log(this.ship)

      this.$store.dispatch('proto/updateOrder',
          {
            device_id: this.ship.device_id,
            order_number: this.ship.order_number,
            order_date: this.ship.order_date,
            ecdis_name: this.ship.ecdis_name,
            ecdis_imo: this.ship.ecdis_imo,
            application_id: this.ship.application_id
          })

      // alert(this.ship.device_id)

      this.$store.dispatch('proto/getAgreement',
          {
            deviceId: this.ship.device_id,
            appType: this.ship.application_id
          })

      eventBus.$emit('fix-scale')
    },
    onRowSelected(items) {
      this.ship.order_number = Math.floor(1000 + Math.random() * 9000)
      this.ship.order_date = new Date().toLocaleDateString()
      this.ship.ecdis_name = items[0].appState.shipInfo.name
      this.ship.ecdis_imo = items[0].appState.shipInfo.imo
      this.ship.device_id = items[0].deviceId
      this.ship.application_id = items[0].applicationId

      console.log("------------- 2")
      console.log(this.ship)




      // eventBus.$emit('ecdis-selected', {id: items[0].aindex, name: items[0].appState.shipInfo.name});
      // this.$store.commit('proto/setSelectedECDIS', {id: items[0].aindex, name: items[0].appState.shipInfo.name, imo: items[0].appState.shipInfo.imo})
      // this.$store.commit('proto/setOrderNumber')

    //   this.$store.dispatch('proto/updateOrder',
    //       {
    //         order_number: Math.floor(1000 + Math.random() * 9000),
    //         order_date: new Date().toLocaleDateString(),
    //         ecdis_name: items[0].appState.shipInfo.name,
    //         ecdis_imo: items[0].appState.shipInfo.imo} )
    //
    //   // store.commit("distributor/setCurrentAgreement", {id: items[0].id, name: items[0].name, distributor: items[0].distributor.title});
    },
  }
}
</script>

<style scoped>
.unstyled-button {
  border: none;
  padding: 0;
  background: none;
}
/*.unstyled-button:hover {*/
/*  border: none;*/
/*  padding: 0;*/
/*  background: none;*/
/*}*/
/*.unstyled-button:active {*/
/*  border-style: outset;*/
/*  padding: 0;*/
/*  background: none;*/
/*}*/

</style>