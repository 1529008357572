<template>
  <b-sidebar width="700px" id="messages-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">MESSAGES</b-badge><br><br>
      <b-card v-if="data">
<!--        <div v-for="(item, index) in data.cells">-->
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-left"><b>Messages:</b></b-col>
            <b-col class="listsmall">
              <b-table ref="messagestable" striped :items="$store.state.proto.a1" ></b-table>
            </b-col>
          </b-row>
<!--        </div>-->
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import {eventBus} from "@/main";
import router from "@/router";

export default {
  name: "Messages",
  props: ["data"],
  mounted() {
    eventBus.$on('refresh-messages-table', () => {
      setTimeout(() => {
        this.$refs.messagestable.refresh();
      }, 1000);
    })
  },
  computed: {
    visible_fields() {
      return [
        {key: 'date', label: 'Date', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'text', label: 'Text', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  }
}
</script>

<style scoped>

</style>