import L from "leaflet";
import {file} from "paths.macro";
import {NavLeaf} from "./nav-leaf";
import {eventBus} from "@/main";
import store from "@/store";
import * as utils from "@/utils/utils";
import {getS63CKTInfo} from "@/utils/utils";
import "./extensions/easy-button"
import "./extensions/easy-button.css"
// import "leaflet-center-cross"
import "leaflet-draw/dist/leaflet.draw-src.css"
import "leaflet-draw/dist/leaflet.draw.css"

import "./extensions/leaflet-search/leaflet-search.min.js"
import "./extensions/leaflet-search/leaflet-search.min.css"

import {CURRENT_PRODUCT, NAVICOM} from "@/utils/build-config";
import './route-styles.css'
import './controls.css'

let orderTextOnChart;
let shipTextOnChart;
let isShipSelected = false;
let disabledCells = [];
// let customControl1;

class PaneControl {
    constructor(map) {
        this.map = map;
        this.Panes = {};
    }
    get panes_ids_list(){
        return Object.keys(this.Panes);
    }
    do(zind){
        // console.log(zind)
        if (!this.panes_ids_list.find(x => x == zind)){
            this.Panes[zind] = this.map.createPane(zind);
            this.Panes[zind].style.zIndex = zind
            // console.log('pane created ' + zind)
        }
        // else console.log('pane already exist' + zind);
    }
    clearPanes(){
        delete this.map._panes.custom;
        delete this.map._paneRenderers.custom;
        this.Panes = {}
    }
}

function ChartsType (){
    this.bigCells = {
        lg: L.layerGroup()
    };
    this.midCells = {
        lg: L.layerGroup()
    };
    this.lilCells = {
        lg: L.layerGroup()
    };
}

export class ChartsLeaf extends NavLeaf {
    customControl;

    constructor() {
        super();
    }
    init(el, drawRoute=false, selectCharts=false){
        this.autoscale_bands = true;
        this.chartsSelectable = selectCharts;
        super.init(el);
        this.drawRoute = drawRoute;
        this.catalogueNotHistory = true;
        this.osmLayer = L.tileLayer("https://api.probki.net/map/{z}/{x}/{y}", {
            minZoom: 4,
            maxZoom: 17
        });

        // document.getElementsByClassName( 'leaflet-control-attribution' )[0].style.display = 'none';

        this.multiTiles = false

        // let control = L.control.centerCross({show: true, position: "topleft", toggleTitle: 'Toggle crosshair'});
        // this.map.addControl(control);

        this.map.createPane('overview')
        this.map.getPane('overview').style.zIndex = 650;
        this.map.createPane('general')
        this.map.getPane('general').style.zIndex = 660;
        this.map.createPane('coastal')
        this.map.getPane('coastal').style.zIndex = 670;
        this.map.createPane('approach')
        this.map.getPane('approach').style.zIndex = 680;
        this.map.createPane('harbour')
        this.map.getPane('harbour').style.zIndex = 690;
        this.map.createPane('berthing')
        this.map.getPane('berthing').style.zIndex = 700;
        this.map.createPane('s102')
        this.map.getPane('s102').style.zIndex = 700;

        this.ChartsData = {}
        this.ChartsLayers = {
            overview : L.layerGroup({}, {pane: 'overview'}),
            general : L.layerGroup({}, {pane: 'general'}),
            coastal : L.layerGroup({}, {pane: 'coastal'}),
            approach : L.layerGroup({}, {pane: 'approach'}),
            harbour : L.layerGroup({}, {pane:'harbour' }),
            berthing : L.layerGroup({}, {pane: 'berthing'}),
            unavailable: L.layerGroup(),
            s102: L.layerGroup({}, {pane: 's102'})
        }

        this.overlayMaps = {
            "Overview" : this.ChartsLayers.overview,
            "General" : this.ChartsLayers.general,
            "Coastal" : this.ChartsLayers.coastal,
            "Approach" : this.ChartsLayers.approach,
            "Harbour" : this.ChartsLayers.harbour,
            "Berthing" : this.ChartsLayers.berthing,
            "S102": this.ChartsLayers.s102,
            "<i>Purchased</i>": this.ChartsLayers.unavailable,
        }

        // this.overlayMapsNavicom =  {
        //         "Navicom" : this.ChartsLayers.general,
        // }

        this.layersControl = L.control.layers({}, this.overlayMaps)

        let searchControl = new L.Control.Search({
            layer: L.layerGroup([this.ChartsLayers.overview]),
            moveToLocation: function(latlng, title, map) {
                let zoom = map.getBoundsZoom(latlng.layer.getBounds());
                map.setView(latlng, zoom); // access the zoom
            }
        })

        // this.map.addControl( searchControl );


        // L.Map.include({
        //     _initControlPos: function () {
        //         var corners = this._controlCorners = {},
        //             l = 'leaflet-',
        //             container = this._controlContainer =
        //                 L.DomUtil.create('div', l + 'control-container', this._container);
        //
        //         function createCorner(vSide, hSide) {
        //             var className = l + vSide + ' ' + l + hSide;
        //
        //             corners[vSide + hSide] = L.DomUtil.create('div', className, container);
        //         }
        //
        //         createCorner('top', 'left');
        //         createCorner('top', 'right');
        //         createCorner('bottom', 'left');
        //         createCorner('bottom', 'right');
        //
        //         createCorner('top', 'center');
        //         createCorner('middle', 'center');
        //         createCorner('middle', 'left');
        //         createCorner('middle', 'right');
        //         createCorner('bottom', 'center');
        //     }
        // });

        eventBus.$on('disable-cell', (id) => {
            disabledCells.push(id)
            console.log(disabledCells)
        })

        eventBus.$on('enable-cell', (id) => {
        })

        eventBus.$on('set-new-ship-text-on-chart', (shipName) => {
            // shipTextOnChart.updateContent("<span class='chart-text'>&nbsp;Ship: " + shipName + "&nbsp;</span>")
            isShipSelected = true;
        })

        // L.Control.textbox1 = L.Control.extend({
        //     onAdd: function(map) {
        //         let text = L.DomUtil.create('div');
        //         text.id = "chart_text_order";
        //         text.innerHTML = "<span class='chart-text'>&nbsp;New order&nbsp;</span>"
        //         return text;
        //     },
        //     onRemove: function(map) {
        //         // Nothing to do here
        //     },
        //     updateContent: function(text) {
        //         this.getContainer().innerHTML = text;
        //     }
        // });
        // L.control.textbox1 = function(opts) { return new L.Control.textbox1(opts);}
        // orderTextOnChart = L.control.textbox1({ position: 'topright' }).addTo(this.map);

        // L.Control.textbox2 = L.Control.extend({
        //     onAdd: function(map) {
        //         let text = L.DomUtil.create('div');
        //         text.onclick = () => {
        //             console.log("click on select")
        //             eventBus.$emit("select-ship-from-chart")
        //         }
        //         text.id = "chart_text_ship";
        //         text.innerHTML = ""
        //         // text.innerHTML = "<span class='chart-warning-text'>&nbsp;Click to select a ship&nbsp;</span>"
        //         return text;
        //     },
        //     onRemove: function(map) {
        //         // Nothing to do here
        //     },
        //     updateContent: function(text) {
        //         this.getContainer().innerHTML = text;
        //     }
        // });
        // L.control.textbox2 = function(opts) { return new L.Control.textbox2(opts);}
        // shipTextOnChart = L.control.textbox2({ position: 'topright' }).addTo(this.map);

        // eventBus.$on('set-new-order-text-on-chart', (payload) => {
        //     orderTextOnChart.updateContent("<span class='chart-text'>&nbsp;Order №" + payload.order  +" (" + payload.order_date + ")&nbsp;</span>")
        // })

        eventBus.$on('clear-order-text-on-chart', () => {
            // orderTextOnChart.updateContent("<span class='chart-text'>&nbsp;New order&nbsp;</span>")
            // shipTextOnChart.updateContent("<span class='chart-warning-text'>&nbsp;Click to select a ship&nbsp;</span>")
            isShipSelected = false;
        })


//         customControl1 =  L.Control.extend({
//             options: {
//                 position: 'topright'
//             },
//
//             onAdd: (map) => {
//                 let container = L.DomUtil.create('input');
//                 container.button_state = true;
//                 container.type="button";
//                 container.title="Toggle bands by map scale ";
//                 container.value = "Select a ship first!";
//                 container.style.backgroundColor = 'white';
//                 //container.style.backgroundImage = "url(https://t1.gstatic.com/images?q=tbn:ANd9GcR6FCUMW5bPn8C4PbKak2BJQQsmC-K9-mbYBeFZm1ZM2w2GRy40Ew)";
// //                container.style.backgroundSize = "30px 30px";
//                 container.style.width = '198px';
//                 container.style.height = '24px';
//
//                 container.onmouseover = function(){
//                     container.style.backgroundColor = 'lightgray';
//                 }
//                 container.onmouseout = function(){
//                     container.style.backgroundColor = 'white';
//                 }
//
//                 container.onclick = () => {
//                     container.button_state = !container.button_state;
//                     this.autoscale_bands = container.button_state
//                     container.value = container.button_state ? "" : ""
//                 }
//
//                 return container;
//             }
//         });
//
//         this.map.addControl(this.customControl = new customControl1())



        // eventBus.$on('set-non-primar-layer-control', () => {
        //     alert(2)
        //     this.layersControl.remove();
        //     this.layersControl = L.control.layers({}, this.overlayMaps1)
        // })
        //
        // eventBus.$on('remove-primar-controls', () => {
        //     //TODO NAVICOM
        //     // this.map.removeControl(this.customControl)
        //
        //     // remove customControl
        // })


        this.clearCellslayer();

        this.ChartsLayers.overview.addTo(this.map);
        // this.ChartsLayers.general.addTo(this.map);
        // this.ChartsLayers.approach.addTo(this.map);



        let customControl =  L.Control.extend({
            options: {
                position: 'topright'
            },

            onAdd: (map) => {
                var container = L.DomUtil.create('input');
                container.button_state = true;
                container.type="button";
                container.title="Toggle bands by map scale ";
                container.value = "Autobands ☑";
                container.style.backgroundColor = 'white';
                //container.style.backgroundImage = "url(https://t1.gstatic.com/images?q=tbn:ANd9GcR6FCUMW5bPn8C4PbKak2BJQQsmC-K9-mbYBeFZm1ZM2w2GRy40Ew)";
//                container.style.backgroundSize = "30px 30px";
                container.style.width = '98px';
                container.style.height = '24px';

                container.onmouseover = function(){
                    container.style.backgroundColor = 'lightgray';
                }
                container.onmouseout = function(){
                    container.style.backgroundColor = 'white';
                }

                container.onclick = () => {
                    container.button_state = !container.button_state;
                    this.autoscale_bands = container.button_state
                    container.value = container.button_state ? "Autobands ☑" : "Autobands ☐"
                }

                return container;
            }
        });
        eventBus.$on('add-primar-controls', () => {
            this.map.addControl(this.customControl = new customControl()) //TODO control
        })
        eventBus.$on('remove-primar-controls', () => {
            this.map.removeControl(this.customControl)
        })
        // eventBus.$on('a1', () => {
        //     this.map.removeControl(customControl1)
        // })

        // this.ScaleStateControl = new L.Control.EasyButton({
        //     position: 'topright',
        //     states: [{
        //         stateName: 'on',        // name the state
        //         icon:      'bi bi-file-diff-fill',               // and define its properties
        //         title:     'Turn autoband off',      // like its title
        //         onClick: (btn) => {       // and its callback
        //             // eventBus.$emit('toggle-autoband', true)
        //             this.autoscale_bands = false
        //             btn.state('off');    // change state on click!
        //         }
        //     }, {
        //         stateName: 'off',
        //         icon:      'bi bi-file-diff',
        //         title:     'Turn autoband on',
        //         onClick: (btn) => {
        //             // eventBus.$emit('toggle-autoband', false)
        //             this.autoscale_bands = true
        //             btn.state('on');
        //         }
        //     }]
        // }).addTo(this.map)


        var drawControl = new L.Control.Draw({
            draw: {
                polygon: false,
                marker: false,
                circle: false,
                polyline: false,
                circlemarker: false

            },
        }).addTo(this.map);

        this.map.on('draw:created', rectangle => {
            if (this.chartsSelectable) {
                Object.keys(this.ChartsLayers).forEach(lg => {
                    if (this.map.hasLayer(this.ChartsLayers[lg])) {
                        Object.keys(this.ChartsData[lg]).forEach(field => {
                            Object.keys(this.ChartsData[lg][field]).forEach(cell => {
                                if (cell !== 'lg') {
                                    let bounds = this.ChartsData[lg][field][cell].cell.getBounds()
                                    if (rectangle.layer._bounds.contains(bounds)) {
                                        this.onCellClicked(cell)
                                    }
                                }
                            })
                        })
                    }
                })
                this.dispatchSelectedCells();
            }
        })
        this.map.on('zoomend', () => {
            if (this.autoscale_bands) {
                this.scaleCharts();
            }
        })
        this.map.on('click', () => {
            // this.setCeatalogueNotHistory(true);
            // alert("click")
            // this.scaleCharts();

            console.log("---------------")

            this.map.contextmenu.hide();
        })
        this.map.on('polylinemeasure:toggle', (e) => {
            if (e.sttus) {
                Object.keys(this.ChartsLayers).forEach(cl => {
                    this.ChartsLayers[cl].removeFrom(this.map);
                });
                eventBus.$emit('draw-control-toggle', true)
            }
            else if (!e.sttus){
                this.ChartsLayers.overview.addTo(this.map);
                // this.ChartsLayers.general.addTo(this.map);
                // this.ChartsLayers.approach.addTo(this.map);
                eventBus.$emit('draw-control-toggle', false)
            }
        });

        this.map.on('polylinemeasure:finish', () => {
            //TODO: enable spinner (doesnt work)
            eventBus.$emit('go-spinny', true)
            this.map.spin(true);
            let y = setTimeout(() => {
                // console.log(this.pMeasure)
                this.computeCharts()
                    .then( () => {
                        eventBus.$emit('go-spinny', false);
                        this.map.spin(false);
                    })
            }, 1000)

        });
        this.map.on('polylinemeasure:move', () => {
            this.clearSelectedCells();
            this.computeCharts();
        });
        this.map.on('polylinemeasure:clear', () => {
            this.clearSelectedCells();

        });

        this.paneControl = new PaneControl(this.map)

        eventBus.$on('cell-clicked', (id) => {
                // alert("cell clicked")

                if (this.chartsSelectable) {
                    this.onCellClicked(id)
                        .then( () => {
                            this.dispatchSelectedCells();
                        })
                }
            }
        )

        // eventBus.$on('aaaa', (text) => {
        //     console.log(text)
        // })

        eventBus.$on('fix-scale', () => {
            // this.layersControl.remove();
            // this.layersControl = L.control.layers({}, this.overlayMaps, {collapsed: false}).addTo(this.map);
            // alert("fix")
            // this.map.fire("viewreset");
            // this.map.fire("resize");
            // this.map.fire("zoomlevelschange");
            // this.scaleCharts()
            // this.map.setZoom(12)
            // this.scaleCharts()
        })

        eventBus.$on('draw-map-spinner', (flag) => {
            this.map.spin(flag);

            // this.map.remove(text2)
            // this.map.remove(customControl1)

            // eventBus.$emit('a1')


            // text2.updateContent("")
            // this.map.removeControl(customControl1)

            // $("#info_text")[0].innerHTML = "aaaa";

        })


        eventBus.$on('s63-order-finished', () => {
            this.clearSelectedCells();
        })
        eventBus.$on('pane2cell', (payload) => {
            this.showHighlight(payload["id"], payload["items"], payload["color"])
        })
        eventBus.$on('find-cells', e => {
            // alert("find clicked")
            console.log(e)

            if (!isShipSelected)
                eventBus.$emit("select-ship-from-chart")
            else
                this.findChartsOnRightClick(e)
        })
        eventBus.$on('purchased-cell-clicked', (id) => {
            // alert("cell clicked1")
            // TODO
            // this.setSpecialTiles('https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}');
            console.log('purchased cell clicked', id)
        })
        eventBus.$on("s63-order-finished", () => {
            this.setMultiTiles();
        })
        eventBus.$on('sumary-open', () => {
            this.map.ChartsLayers.unavailable.addTo(this.map)
        })
        eventBus.$on('show-cell-on-map', (url) => {
            // TODO
            // this.setSpecialTiles(url);
        })
    }
    showAppState(aindex, lat, lon, azimuth, id, isActive, name, route, sog, email, gpsDate, appStateDate, routeJson=[], posbyship=false) {

        this.scaleCharts();
        console.log('show appstate:: start')
        super.showAppState(aindex, lat, lon, azimuth, id, isActive, name, route, sog, email, gpsDate, appStateDate, routeJson=[], posbyship=false)
        console.log('show appstate:: end')
    }
    findChartsOnRightClick(e) {

        if (e === 'hide'){
            this.map.contextmenu.hide();
        }
        else{
            const latlon = e.e.latlng
            const type = e.props.type;
            const id = e.props.id;
            const title = e.props.name;
            const purchased = e.props.unavailable ? "<br>" + '' : '';
            const paid = e.props.unavailable ? "(bought) " : "";
            this.map.contextmenu.removeAllItems();
            // ДОБАВЛЕНИЕ
            // const icon = (!Object.keys(this.ChartsData.selectedCells).find(x => x === id)) ? "bi bi-bag-plus-fill": 'bi bi-bag-dash'
            const icon = (!Object.keys(this.ChartsData.selectedCells).find(x => x === id)) ? "bi bi-toggle-off": 'bi bi-toggle-on'

            this.map.contextmenu.addItem(
                {
                    text: paid + "<span class='smt1'>" + id + "</span>" + " " + "<i>" + type + "</i>" + "<br>" + "<b>" + title + "</b>" ,
                    // + purchased ? "<b>Purchased</b>" : "",
                    iconCls: purchased ? 'bi bi-cash-coin' : this.chartsSelectable ? icon : '',
                    hideOnSelect: true,
                    callback: !purchased?
                        function () {eventBus.$emit('cell-clicked', id);} :
                        function () {eventBus.$emit('purchased-cell-clicked', id);},
                    index: 0
                }
            )
            this.map.contextmenu.addItem(
                {
                    text: "underlying cells",
                    disabled: true,
                    separator: true,
                    index: 1
                }
            )
            Object.keys(this.ChartsLayers).forEach( lg => {
                if (this.map.hasLayer(this.ChartsLayers[lg])){
                    Object.keys(this.ChartsData[lg]).forEach(field => {
                        Object.keys(this.ChartsData[lg][field]).forEach( cell => {
                            if (cell !== 'lg'){
                                let bounds = this.ChartsData[lg][field][cell].cell.getBounds()
                                if (this.isPointInsidePolygon(bounds, latlon) && cell !== id){

                                    this.map.contextmenu.addItem(
                                        {
                                            text: lg === 'unavailable'? "(bought) " + "<span class='smt1'>" + cell + "</span>" : "<span class='smt1'>" + cell + "</span>" + " " + "<i>" + lg + "</i>",
                                            iconCls: lg === 'unavailable'? "bi bi-cash-coin" : this.chartsSelectable ? "bi bi-toggle-off" : '',
                                            hideOnSelect: true,
                                            callback: lg !== 'unavailable'?
                                                function () {eventBus.$emit('cell-clicked', cell);} :
                                                function () {eventBus.$emit('purchased-cell-clicked', cell);}
                                        }
                                    )
                                }
                            }
                        })
                    })
                }
                this.map.contextmenu.showAt(latlon);
            })
            // УДАЛЕНИЕ
            Object.keys(this.ChartsData.selectedCells).forEach(key => {
                if (key != 'lg'){
                    let cell = this.ChartsData.selectedCells[key].cell;
                    console.log(cell)
                    let bounds = cell.getBounds();
                    if (this.isPointInsidePolygon(bounds, latlon)  && key !== id){
                        let type = cell._layers[cell._leaflet_id - 1].feature.properties.type;
                        this.map.contextmenu.addItem(
                            {
                                text: "<i class='bi bi-bag-dash'></i> "+ type + " " +  key,
                                // hideOnSelect: false,
                                callback: function () {eventBus.$emit('cell-clicked', key);},
                            }
                        )
                    }
                }
            })
        }
    }

    setCeatalogueNotHistory(flag){

        if (this.highLight){
            this.highLight.eachLayer( layer => {
                layer.setStyle({color: "#656565"})
            })
            this.highLight.clearLayers();
        }
        if (flag === false){
            Object.keys(this.ChartsLayers).forEach(cl => {
                this.ChartsLayers[cl].removeFrom(this.map);
            });
            this.highLight = new L.layerGroup();
            this.highLight.addTo(this.map);
            this.makeChartsUnselectable();
        }
        else if (flag === true && !this.catalogueNotHistory){
            this.highLight.removeFrom(this.map);
            this.ChartsLayers.overview.addTo(this.map);
            // this.ChartsLayers.general.addTo(this.map);
            // this.ChartsLayers.approach.addTo(this.map);
        }
        this.catalogueNotHistory = flag;
    }

    showHighlight(currentId, items, color){

        let id = currentId;
        let match = this.match(id);
        let field = match.field;
        let scope = match.scope;
        let cell = scope == 'selectedCells'? this.ChartsData.selectedCells[id].cell : this.ChartsData[scope][field][id].cell;
        let bounds = cell.getBounds();
        this.map.fitBounds(bounds);


        // this.setCeatalogueNotHistory(false);
        //
        // items.forEach(cellFromOrder => {
        //
        //     let id = cellFromOrder.id;
        //     let match = this.match(id);
        //     let field = match.field;
        //     let scope = match.scope;
        //     let cell = scope == 'selectedCells'? this.ChartsData.selectedCells[id].cell : this.ChartsData[scope][field][id].cell;
        //     let lefId = Object.keys(cell._layers)[0]
        //     cell.addTo(this.highLight);
        //     cell._layers[lefId].setStyle({color: color});
        //     if (id === currentId) {
        //         let bounds = cell.getBounds();
        //         this.map.fitBounds(bounds);
        //         setTimeout(() => {
        //             cell._layers[lefId].openTooltip();
        //         }, 1000)
        //     }
        // })
    }
    isPointInsidePolygon(poly, pt){
        let polyPoints = poly
        let x = pt.lat, y = pt.lng;
        // let x = 60, y = 25;
        let ne = poly._northEast;
        let sw = poly._southWest;

        let inside = false;
        // console.log(x, y, ne, sw)

        inside = x <= ne.lat && x >= sw.lat && y <= ne.lng && y >= sw.lng;

        return inside;
    }
    computeCharts(){
        console.log(file, 'processing route: start')
        return new Promise( (resolve, error) => {
            let x = this.pMeasure._arrPolylines[0];
            if (!x){
                eventBus.$emit('go-spinny', false);
                this.map.spin(false);
                error();
            }

            let ltlList = x.polylinePath._latlngs;
            // console.log(this.ChartsData)
            let cells = [];
            let products = [];
            let coordss = null;
            let latlon = null
            ltlList.forEach( ltl => {
                latlon = ltl
                Object.keys(this.ChartsData).forEach(key => {
                    if (key != 'selectedCells'){
                        Object.keys(this.ChartsData[key]).forEach(field => {
                            if (field != 'lg'){
                                Object.keys(this.ChartsData[key][field]).forEach(id => {
                                    if  (this.ChartsData[key][field][id].cell) {
                                        let cell = this.ChartsData[key][field][id].cell

                                        // console.log(cell._layers[cell._leaflet_id - 1].feature.properties.name)
                                        // console.log(ltl)
                                        let bounds = cell._layers[cell._leaflet_id - 1].getBounds();
                                        // console.log(bounds)
                                        coordss = bounds
                                        if (this.isPointInsidePolygon(bounds, ltl)) {
                                            if (!cells.find(x => x == cell._layers[cell._leaflet_id - 1].feature.properties.id) &&
                                                !products.find (x => x == cell._layers[cell._leaflet_id - 1].feature.properties.productId)) {
                                                cells.push(cell._layers[cell._leaflet_id - 1].feature.properties.id)
                                                products.push(cell._layers[cell._leaflet_id - 1].feature.properties.productId)
                                            }
                                            else console.log(cells, products)
                                        }
                                    }
                                })
                            }
                        })
                    }
                })
            })
            // console.log(coordss);
            // console.log(latlon);
            // console.log(cells)
            new Set(cells).forEach(id => {
                this.onCellClicked(id)
            })
            console.log(file, 'processing route: end')
            this.dispatchSelectedCells();
            resolve();
        })
    }
    clearSelectedCells(){
        console.log(file, 'clear selected cells')
        let linksToProducts = store.state.charts.s63.products.linksToProducts;
        let products = store.state.charts.s63.products.all;
        let toRemoveList = [];
        let productsList = [];
        let selc = this.ChartsData.selectedCells
        Object.keys(selc).forEach( c => {
            if (c != "lg"){
                productsList.push(linksToProducts[c]);
            }
        })
        console.log(productsList)
        new Set(productsList).forEach(x => {
            toRemoveList.push(
                products.find(y => y.id === x).productCellList.productCells[0].id
            )
        })
        console.log(toRemoveList)
        toRemoveList.forEach(x => {
            this.onCellClicked(x);
        })
        console.log(file, 'clear selected cells: end')
        this.dispatchSelectedCells();
    }
    selectChartsByRoute(){
        this.clearRoute();
        this.add_pMeasure(true);
        // console.log(this.pMeasure)

        let a = document.getElementById('polyline-measure-control')
        if(a)
            a.hidden = false;

    }
    makeChartsSelectable(){
        this.chartsSelectable = true;
        // this.setCeatalogueNotHistory(true)
    }
    makeChartsUnselectable(){
        this.chartsSelectable = false;

    }
    getSelectedCells(){
        let stuf = []
        Object.keys(this.ChartsData.selectedCells).forEach(key => {
            if (key != 'lg'){
                let cell = this.ChartsData.selectedCells[key].cell;
                let lefId = Object.keys(cell._layers)[0];
                console.log(cell._layers[lefId].feature.properties.id, cell._layers[lefId].feature.properties.productId)

                // let otherStuf = store.state.charts.s63.primar_map_info.filter( x => {
                //     return x.id == cell._layers[lefId].feature.properties.productId;
                // })
                stuf.push(cell._layers[lefId].feature.properties.productId);
            }
        })
        return stuf;
    }
    scaleCharts(){
        console.log('scale:start')
        let zoom = this.map.getZoom();


        Object.keys(this.ChartsLayers).forEach( name => {

            if (name != 'unavailable' && name != 's102'){
                console.log(name)
                const minz = this.getMinZFromUsageBand(name);
                if (zoom >= minz -5){
                    // alert("a1")
                    console.log(name + " added")
                   this.ChartsLayers[name].addTo(this.map);
                }
                else {
                    // alert("a2")
                    console.log(name + " removed")
                   this.ChartsLayers[name].remove();
                }
            }
        })
        // alert(zoom)
        if (zoom < 3){
            this.refreshLayers('bigCells');
        }
        else if (zoom < 4) this.refreshLayers('midCells');
        else if (zoom < 9) this.refreshLayers('lilCells');
        console.log('scale:end')
    }
    refreshLayers(cells=null){
        Object.keys(this.ChartsLayers).forEach(key => {
            this.ChartsLayers[key].clearLayers();
            if (cells){
                this.ChartsData[key][cells].lg.addTo(this.ChartsLayers[key]);
            }
        })
    }
    refreshData() {
        if (this.ChartsData.selectedCells) this.ChartsData.selectedCells.lg.removeFrom(this.map);
        this.ChartsData = {
            overview : new ChartsType(),
            general : new ChartsType(),
            coastal : new ChartsType(),
            approach : new ChartsType(),
            harbour : new ChartsType(),
            berthing : new ChartsType(),
            unavailable: new ChartsType(),
            s102: new ChartsType(),
            selectedCells: {lg : L.layerGroup()}
        }

        Object.keys(this.ChartsData).forEach( key => {
            if (key != 'selectedCells') {
                this.ChartsData[key].lilCells.lg.addTo(this.ChartsLayers[key]);
                this.ChartsData[key].midCells.lg.addTo(this.ChartsLayers[key]);
                this.ChartsData[key].bigCells.lg.addTo(this.ChartsLayers[key]);
            }
        })
        this.ChartsData.selectedCells.lg.clearLayers();
        this.ChartsData.selectedCells.lg.addTo(this.map);
    }
    drawCell(data, styleType ) {

        // alert("draw cell")
        let zind = data.properties.zind;
        if (zind) {
            zind = String(zind);
            this.paneControl.do(zind);
        }
        let pane = zind ? zind : data.properties.type;


        let cellStyle = {color: "red", fillOpacity: 0.1, dashArray: 10, weight: 2, opacity: 1};
        switch (styleType) {
            case 1:
                cellStyle = {color: "pink", fillOpacity: 0.05, dashArray: 1, weight: 1, opacity: 1};
                break;
            case 2:
                cellStyle = {color: "black", fillOpacity: 0.05, dashArray: 1, weight: 1, opacity: 1};
                break;
            case 3:
                cellStyle = {color: "#dfff9b", fillOpacity: 0, dashArray: 2, weight: 2, opacity: 1};
                break;
        }

        var cell = L.geoJson(data
            , {
                onEachFeature: function (feature, layer) {
                    layer.bindContextMenu();
                    layer.bindPopup(feature.properties.name, {pane: 'aaa'});
                    const purchased = feature.properties.unavailable ? "<br>" + "<b>Purchased</b>" : ''
                    // layer.bindTooltip(feature.properties.id + "<br>" +feature.properties.name +"<br>" + feature.properties.type + purchased, {sticky: true, pane: "aaa"})
                    layer.on('click', (e) => {
                            // alert("layer clicked")
                            layer.closePopup();
                            // if (!feature.properties.unavailable) {
                            //     eventBus.$emit('cell-clicked', feature.properties.id);
                            // }
                            // else {
                            //     eventBus.$emit('purchased-cell-clicked', feature.properties.id)
                            // }
                            eventBus.$emit('find-cells', {e: e, props: feature.properties})

                        }
                    );
                    // layer.on('hover',  () => {layer.openTooltip();})
                    layer.on('contextmenu', () => {
                        eventBus.$emit('find-cells', 'hide')
                    })
                },
                style: cellStyle
            }
        );

        let field = this.matchFieldBySize(cell);
        let lefId = Object.keys(cell._layers)[0]
        let type = cell._layers[lefId].feature.properties.unavailable ? 'unavailable' : cell._layers[lefId].feature.properties.type;
        if (field && cell._layers[lefId].feature.properties.id && type) {
            this.ChartsData[type][field][cell._layers[lefId].feature.properties.id] = {};
            this.ChartsData[type][field][cell._layers[lefId].feature.properties.id].cell = cell;
            cell.addTo(this.ChartsData[type][field].lg);
        }
    }
    resetMainLayer(){
        this.mainLayer.remove();
        this.mainLayer = L.tileLayer("https://api.probki.net/map/{z}/{x}/{y}").addTo(this.map);
        if (this.layersControl) this.layersControl.remove()
    }
    resetAll(){
        // this.resetMainLayer();
        // this.clearCellslayer();
        //this.clearAppStates();
    }
    drawAllCells(allCharts) {
        console.log(file, 'draw all cells: start')
        let flag

        store.dispatch('current/addLoadingMarker', "my_lead|drawAllCells");

        eventBus.$emit('loading-data', true);
        // this.setDefaultTiles();
        this.clearCellslayer();
        store.dispatch('addS63Items', [])

        let summary = store.state.charts.s63.summary;

        var x = 0;
        console.log(allCharts)

        allCharts = allCharts.filter(function (e) {
            return e.productSpecNumber !== 111;
        });

        allCharts.forEach((chart) => {
            let borders = [[[chart.west, chart.north], [chart.east, chart.north],
                [chart.east, chart.south], [chart.west, chart.south]]];
            let geometry = {type: "Polygon", coordinates: borders}

            let chartFeatures = getS63CKTInfo(chart.id);
            let price = chartFeatures.price;

            let notForSale = false;
            if (summary) {
                notForSale = summary.find(x => x.id === chart.id) ? true : false;
            }

            // if (notForSale) console.log(notForSale)
            let periods = chartFeatures.period;

            let chartType = chart.usageBand === 's102' ? 's102' : utils.getBandName(chart.usageBand);

            

            if (!notForSale) {
                const z = ((ne, sw) => {
                    let d = Math.hypot(ne.lat - sw.lat, ne.lng - sw.lng);
                    return 10 / d;
                })({lat: chart.east, lng: chart.north}, {lat: chart.west, lng: chart.south})

                let pane = Number(this.map.getPane(chartType).style.zIndex);

                var zi = Math.round(pane + z);
                zi = zi > pane + 9 ? pane + 9 : zi;
                x++

            }

            if (!chart.productId){
                flag = true
                console.log(chartType)
            }

            var cellJson = {
                type: "Feature",
                properties: {
                    name: chart.title,
                    id: chart.id,
                    type: chartType,
                    price: price,
                    unavailable: notForSale,
                    periods: periods,
                    productId: chart.productId,
                    zind: zi
                },
                geometry: geometry
            };

            let styleType = (store.state.charts.s63.products.multiPart[chart.productId]) ? 1 : 2;
            if (notForSale) styleType = 3

            this.drawCell(cellJson, styleType)

        })
        if (flag){
            alert('PROBLEMA, '+ file)
            console.log('PROBLEMA, '+ file)
        }
        this.scaleCharts();

        // let urls = [];
        // summary.forEach( chart => {
        //     const url =  'https://win1hetz.bgeo.fi:5005/'+chart.id+'/{z}/{x}/{y}';
        //     urls.push(url);
        // })
        // this.setMultiTiles(urls)
        this.addChartLayers();
        this.setMultiTiles()
        console.log(Object.keys(this.ChartsData.s102.lilCells).length)

        eventBus.$emit('loading-data', false);

        store.dispatch('current/removeLoadingMarker', "my_lead|drawAllCells");

        console.log(file, 'draw all cells: end')

        // this.scaleCharts();

        // this.map.setZoom(2);
    }
    replaceCell(scope, field, id){
        console.log(scope, field, id)

        let cell = scope == 'selectedCells'? this.ChartsData.selectedCells[id].cell : this.ChartsData[scope][field][id].cell;
        let lefId = Object.keys(cell._layers)[0]
        let newScope = (scope == 'selectedCells') ? cell._layers[lefId].feature.properties.type : 'selectedCells';
        if (!cell._layers[lefId].feature.properties.unavailable) {
            if (newScope != 'selectedCells') {
                this.ChartsData[newScope][field][id] = {};
                this.ChartsData[newScope][field][id].cell = cell;
                let color = '#656565';
                this.ChartsData[newScope][field][id].cell.setStyle({color: color});
                this.ChartsData.selectedCells.lg.removeLayer(cell);
                cell.addTo(this.ChartsData[newScope][field].lg);
                delete this.ChartsData.selectedCells[id];
            } else if (newScope == 'selectedCells') {
                this.ChartsData.selectedCells[id] = {};
                this.ChartsData.selectedCells[id].cell = cell;
                let color = 'green';
                this.ChartsData.selectedCells[id].cell.setStyle({color: color, weight: 1, opacity: 1, fillOpacity: 0.5});
                this.ChartsData[scope][field].lg.removeLayer(cell);
                cell.addTo(this.ChartsData.selectedCells.lg);
                delete this.ChartsData[scope][field][id];
            }
        }
    }
    match(id){
        let f = null;
        let s = null
        Object.keys(this.ChartsData).forEach( scope => {
            Object.keys(this.ChartsData[scope]).forEach(field => {
                if (scope != 'selectedCells'){
                    if (this.ChartsData[scope][field][id]){
                        // console.log(this.ChartsData[scope][field][id], field)
                        f = field;
                        s = scope;
                    }
                }
                else if (scope == 'selectedCells'){
                    if (this.ChartsData.selectedCells[id]){
                        s = scope;
                        f = this.matchFieldBySize(this.ChartsData.selectedCells[id].cell);
                    }
                }
            })
        })
        return {scope : s, field: f};
    }
    dispatchSelectedCells(){
        console.log("----------")
        console.log("----------")
        console.log("----------")
        console.log("----------")
        this.setMultiTiles();
        console.log(file, 'dispatching selected cells: start')
        let s = this.getSelectedCells()
        store.dispatch('addS63Items', s)
        console.log(file, 'dispatching selected cells: end')
    }
    onGetId(id){
        let m = this.match(id);
        let field = m.field;
        let scope = m.scope;
        if (field && scope) {
            this.replaceCell(scope, field, id);
        }

        else console.log('cell not found');
    }
    async onCellClicked(id){
        console.log("onCellClicked")
        console.log(id)

        let groups = store.state.charts.s63.products.multiPart;
        let links = store.state.charts.s63.products.linksToProducts;

        if (groups[links[id]]){
            let arr = groups[links[id]].productCellList.productCells;
            arr.forEach( key => {
                // console.log(key, id)
                this.onGetId(key.id);
            })
        }
        else{
            this.onGetId(id)
        }


    }
    matchFieldBySize(cell){
        let ne = cell.getBounds()._northEast
        let sw = cell.getBounds()._southWest

        let d = Math.sqrt((Math.pow((ne.lat - sw.lat), 2) + Math.pow((ne.lng - sw.lng), 2)));
        if (d >= 80) {
            return 'bigCells';
        }
        else if (d >= 30) {
            return 'midCells';
        }
        else if (d > 0){
            return "lilCells";
        }
        return null;
    }
    clearCellslayer() {
        this.refreshLayers();
        this.refreshData();
    }
    rmChartLayers(){
        Object.keys(this.ChartsLayers).forEach(cl => {
            this.ChartsLayers[cl].removeFrom(this.map);
        });
        this.layersControl.remove();
    }
    addChartLayers(){
        // this.ChartsLayers.overview.addTo(this.map);
        // this.ChartsLayers.general.addTo(this.map);
        // this.ChartsLayers.coastal.addTo(this.map);
        // this.ChartsLayers.harbour.addTo(this.map);
        // this.ChartsLayers.approach.addTo(this.map);
        // this.ChartsLayers.berthing.addTo(this.map);
        // alert(3)
        this.ChartsLayers.unavailable.addTo(this.map);
        this.layersControl.remove();
        if (!CURRENT_PRODUCT.is_navicom) //TODO control
            this.layersControl = L.control.layers({}, this.overlayMaps, {collapsed: false}).addTo(this.map);
    }
    isDefaultTiles(flag){
        this.is_default_tiles = flag;
    }
    // changeTiles(URL){
    //     this.mainLayer.remove();
    //     this.mainLayer = L.tileLayer(URL);
    //     this.mainLayer.addTo(this.map);
    // }
    // setDefaultTiles(){
    //     if (!this.is_default_tiles){
    //         // this.layersControl.remove();
    //         console.log('setDefaultTiles', file)
    //         this.changeTiles("https://api.probki.net/map/{z}/{x}/{y}");
    //         // this.addChartLayers();
    //         this.osmLayer.remove();
    //         this.isDefaultTiles(true);
    //     }
    // }
    // setSpecialTiles(URL){
    //     // this.layersControl.remove();
    //     this.osmLayer.addTo(this.map);
    //     this.changeTiles(URL);
    //     // this.rmChartLayers();
    //     this.isDefaultTiles(false);
    //     // this.layersControl = L.control.layers(
    //     //     {osmLayer: this.osmLayer, mainLayer: this.mainLayer},
    //     //     this.overlayMaps, {collapsed: false})
    // }
    static getSpecialTileLayer(id, bounds){
        const url =  'https://win1hetz.bgeo.fi:5005/'+id+'/{z}/{x}/{y}';
        // const minZ = this.getMinZFromUsageBand(type);

        const tileLayer = L.tileLayer(url, {
            minZoom: 4,
            maxZoom: 17,
            bounds: bounds,
        })
        return tileLayer;
    }
    getMinZFromUsageBand(chartType){
        let minZ
        switch (chartType){
            case 'overview':
                minZ = 9;
                break;
            case 'general':
                minZ = 10;
                break;
            case 'coastal':
                minZ = 13;
                break;
            case 'approach':
                minZ = 15;
                break;
            case 'harbour':
                minZ = 14;
                break;
            case 'berthing':
                minZ = 17;
                break;
        }
        return minZ
    }
    setMultiTiles(remove=false){
        // this.updateMultiTiles();
        // if (this.multiTilesStateControl._currentState.stateName === 'on'){
        //     this.clearMultiTiles();
        //     if (!remove) this.addMultiTiles()
        // }
    }
    clearMultiTiles(){
        if (this.tilesGroup){
            Object.keys(this.tilesGroup).forEach( type => {
                this.tilesGroup[type].forEach(tile => {
                    tile.remove();
                })
            })
        }
    }
    addMultiTiles(){
        this.mainLayer.remove();
        this.osmLayer.remove();
        this.osmLayer.addTo(this.map);
        this.isDefaultTiles(false);
        Object.keys(this.tilesGroup).forEach(type => {
            console.log(this.tilesGroup[type], type);
            this.tilesGroup[type].forEach( TL => {
                TL.addTo(this.map)
            })
        })
    }

    updateMultiTiles(){
        this.tilesGroup =
            {
                overview: [],
                general: [],
                coastal: [],
                approach: [],
                harbour: [],
                berthing: [],
            };
        // console.log(this.ChartsLayers, this.ChartsData)
        Object.keys(this.ChartsData.unavailable).forEach( level => {
            Object.keys(this.ChartsData.unavailable[level]).forEach(id => {
                if (id !== 'lg'){
                    const cell = this.ChartsData.unavailable[level][id].cell;
                    const lefId = Object.keys(cell._layers)[0];
                    const type = cell._layers[lefId].feature.properties.type;
                    const bounds = cell.getBounds()
                    const TL = ChartsLeaf.getSpecialTileLayer(id,  bounds);
                    this.tilesGroup[type].push(TL);
                }
            })
        })

        Object.keys(this.ChartsData.selectedCells).forEach(id => {
            if (id !== 'lg'){
                const cell = this.ChartsData.selectedCells[id].cell;
                const lefId = Object.keys(cell._layers)[0];
                const type = cell._layers[lefId].feature.properties.type;
                const bounds = cell.getBounds()
                const TL = ChartsLeaf.getSpecialTileLayer(id,  bounds);
                this.tilesGroup[type].push(TL);
            }
        })
    }

    redrawMap(URL) {
        this.setCeatalogueNotHistory(false);
        console.log(URL)
        this.mainLayer.remove();
        //this.map.setView(coordinates, zoom_level);

        // map.setMaxZoom(maxzoom);
        // map.setMinZoom(minzoom);
        this.map.setMaxZoom(20);
        this.map.setMinZoom(1);



        let osmLayer = L.tileLayer("https://api.probki.net/map/{z}/{x}/{y}");
        this.layersControl.remove()
        this.mainLayer = L.tileLayer(URL);
        this.layersControl = L.control.layers({osmLayer: osmLayer, mainLayer: this.mainLayer}).addTo(this.map);



        osmLayer.addTo(this.map);
        this.mainLayer.addTo(this.map);

    }
}