import myAxios from "@/services/myAxios";
import {file} from "paths.macro";
import {commit} from "lodash/seq";
import axios from "axios";
import {CURRENT_PRODUCT} from "@/utils/build-config";
// import store from "core-js/internals/shared-store";
import {getAppType} from "@/utils/utils";
import store from "@/store";
import Vue from "vue";
const hashPeriodBands = {12: 0, 6: 1, 3: 4};
const BandPeriods = {0: '12 months', 1: `6 months`, 4: '3 months'};

const initialState = {
    webuser: {
        ip: "",
        location: "",
    },
    user_type: {
        is_mconnect: false,
        device_id: "",
    },
    profile: localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : {
        device_id: "",
        user_id: "",
        email: "",
        device_os: "",
        app_name: "",
        app_version: "",
        pos_lat: "",
        pos_lon: "",
    },
    ship: {
        draught: 10,
    },
    cart: {
        s63cells: [],
        totalPriceByPrimar: '',
        totalPriceByPrimarNet: '',
        totalPriceByPrimarAdd: '',
        totalPriceByPrimarRUB: '',
        // totalPriceByPrimarEURO_RU: '',
        totalPriceByPrimarAgreementRUB: '',
        totalPriceByPrimarAgreementUSD: '',
        totalPriceByPrimarAgreementEURO_RU: '',
        totalPriceByPrimarDisplay:'',
        totalPriceByGP: 0,
        currentBandPeriods : BandPeriods,
        cellPrices: []
    },
    loading: {
        loadingMarkers: [],
        geocontent: false,
        orders: false,
        primarInfo: false,
        drawing: false
    },
    s63_payment: {
        back_from_store: false,
        payment_success: false,
    },
    s63_primar: {
      info_loaded: false,
    },
    geocontent: {
        s63_id: 0,
    },
    product: {
        current: "",
    },
    hints: {
        showDevicesHint: true,
        showChartsHint: true,
    },
    ecdis: {
        edit: false,

        email: '',
        ship_name: '',
        ship_imo: '',
        ship_mmsi: '',
        ship_callsign: '',
        ship_category: '',
        country: '',

        ecdis_brand: '',
        ecdis_os: '',
        ecdis_type: '',

        permit_main: '',
        permits_backup: [],

    },
    agreement: {
        id: -1,
        currency: '',
        validdate: ''
    }
}

export const current = {
    namespaced: true,
    state: initialState,
    actions: {
        getPeriodList({dispatch, commit}, products){
            console.log(file, "getPeriodList : start")
            const cartItems = products;
            console.log(cartItems)
            let result = {}
            if (cartItems[0]) {
                let intersection = cartItems[0].periods
                cartItems.forEach(item => {
                    const set = item.periods;
                    console.log(item, file)
                    intersection = intersection.filter(x => set.includes(x));
                })
                intersection.forEach(period => {

                    if (period === 12 || period === 6 || period === 3) {
                        console.log(hashPeriodBands[period], period)
                        result[hashPeriodBands[period]] = BandPeriods[hashPeriodBands[period]];
                    }
                })
            }

            // change period for S102 maps
            cartItems.forEach(item => {
                if (item.id.startsWith("102"))
                    result = {0: "12 months", 1: "6 months" };
            })

            commit("setCurrentPeriodList", result);
            console.log(file, "getPeriodList : end")
        },
        getWebUserLocation({commit}) {
            console.log(file, "getWebUserLocation");
            // axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=6bdbc2eb8ac6474489f7b5410edef0ec').then( resp => {
            //     commit("setWebUserLocation", {ip: resp.data.ip_address, location: resp.data.country_code});
            // })
            axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=c4ef09fdb3104249a0e720e8cd1a92a0').then( resp => {
                commit("setWebUserLocation", {ip: resp.data.ip_address, location: resp.data.country_code});
            })
        },
        getCellPrice({commit}, {cell, periodBand, agreementID, deviceID, appType}) {

            let cellArray = store.state.current.cart.cellPrices
            let objIndex = cellArray.findIndex((obj => obj.id === cell.id));

            console.log("aaaaaaaaaaa")
            console.log("aaaaaaaaaaa")
            console.log("aaaaaaaaaaa")
            console.log("aaaaaaaaaaa")
            console.log(cell.id, objIndex)

            if (objIndex !== -1)
                return

            let items = [];
            items.push(cell.id)

            let quotate = {
                items: items,
                subscriptionType: periodBand,
                agreementId: agreementID,
                deviceId: deviceID,
                applicationTypeId: appType
            };

            myAxios.post('/api/v1/orders/primar/quotate/v2', quotate).then( resp => {
                console.log(resp.data)
                // let cellPrice = Number(resp.data.userEuro - resp.data.userEuroAdd).toFixed(2)
                let cellPrice = Number(resp.data.euroPrice).toFixed(2)
                commit("setCellPrice", {id: cell.id, price: cellPrice})
            })

        },
        getTotalPrice({commit}, {products, periodBand, agreementID, deviceID, appType}){
            return new Promise(resolve => {
                console.log(file, "getTotalPrice : start");
                console.log('cells', products);
                console.log('period', periodBand);
                if (products.length > 0 && periodBand) {
                    let items = [];
                    products.forEach(x => {
                        if (!x.disabled)
                            items.push(x.id)
                    })

                    let quotate = {
                        items: items,
                        subscriptionType: periodBand,
                        agreementId: agreementID,
                        deviceId: deviceID,
                        applicationTypeId: appType
                    };

                    // TODO NAVICOM
                    if (CURRENT_PRODUCT.is_navicom) {
                        commit("setTotalPriceByPrimar",
                            {   euro: 4 * products.length,
                                rub: 350 * products.length,
                                agreementUsd: 0,
                                agreementRub: 0,
                                displayPrice: 350 * products.length,
                            }
                        )
                        console.log('1')
                    } else {
                        myAxios.post('/api/v1/orders/primar/quotate/v2', quotate).then( resp => {
                            // cell.price = price;
                            commit("setTotalPriceByPrimar",
                                {   euro: Number(resp.data.userEuro).toFixed(2),
                                    rub: Number(resp.data.userRub).toFixed(2),
                                    euroadd: Number(resp.data.userEuroAdd).toFixed(2),
                                    euronet: Number(resp.data.euroPrice).toFixed(2),
                                    agreementUsd: Number(resp.data.agreementUsd).toFixed(2),
                                    agreementRub: Number(resp.data.agreementRub).toFixed(2),
                                    displayPrice: Number(resp.data.displayPrice).toFixed(2),
                                }
                            )
                            console.log('2')
                            resolve(this.state.totalPriceByPrimar);
                        })

                    }

                }
                else {commit("setTotalPriceByPrimar", ''); console.log('3');}


                console.log(file, "getTotalPrice : end")

            })

        },
        addTotalPriceByGP({commit}, fixedPrice) {
            commit("setTotalPriceByGP", fixedPrice.toFixed(2));
        },
        addProfile({commit}, payload) {
            commit("setProfile", payload);
        },
        addGeoContent({commit}, payload) {
            commit("setGeocontent", payload);
        },
        addPrimarMapInfosState({commit}, payload) {
            commit("setPrimarMapInfosState", payload);
        },
        async addS63Cart({commit, dispatch}, payload) {
            console.log(file, "addS63Cart : start")
            console.log(payload)

            dispatch("getPeriodList", payload);

            commit("setS63Cart", payload);
            console.log(file, "addS63Cart : end")
        },
        addLoadingMarker({commit}, marker) {
            commit("pushLoadingMarker", marker);
        },
        removeLoadingMarker({commit}, marker) {
            commit("popLoadingMarker", marker);
        },
        loadingGeoContent({commit}, is_loading) {
            commit("setGeoContentStatus", is_loading);
        },
        loadingOrders({commit}, is_loading) {
            commit("setOrdersStatus", is_loading);
        },
        loadingSummary({commit}, is_loading) {
            commit("setOrdersStatus", is_loading);
        },
        drawing({commit}, is_loading) {
            commit("setDrawStatus", is_loading);
        },
        updatePaymentStatus({commit}, payload) {
            commit("setPaymentStatus", payload);
        },
        updateCellStaus({commit}, id) {
            console.log(id)
            commit("setCellStaus", id)
            //Find index of specific object using findIndex method.



            // //Update object's name property.
            // myArray[objIndex].name = "Laila"
            //
            // //Log object to console again.
            // console.log("After update: ", myArray[objIndex])

        }
    },
    mutations: {
        setCellStaus(state, id) {
            let objIndex = state.cart.s63cells.findIndex((obj => obj.id === id));
            state.cart.s63cells[objIndex].disabled = !state.cart.s63cells[objIndex].disabled
            //
            // console.log("aaaaaaa")
            // console.log(objIndex)
        },
        setWebUserLocation(state, payload) {
            state.webuser.location = payload.location;
            state.webuser.ip = payload.ip;
        },
        setCellPrice(state, payload) {
            console.log(payload.id, payload.price)
            // state.cart.cellPrices[payload.id] = payload.price
            let x = {}
            // x[payload.id] = payload.price
            state.cart.cellPrices.push({id:payload.id, price:payload.price})
            // Vue.set(state.cart.cellPrices, payload.id, payload.price)
        },
        setTotalPriceByPrimar(state, payload){
            state.cart.totalPriceByPrimar = payload.euro;
            state.cart.totalPriceByPrimarNet = payload.euronet;
            state.cart.totalPriceByPrimarAdd = payload.euroadd;
            state.cart.totalPriceByPrimarRUB = payload.rub;
            // state.cart.totalPriceByPrimarEURO_RU = payload.rubineuro;
            state.cart.totalPriceByPrimarDisplay = payload.displayPrice;
            // state.cart.totalPriceByPrimarAgreementEURO_RU = payload.agreementUsd;
            state.cart.totalPriceByPrimarAgreementRUB = payload.agreementRub;
            state.cart.totalPriceByPrimarAgreementUSD = payload.agreementUsd;

        },
        setTotalPriceByGP(state, payload){
            state.cart.totalPriceByGP = payload;
        },
        setGeocontent(state, payload) {
            state.geocontent.s63_id = payload.s63_id;
        },
        setProfile(state, payload) {
            Object.assign(state.profile, initialState.profile);

            state.profile.device_id = payload.device_id;
            state.profile.user_id = payload.user_id;
            state.profile.email = payload.email;
            state.profile.device_os = payload.device_os;
            state.profile.app_name = payload.app_name;
            state.profile.app_version = payload.app_version;
            state.profile.pos_lat = payload.pos_lat;
            state.profile.pos_lon = payload.pos_lon;
        },
        setPrimarMapInfosState(state, payload) {
            state.s63_primar.info_loaded = payload;
        },
        setS63Cart(state, payload) {
            console.log("------------")
            console.log(state.cart.s63cells)
            console.log(payload)

            let oldCart = state.cart.s63cells
            state.cart.s63cells = payload;

            oldCart.forEach((item) => {
                if (item.disabled) {
                    console.log(item.id)
                    let id = item.id;
                    let index = state.cart.s63cells.findIndex((obj => obj.id === id));
                    console.log(index)
                    if (index !== -1)
                        state.cart.s63cells[index].disabled = true
                }
            })

        },
        setAgreement(state, payload) {
          state.agreement.id = payload.id;
          state.agreement.currency = payload.currency;
          state.agreement.validdate = payload.validtill;
        },
        pushLoadingMarker(state, marker) {
            state.loading.loadingMarkers.push(marker)
        },
        popLoadingMarker(state, marker) {
            const index = state.loading.loadingMarkers.indexOf(marker);
            if (index > -1) { // only splice array when item is found
                state.loading.loadingMarkers.splice(index, 1); // 2nd parameter means remove one item only
            }
        },
        setGeoContentStatus(state, is_loading) {
            state.loading.geocontent = is_loading;
        },
        setPrimarInfoStatus(state, is_loading) {
            if (is_loading === false) {
                setTimeout(function(){
                }, 3000);
            }
            state.loading.primarInfo = is_loading;
        },
        setOrdersStatus(state, is_loading) {
            state.loading.orders = is_loading;
        },
        setDrawStatus(state, is_loading) {
            state.loading.drawing = is_loading;
        },
        setPaymentStatus(state, payload) {
            state.s63_payment.back_from_store = payload.back_from_store;
            state.s63_payment.payment_success = payload.is_success;
        },
        setCurrentPeriodList(state, paylolad){
            state.cart.currentBandPeriods = paylolad;
        },
        setCurrentProduct(state, product){
            state.product.current = product;
        },
        setCurrentDraught(state, draught) {
            state.ship.draught = draught;
        },
        setMConnectUser(state, payload) {
            state.user_type.is_mconnect = payload.is_mconnect;
            state.user_type.device_id = payload.device_id;
        },
        setHintsCharts(state, flag) {
            state.hints.showChartsHint = flag;
        },
        setHintsDevices(state, flag) {
            state.hints.showDevicesHint = flag;
        },
        setEditECIDSFlag(state, flag) {
            state.ecdis.edit = flag;
        },
        setECDIS(state, payload) {
            state.ecdis.email = payload.ecids_emails
            state.ecdis.ship_name = payload.ecids_ship_name
            state.ecdis.ship_imo = payload.ecids_ship_imo
            state.ecdis.ship_mmsi = payload.ecids_ship_mmsi
            state.ecdis.ship_callsign = payload.ecids_ship_callsign
            state.ecdis.ship_pid = payload.ecids_ship_pid
            state.ecdis.ship_category = payload.ecids_ship_category
            state.ecdis.ecdis_brand = payload.ecids_brand
            state.ecdis.ecdis_os = payload.ecids_os
            state.ecdis.country = payload.country
            state.ecdis.permit_main = payload.ecids_permit
            state.ecdis.permits_backup = payload.ecids_backup_permit
        }
    }
}