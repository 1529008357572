<template>
  <b-container fluid>

    <div v-if="parent !== 'devices'">

    <b-row>
      <!--app select-->
      <b-col cols="9">
        <b-form-select
            v-model="selectedAppType"
            v-on:change="appTypeSelected"
            :options="appsList"
            class="mb-0"
            size="sm"
            value-field="item"
            text-field="name"
        ></b-form-select>
      </b-col>
      <!--search button-->
      <b-col>
        <b-button :pressed="null" size="sm" variant="light"
                  @click='isShowSearch = !isShowSearch;$event.target.blur()'>
          <font-awesome-icon v-if="!isShowSearch" icon="fa-solid fa-search"/>
          <font-awesome-icon v-if="isShowSearch" icon="fa-solid fa-times"/>
        </b-button>
      </b-col>
    </b-row>
    <!--search field    -->
    <b-row class="mt-2" v-show='isShowSearch'>
      <b-col>
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="searchFilter"
                debounce="500"
                type="search"
                placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="light" :disabled="!searchFilter" @click="searchFilter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    </div>

    <div v-else>

      <b-row>
        <!--search button-->
        <b-col>
          <b-button :pressed="null" size="sm" variant="light"
                    @click='isShowSearch = !isShowSearch;$event.target.blur()'>
            <font-awesome-icon v-if="!isShowSearch" icon="fa-solid fa-search"/>
            <font-awesome-icon v-if="isShowSearch" icon="fa-solid fa-times"/>
          </b-button>
        </b-col>
      </b-row>
      <!--search field    -->
      <b-row class="mt-2" v-show='isShowSearch'>
        <b-col>
          <b-form-group>
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="searchFilter"
                  type="search"
                  placeholder="Type to Search"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="light" :disabled="!searchFilter" @click="searchFilter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-table
        id="installations-table"
        ref="table1"
        class="secondary text-nowrap mt-3"
        primary-key="aindex"
        :items="itemsByAppFilter()"
        :sticky-header="parent === 'devices'"
        :fields="fieldsByTabFilter"
        :filter="searchFilter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        select-mode="single"
        show-empty
        selectable
        selected-variant=""
        responsive
        sort-icon-left
        small
        hover
        @row-selected="onRowSelected"
    >

      <template v-slot:cell()="data">
        <span v-b-tooltip.hover :title=data.value>{{ data.value}}</span>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner small type="grow" variant="warning"></b-spinner>
          <span class="small-gray">  loading...</span>
        </div>
      </template>

      <template #cell(appState.positionInfo.datetimeUtc)="data">
        <span v-if="daysDiff(data.value, data.item) === 0" class="row-green">{{ formatDate(data.value) }}</span>
        <span v-if="daysDiff(data.value, data.item) > 0 && daysDiff(data.value, data.item) <= 1"
              class="row-yellow">{{ formatDate(data.value) }}</span>
        <span v-if="daysDiff(data.value, data.item) > 1 && daysDiff(data.value, data.item) <= 7"
              class="row-red">{{ formatDate(data.value) }}</span>
        <span v-if="daysDiff(data.value, data.item) > 7 && daysDiff(data.value, data.item) <= 30"
              class="row-cyan">{{ formatDate(data.value) }}</span>
        <span v-if="daysDiff(data.value, data.item) > 30">{{ formatLostDate(data.value, data.item) }}</span>
      </template>

      <!--      <template #cell(platformName)="data">-->
      <template #cell(appState.programInfo.platform)="data">
        <font-awesome-icon v-if="data.value === 'iOS'" icon="fa-brands fa-apple"/>
        <font-awesome-icon v-if="data.value === 'Android'" icon="fa-brands fa-android"/>
        <font-awesome-icon v-if="data.value === 'WinPC' || data.value === 'Windows'" icon="fa-brands fa-windows"/>
        <font-awesome-icon v-if="data.value === ''" icon="fa-solid fa-question"/>
      </template>

      <template #cell(hasStatistics)="data">
        <font-awesome-icon v-if="data.value" icon="fa-solid fa-chart-simple"/>
      </template>

<!--      <template #cell(imo)="data">-->
<!--        <font-awesome-icon v-if="data.value" icon="fa-solid fa-chart-simple"/>-->
<!--      </template>-->

      <template #cell(email)="data">
        <font-awesome-icon v-if="data.value !== ''" icon="fa-solid fa-at"/>
      </template>
    </b-table>

    <!--total-->
    <div v-if="parent !== 'devices'" class="small-gray">Selected: {{ filteredItemsCount }} (total: {{ totalItemsCount }})</div>

  </b-container>
</template>

<script>
import {eventBus} from '@/main'
import {ActiveTab} from "@/utils/config";
import * as utils from "@/utils/utils";
import {file} from 'paths.macro';
import {test} from "@/utils/fields";
import {commit} from "lodash/seq";
import router from "@/router";
import {IS_NP} from "@/utils/build-config";
import {getCatalog, getFilesList} from "@/requests/axios-requests";

export default {
  components: {},
  data() {
    return {
      selectedAppType: (!IS_NP ? this.selectedAppTypeInit : 'np'),
      items: [],
      fields: [
        // {
        //   key: 'aindex',
        //   label: '#', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray', ownVisible: true
        // },
        {
          key: 'appState.programInfo.version',
          label: 'Ver', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray', ownVisible: true, onlyECDIS: false
        },
        {
          key: 'appState.programInfo.platform',
          label: 'OS', tdClass: 'smt7', thClass: 'small-gray', ownVisible: true, onlyECDIS: false
        },
        {
          key: 'appState.programInfo.name',
          label: 'App', tdClass: 'small-black', thClass: 'small-gray', ownVisible: true, onlyECDIS: false
        },

        {
          key: 'appState.shipInfo.name',
          label: 'Ship', sortable: true, tdClass: 'small-black', thClass: 'small-gray', ownVisible: true, onlyECDIS: false, class: 'truncate_name'
        },
        // {
        //   key: 'appState.shipInfo.imo',
        //   label: 'IMO', tdClass: 'smt7', thClass: 'small-gray', ownVisible: true
        // },
        {
          key: 'email',
          label: '@', sortable: true, sortDirection: 'desc', tdClass: 'smt7', thClass: 'small-gray', ownVisible: false, onlyECDIS: false
        },
        {
          key: 'hasStatistics',
          label: '#', sortable: true, sortDirection: 'desc', tdClass: 'smt7', thClass: 'small-gray', ownVisible: false, onlyECDIS: false
        },
        {
          key: 'appState.positionInfo.datetimeUtc',
          label: 'Date', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray', ownVisible: true, onlyECDIS: false
        },
      ],
      filteredItemsCount: 0,
      totalItemsCount: 0,
      isBusy: false,
      isShowSearch: false,
      transProps: {name: 'flip-list'},
      sortBy: 'appState.positionInfo.datetimeUtc',
      sortDesc: true,
      searchFilter: null,
      filterOn: ['ppState.programInfo.platform', 'email', 'deviceId', 'appState.programInfo.version', 'appState.shipInfo.name', 'appState.shipInfo.imo'],
    }
  },
  props: ["installationsList", "appsList", "map", "selectedAppTypeInit", "parent"],
  mounted() {
    console.log(this.$store.state.current.user_type.is_mconnect)

    //TODO
    // this.itemsByAppFilter().length

    // this.$refs.table1.selectRow(1);
    // this.scrollToRow(1);


    eventBus.$on('tableReady', () => {

      if (this.$store.state.current.user_type.is_mconnect) {

        let x = setTimeout(() => {
          this.$refs.table1.selectRow(0)

          let y = setTimeout(() => {
            router.push({name: 'charts'});
          }, 1000)

        }, 1)
      } else if (this.itemsByAppFilter().length >= 1) {
        let x = setTimeout(() => {
          if (this.$refs.table1)
            this.$refs.table1.selectRow(0) // TODO
        }, 1)
      }
    })

    eventBus.$on('installationsReceived', () => {
          if (!IS_NP)
            this.appTypeSelected(this.selectedAppTypeInit)
          else
            this.appTypeSelected('np')
        }
    )
  },
  watch: {

    // 'this.$refs.table1.items[0]': function () {
    //   console.log(this.$refs.table1.items[0])
    // },

    '$store.state.manage.is_loading': function () {
      this.isBusy = this.$store.state.manage.is_loading;

      // if (!this.isBusy) {
      //   let x = setTimeout(() => {
      //     this.$refs.table1.selectRow(0)
      //
      //     let y = setTimeout(() => {
      //       router.push({name: 'charts'});
      //     }, 1)
      //
      //   }, 1)



      // }
    },
  },
  computed: {
    fieldsByTabFilter() {
      if (utils.currentRouterId() === ActiveTab.DEVICES) {
        return this.fields.filter(field => field.ownVisible);
      } else {
        if (this.selectedAppType === 'ECDIS') {
          return this.fields;
        } else {
          return this.fields.filter(field => (field.onlyECDIS === false));
        }
      }
    },
  },
  methods: {
    licenseTypeSelected(option) {
      console.log(file, "licenseTypeSelected: " + option);
    },
    is_mconnect_user() {
      return this.$store.state.current.user_type.is_mconnect;
    },
    appTypeSelected(option) {
      console.log(file, "appTypeSelected: " + option);

      this.$store.dispatch('changeAppsFilter', option).then(() => {
        this.items = this.itemsByAppFilter();
        this.$emit('app-type-selected', this.items);
        this.$root.$emit('bv::refresh::table', 'installations-table')
        eventBus.$on('ship-clicked', this.onShipClicked)

      })
    },
    itemsByAppFilter() {
      let filter = this.$store.state.manage.apps_filter[utils.currentRouterId()];
      let filteredList = utils.filterByAppName(this.installationsList, filter);
      if (filteredList) {
        this.filteredItemsCount = filteredList.length;
        this.totalItemsCount = this.installationsList.length;
      }

      return filteredList;
    },
    daysDiff(value, item) {
      if (/*item.appState && item.appState.isActive !== undefined &&*/ item.appState.isActive) {
        return 0;
      }
      return utils.daysOld(value);
    },
    formatDate(value) {
      return utils.formatDate(value).isvalid ? utils.formatDate(value).date : '-'
    },
    formatDateTime(value) {
      return utils.formatDate(value).datetime
    },
    formatLostDate(value, item) {
      let dateUTC = item.appState.positionInfo.timestampUtc;
      let dateUTCFormated = utils.formatDate(dateUTC).isvalid ? utils.formatDate(dateUTC).date : '-';

      return utils.formatDate(value).isvalid ? utils.formatDate(value).date : dateUTCFormated
    },
    onRowSelected(items) {
      // this.map.resetAll();
      this.selected = items

      this.$store.commit('dropS63LatestOrder');

      this.$store.dispatch('current/addProfile',
          {
            device_id: items.at(0).deviceId,
            user_id: items.at(0).userId,
            email: items.at(0).appState.programInfo.activeEmail,
            app_version: items.at(0).appState.programInfo.version,
            app_name: items.at(0).appState.programInfo.name,
            device_os: items.at(0).appState.programInfo.platform,
            pos_lat: items.at(0).appState.positionInfo.latDegree,
            pos_lon: items.at(0).appState.positionInfo.lonDegree,
          });

      // reset selected filter
      this.$store.dispatch('changeFilesFilter', 'appstate');

      if (items.length > 0) {

        this.$store.commit('devices/setSelectedDevice', items.at(0).deviceId);
        this.$store.commit('devices/setSelectedDeviceId', items.at(0).deviceId);

        this.$store.dispatch('current/loadingGeoContent', true).then(() => {
        });
        this.$store.dispatch('current/removeLoadingMarker', "geocontent");
        this.$store.dispatch('getAppState', items.at(0).deviceId).then((appstate) => {
          localStorage.setItem('appState', JSON.stringify(appstate));
          //console.log(localStorage.getItem('appState'), appstate)
          this.$store.dispatch('current/loadingGeoContent', false).then(() => {
          });
          this.$store.dispatch('current/removeLoadingMarker', "geocontent");
        });

        let deviceId = items.at(0).deviceId;
        let aindex = items.at(0).aindex;
        console.log("------------- 1")
        getCatalog(deviceId, aindex);
        console.log("------------- 2")
        getFilesList(deviceId, aindex)
        console.log("------------- 3")

        // this.$store.dispatch('getFilesList', {deviceId: items.at(0).deviceId, aindex: items.at(0).aindex}).then(() => {
        // });

        this.$root.$emit('bv::refresh::table', 'files-table');
        eventBus.$emit('reset_files_filter');

        eventBus.$emit('item_selected', {aindex: items.at(0).aindex});
        console.log(items)
        eventBus.$emit('show_details', {
          lat: items.at(0).appState.positionInfo.latDegree,
          lon: items.at(0).appState.positionInfo.lonDegree,
          cog: items.at(0).appState.positionInfo.cogDegree,
          id: items.at(0).deviceId,
          days: items.at(0).appState.positionInfo.datetimeUtc,
          aindex: items.at(0).aindex,
          name: items.at(0).appState.shipInfo.name,
          route: items.at(0).appState.activeRoute,
          sog: utils.roundNumber(items.at(0).appState.positionInfo.sogKnot),
          // email: items.at(0).appState.email,
          // email: items.at(0).email ? items.at(0).email : this.$store.state.auth.user.email,
          email: items.at(0).appState.programInfo.activeEmail,
          isActive: items.at(0).appState.isActive,
          routeJson: items.at(0).appState && items.at(0).appState.activeRouteJson ? JSON.parse(items.at(0).appState.activeRouteJson) : [],
          user_id: items.at(0).userId,

          device_id: items.at(0).deviceId,
          device_platform: items.at(0).appState.programInfo.platform,
          // device_email: items.at(0).email,
          device_email: items.at(0).appState.programInfo.activeEmail,
          device_license: items.at(0).productInfo ? items.at(0).productInfo.licenseId : "",

          app_name: items.at(0).appState.programInfo.name,
          app_ver: items.at(0).appState.programInfo.version,
          app_reqkey: items.at(0).appState.programInfo.regKey,
          app_route: items.at(0).appState.activeRoute,
          app_active: items.at(0).appState.isActive,

          prod_id: items.at(0).productInfo ? items.at(0).productInfo.id : "",
          prod_title: items.at(0).productInfo ? items.at(0).productInfo.title : "",
          prod_licenseType: items.at(0).productInfo ? items.at(0).productInfo.licenseType : "",
          prod_provider: items.at(0).productInfo ? items.at(0).productInfo.provider : "",
          prod_price: items.at(0).productInfo ? items.at(0).productInfo.price : "",
          prod_enc: items.at(0).productInfo ? items.at(0).productInfo.enc : "",
          prod_s102: items.at(0).productInfo ? items.at(0).productInfo.s102 : "",
          prod_poi: items.at(0).productInfo ? items.at(0).productInfo.poi : "",
          prod_ntm: items.at(0).productInfo ? items.at(0).productInfo.ntm : "",
          prod_ais: items.at(0).productInfo ? items.at(0).productInfo.ais : "",
          prod_weather: items.at(0).productInfo ? items.at(0).productInfo.weather : "",
          prod_navMsg: items.at(0).productInfo ? items.at(0).productInfo.navMsg : "",
          prod_noaaWeather: items.at(0).productInfo ? items.at(0).productInfo.noaaWeather : "",
          prod_pollution: items.at(0).productInfo ? items.at(0).productInfo.pollution : "",

          ship_name: items.at(0).appState.shipInfo.name,
          ship_mmsi: items.at(0).appState.shipInfo.mmsi,
          ship_imo: items.at(0).appState.shipInfo.imo,
          ship_length: utils.roundNumber(items.at(0).appState.shipInfo.length),
          ship_width: utils.roundNumber(items.at(0).appState.shipInfo.width),
          ship_height: utils.roundNumber(items.at(0).appState.shipInfo.height),
          ship_draught: utils.roundNumber(items.at(0).appState.shipInfo.draught),
          ship_sensore: items.at(0).appState.sensorInfo.name,

          pos_hdop: items.at(0).appState.positionInfo.hdop,
          pos_lat: items.at(0).appState.positionInfo.latDegree,
          pos_lon: items.at(0).appState.positionInfo.lonDegree,
          pos_cog: utils.roundNumber(items.at(0).appState.positionInfo.cogDegree),
          pos_sog: utils.roundNumber(items.at(0).appState.positionInfo.sogKnot),
          pos_hdg: items.at(0).appState.positionInfo.courseDegree === -3000 ? "" : utils.roundNumber(items.at(0).appState.positionInfo.courseDegree),
          pos_speed: items.at(0).appState.positionInfo.speedKnot,
          pos_mac: items.at(0).appState.positionInfo.macDegree,
          pos_rot: items.at(0).appState.positionInfo.rotationDpm === -3000 ? "" : utils.roundNumber(items.at(0).appState.positionInfo.rotationDpm),
          pos_datetime: this.formatDateTime(items.at(0).appState.positionInfo.datetimeUtc),
          pos_date: this.formatDate(items.at(0).appState.positionInfo.datetimeUtc).date,
          pos_time: this.formatDate(items.at(0).appState.positionInfo.datetimeUtc).isvalid ? this.formatDate(items.at(0).appState.positionInfo.datetimeUtc).isvalid : '',
          pos_tick: items.at(0).appState.positionInfo.tick,
          pos_lost: items.at(0).appState.positionInfo.isLost,
          pos_satellites: items.at(0).appState.positionInfo.satellitesCount,

          ecids_permit: items.at(0).primarInfo ? items.at(0).primarInfo.primarPermit : "",
          ecids_backup_permit: items.at(0).primarInfo ? items.at(0).primarInfo.backupPermits : "",
          ecids_emails: items.at(0).primarInfo ? items.at(0).primarInfo.notificationEmails : "",
          ecids_ship_name: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.name : "",
          ecids_ship_imo: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.imo : "",
          ecids_ship_mmsi: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.mmsi : "",
          ecids_ship_callsign: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.callSign : "",
          ecids_ship_pid: items.at(0).primarInfo ? items.at(0).appState.shipInfo.customId : "",
          ecids_ship_category: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.category : "",
          ecids_brand: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.ecdisBrand : "",
          ecids_os: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.ecdisOs : "",
          ecids_type: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.ecdisType : "",
          country: items.at(0).primarInfo ? items.at(0).primarInfo.shipInfo.flag : "",


          device_products: utils.productsByDeviceId(this.installationsList, items.at(0).deviceId),

          files: items.at(0).files,
        })

        let days = items.at(0).appState.positionInfo.datetimeUtc;
        this.map.paneToShip(items.at(0).aindex, items.at(0).deviceId, items.at(0).appState.shipInfo.name, items.at(0).appState.activeRoute, utils.roundNumber(items.at(0).appState.positionInfo.sogKnot), items.at(0).email,
            items.at(0).appState.isActive, days);
      }
    },
    onShipClicked(id) {
      if (this.$refs.table1) {
        let x = this.$refs.table1.computedItems;
        let row = this.$refs.table1.computedItems.findIndex(x => x.aindex === id);
        console.log(id, row, this.items);
        this.$refs.table1.selectRow(row);
        this.scrollToRow(row);
      }
    },
    scrollToRow(index) {
      const tbody = this.$refs.table1.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[index]
      row.scrollIntoView()
    }
  }
}
</script>

<style>
@import '@/assets/styles/styles.css';

.truncate_name {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>

<style scoped>
/deep/ .table > tbody > tr.b-table-row-selected,
/deep/ .table > tbody > tr.b-table-row-selected > td,
/deep/ .table > tbody > tr.b-table-row-selected > th {
  background-color: #bee5eb;
}

</style>

