<template>
  <div class="row">

    <b-modal v-if="!IS_NP()" id="modal-devices" title="Hint" v-model="showHint" ok-only @ok="okHandler">
      <p class="my-4">To purchase Primar maps select any your registered device and proceed to Charts menu.</p>
      <img src="https://mkartweb.com/pics/hint_01.png" width="300">
      <p class="my-4">For any questions please write us to <a href="mailto:support@mkart.fi">support@mkart.fi</a> </p>
    </b-modal>

    <div class="col-12">
      <div class="row">
        <div class="col-4">
          <!--          <div class="row">-->
          <!--            <div class="col-6 border-right" style="height: 800px; overflow-y: scroll;">-->
          <!--                             :installationsList="this.$store.state.manage.installations.at(getCurrentRouter())"-->

          <b-badge variant="light">DEVICES</b-badge>
          <br><br>
          <installations :map=map
                         :installationsList="getInstallationsList()"
                         :appsList="optionsAppType"
                         :licensesList="licensesType"
                         selectedAppTypeInit='all'
                         parent='devices'
                         @app-type-selected="this.displayShips"
          ></installations>

          <!--            </div>-->

          <!--            <div class="col-6 border-right" style="height: 800px; overflow-y: scroll;">-->
          <!--              <b-badge variant="light">DATA</b-badge>-->
          <!--              <br/><br/>-->

          <!--              <b-form-select-->
          <!--                  v-model="selectedFileType"-->
          <!--                  v-on:change="filesTypeSelected"-->
          <!--                  :options="filesList"-->
          <!--                  class="mb-0"-->
          <!--                  size="sm"-->
          <!--                  value-field="item"-->
          <!--                  text-field="name"-->
          <!--              ></b-form-select>-->
          <!--              <br><br>-->

<!--          <div v-show="this.$store.state.manage.files_filter === 'appstate'">-->
            <app-state-devices :map=map :geocontent_list="geocontentList"></app-state-devices>
<!--          </div>-->

          <!--              <div v-show="this.$store.state.manage.files_filter !== 'appstate'">-->
          <!--                <all-files :inst_list="filesList"></all-files>-->
          <!--              </div>-->

          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="col-8">
          <app-state-map :map=map></app-state-map>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AllFiles from "@/views/manage/files/AllFiles";
import Installations from "@/views/manage/Installations";
import AppStateDevices from "@/views/devices/AppStateDevices.vue";
import AppStateMap from "@/views/devices/AppStateMap";
import Statistic from "@/views/manage/files/Statistic";
import Map from "@/components/Map";
import {file} from 'paths.macro';
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import {NavLeaf} from "@/my-leaf/nav-leaf";
import {commit} from "lodash/seq";
import {IS_NP} from "@/utils/build-config";

export default {
  components: {Installations, AppStateDevices, AppStateMap, AllFiles, Statistic, MyMap: Map},
  data() {
    return {
      showHint: false,

      // openDialog: true,
      map: new NavLeaf(),
      licensesType: [
        {item: 'l0', name: 'All products'},
        {item: 'l1', name: 'USA ENCs & NPs'},
        {item: 'l2', name: 'Norway ENCs & NPs'},
        {item: 'l3', name: 'Estonia  ENCs & NPs'},
      ],
      optionsAppType: [
        {item: 'all', name: 'All'},
        {item: 'mkart', name: 'mKart'},
        {item: 'np', name: 'Njord Pilot'},
        {item: 'npm', name: 'Njord Pilot Mobile'},
        {item: 'nv', name: 'Vega'},
        {item: 'm-Connect', name: 'm-Connect'},
        {item: 'ECDIS', name: 'ECDIS'},
      ],

      selected_item_id: -1,
      selectedFileType: 'appstate',
      // filesTypes: [
      //   {item: 'appstate', name: 'AppState'},
      //   {item: 'poi', name: 'Bookmarks'},
      //   {item: 'logbook', name: 'Logbooks'},
      //   {item: 'navalcatalog', name: 'Naval Catalog'},
      //   {item: 'nmealog', name: 'NMEA Logs'},
      //   {item: 'profile', name: 'Profiles'},
      //   {item: 'routes', name: 'Routes'},
      //   {item: 'settings', name: 'Settings'},
      //   {item: 'shipslibrary', name: 'Ships Library'},
      //   {item: 'userlayers', name: 'User Layers'},
      //   {item: 'statisticsdata', name: 'Statistics', notEnabled: false},
      // ],
    }
  },
  watch: {
    geocontentList() {
      if (this.$store.state.manage.selected_geocontent) {
        localStorage.setItem('selected-geocontent', JSON.stringify(this.$store.state.manage.selected_geocontent));
      }
    }
  },
  computed: {
    instList() {
      return this.$store.state.manage.installations.at(this.getCurrentRouter());
    },
    filesList() {
      return this.$store.state.manage.files_list[this.selected_item_id];
    },
    geocontentList() {
      this.$store.dispatch('changeSelectedGeocontent', this.$store.state.manage.geocontent_list[this.selected_item_id]).then(() => {
      })

      return this.$store.state.manage.geocontent_list[this.selected_item_id];
    }
  },
  created() {
    eventBus.$on('reset_files_filter', data => {
      this.selectedFileType = 'appstate';
    });
    eventBus.$on('item_selected', data => {
      this.selected_item_id = data.aindex;
    });
  },
  mounted() {
    console.log(file, "mounted");

    this.showHint = localStorage.getItem('disable-devices-hint') === null ? true : false ;
    console.log(this.showHint)


    this.$store.dispatch('getInstallations').then(() => {
      eventBus.$emit('installationsReceived');
    })

    // if (!this.$store.state.current.s63_primar.info_loaded) {
    // this.$store.dispatch('getENCs')
    // }

    // this.$store.dispatch('getAllProducts').then(() => {
    // })
  },
  methods: {
    IS_NP() {
      return IS_NP
    },
    getInstallationsList() {
      let allList = this.$store.state.manage.installations.at(this.getCurrentRouter())
      let a1 = allList
      if (this.$store.state.current.user_type.is_mconnect) {
        let deviceID = this.$store.state.current.user_type.device_id
        a1 = allList.filter(function (e) {
          return e.deviceId === deviceID;
         });
      }
      else {
        if (allList) {
          a1 = allList.filter(function (e) {
            return e.appState !== null;
          });
        }
      }

      // let mConnectList = allList.filter(function (e) {
      //   return e.deviceId === 'AD8A-ED21-41E3-FD19';
      //   // return e.aindex === 1;
      // });

      // console.log("aaaaaaaa aaaaaaaaa aaaaaaaaaaa aaaaaaaaaaa")
      // console.log(allList)
      // console.log(mConnectList)

      return a1;
    },
    displayShips(items) {
      console.log(file, 'shtp!')
      this.map.clearAppStates();
      let filteredInstList = items;
      if (filteredInstList) {
        filteredInstList.forEach((inst) => {
          if (inst && inst.appState && inst.appState.positionInfo) {
            let lat = inst.appState.positionInfo.latDegree;
            let lon = inst.appState.positionInfo.lonDegree;
            let cog = inst.appState.positionInfo.cogDegree;
            let id = inst.deviceId;
            let days = inst.appState.positionInfo.datetimeUtc;
            let gpsDate = inst.appState.positionInfo.datetimeUtc;
            let appStateDate = inst.appState.positionInfo.timestampUtc;
            let aindex = inst.aindex;
            let name = inst.appState.shipInfo.name;
            let route = inst.appState.activeRoute;
            let sog = utils.roundNumber(inst.appState.positionInfo.sogKnot);
            let email = inst.appState.email;
            let isActive = inst.appState.isActive;
            let routeJson = inst.appState && inst.appState.activeRouteJson ? JSON.parse(inst.appState.activeRouteJson) : [];

            console.log("aaaaaaaaaaaa")
            console.log("aaaaaaaaaaaa")
            console.log("aaaaaaaaaaaa")
            console.log("aaaaaaaaaaaa")
            console.log(aindex, lat, lon, cog, id, isActive, name, route, sog, email, gpsDate, appStateDate, routeJson)

            this.map.showAppState(aindex, lat, lon, cog, id, isActive, name, route, sog, email, gpsDate, appStateDate, routeJson);
          }
        })
      }
    },
    filesTypeSelected(option) {
      console.log(file, "filesTypeSelected: " + option);
      this.$store.dispatch('changeFilesFilter', option).then(() => {
        this.$root.$emit('bv::refresh::table', 'files-table')
      })
    },
    getCurrentRouter() {
      return utils.currentRouterId();
    },
    okHandler() {
      // this.$store.commit("current/setHintsDevices", false)
      localStorage.setItem('disable-devices-hint', "true")
    }

  },
}
</script>

<style>
/*. {*/
/*  font-family: "Merienda", Helvetica, Arial;*/
/*  font-size: 14px;*/
/*  color: #17a2b8;*/
/*}*/

/*.smt2 {*/
/*  font-family: "Merienda", Helvetica, Arial;*/
/*  font-size: 14px;*/
/*  color: dimgray;*/
/*}*/

</style>