<template>
<div>
  <b-sidebar width="1200px" id="charts-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">CHARTS</b-badge><br><br>
      <b-card>

        <b-row class="mb-2 small-cyan">
          <b-col sm="3">
          </b-col>
          <b-col sm="3">
            <b>Selected ship:</b>
          </b-col>
          <b-col class="text-sm-right">
             {{ $store.state.transactions.selected_ship.name }}
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-left">
            <b-button size="sm" variant="info" @click="placeRequest()">Place request</b-button>
            <br><br>
            <b>Items:</b>
            <ul class="listsmall">
              <li>RUA10002</li>
              <li>RUA10012</li>
            </ul>
          </b-col>
          <b-col sm="9" class="listsmall">
            <my-map :map=map :show-route=false :height="mapHeight"></my-map>
          </b-col>
        </b-row>

      </b-card>
    </div>
  </b-sidebar>
</div>
</template>

<script>

import ChartsMap from "@/views/charts/ChartsMap.vue";
import {ChartsLeaf} from "@/my-leaf/charts-leaf";

export default {
  name: "Charts",
  components: {MyMap: ChartsMap},
  data() {
    return {
      map: new ChartsLeaf(true),
      mapHeight: window.innerHeight / 1.5,
    }
  },
  methods: {
    placeRequest() {
      this.$root.$emit('bv::toggle::collapse', 'charts-sidebar');
      this.$store.dispatch("proto/updateProto1")
    }
  }
}
</script>

<style scoped>

</style>