<template>
  <b-sidebar width="700px" id="status-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">STATUS</b-badge><br><br>
      <b-card v-if="data">
        <b-row class="mb-2" v-if="is_agreement_owner">
          <b-col sm="3" class="text-sm-right"><b>Status:</b></b-col>
          <b-col class="listsmall">
            <b-button v-if="data.order_status === 'Complete'" size="sm" disabled variant="secondary" id="dropdown-2" :text=data.order_status>
              {{data.order_status}}
            </b-button>
            <b-dropdown split v-if="data.order_status !== 'Complete'" size="sm" :variant="data.order_status === 'new' ? 'warning' : 'warning'" id="dropdown-2" :text=data.order_status>
              <b-dropdown-item v-if="data.order_status === 'Request'" @click="updateStatus(1)">Change to Order</b-dropdown-item>
              <b-dropdown-item @click="updateStatus(2)">To pay</b-dropdown-item>
              <b-dropdown-item @click="editOrder()">Edit</b-dropdown-item>
              <b-dropdown-item @click="cancelOrder()">Cancel</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <br>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Order:</b> </b-col>
          <b-col class="listsmall text-sm-right">{{ data.order_date }}</b-col>
        </b-row>
        <hr>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Order Name:</b></b-col>
          <b-col class="listsmall">{{ data.order_number }}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Vessel name:</b> </b-col>
          <b-col class="listsmall">{{ data.ecdis_name }}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>IMO number:</b> </b-col>
          <b-col class="listsmall"></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>License group:</b> </b-col>
          <b-col class="listsmall"></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Total order value:</b> </b-col>
          <b-col class="listsmall">${{ data.total_price }}</b-col>
        </b-row>
        <br>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Chart Database:</b> </b-col>
          <b-col class="listsmall"></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Charts in order:</b> </b-col>
          <b-col class="listsmall">
            Overview: {{ data.bands[0] }}<br>
            General: {{ data.bands[1] }}<br>
            Coastal: {{ data.bands[2] }}<br>
            Approach: {{ data.bands[3] }}<br>
            Harbour: {{ data.bands[4] }}<br>
            Berthing: {{ data.bands[5] }}
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Licensing period:</b> </b-col>
          <b-col class="listsmall">{{ data.period }}</b-col>
        </b-row>
        <br>

        <!--        <b-row class="mb-2">-->
        <!--          <b-col sm="3" class="text-sm-right"><b>Charts:</b> </b-col>-->
        <!--          <b-col class="listsmall">-->
        <!--            <b-table striped :items="data.products[0].items":fields="status_items_fields"></b-table>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Subtotal value:</b> </b-col>
          <b-col class="listsmall"></b-col>
        </b-row>
        <hr>
        <b-row class="mb-2">
          <b-col sm="12" class="text-sm-left"><b>Graphical view of ordered charts:</b> </b-col>
        </b-row>
        <!--        <b-row class="mb-2" v-if="is_agreement_owner && (data.status === 'new' || data.status === 'order')">-->
        <!--          <b-col sm="3" class="text-sm-right"><b>Notification:</b></b-col>-->
        <!--          <b-col>-->
        <!--            <iframe id="fred" title="Change status message" :src="source1" width="100%" height="600px" ></iframe>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import {formatDate} from "@/utils/utils";

export default {
  name: "Status",
  methods: {
    formatDate,
    updateStatus(val) {

      console.log(val)
      if (val === 1)
        this.$store.commit('proto/setA1')
      if (val === 2)
        this.$store.commit('proto/setA2')

      // this.$store.dispatch("proto/updateProto2")
      this.$root.$emit('bv::toggle::collapse', 'status-sidebar');
      this.$refs.orderstable1.refresh();
    },
    editOrder() {
      // this.$store.dispatch("proto/updateProto2")
      this.$root.$emit('bv::toggle::collapse', 'items-edit-sidebar');
      this.$root.$emit('bv::toggle::collapse', 'status-sidebar');
    },
    cancelOrder() {
      // this.$store.dispatch("proto/updateProto2")
      this.$root.$emit('bv::toggle::collapse', 'status-sidebar');
    },
  },
  props: ["data"],
  computed: {
    is_agreement_owner() {
      return this.$store.state.auth.user.displayId === 74350 //TODO
    },
    // status_items_fields() {
    //   return [
    //     {key: 'id', label: 'Chart', tdClass: 'small-black', thClass: 'small-gray'},
    //     {key: 'duration', label: 'Period', tdClass: 'small-black', thClass: 'small-gray'},
    //   ]
    // },
  }
}
</script>

<style scoped>

</style>