<template>
  <div>

    <div class="alert alert-warning" v-if="isUserHasInstallations === -1">You haven't any installations. Please use the same credentials to login in an app first. </div>
    <div class="alert alert-warning" v-if="isUserHasInstallations === 1 && !selected.device_id">Please, select the device first!</div>

    <b-container v-if="selected.device_id">
      <b-row>
        <b-col>
          <b-card class="mt-2 small-black" bg-variant="light" text-variant="black" header="POSITION">
            <div v-if="selected.pos_lost">
              <b-icon icon="exclamation-circle" animation="throb" variant="danger"></b-icon>
              <span class="small-gray-card"> POSITION LOST</span><br></div>

            <div v-if="selected.ship_sensore"><span class="small-gray-card">Sensore:</span> {{ selected.ship_sensore
              }}<br></div>
            <div v-if="!selected.pos_lost">

              <div v-if="selected.pos_lat"><span class="small-gray-card">Position:</span> {{ getCoordinates() }}<br>
              </div>
              <div v-if="selected.pos_datetime"><span class="small-gray-card">Time:</span> {{ selected.pos_datetime }}<br>
              </div>
              <div v-if="selected.pos_sog"><span class="small-gray-card">SOG:</span> {{ selected.pos_sog }}<br></div>
              <div v-if="selected.pos_cog"><span class="small-gray-card">COG:</span> {{ selected.pos_cog }}<br></div>
              <div v-if="selected.pos_hdg"><span class="small-gray-card">HDG:</span> {{ selected.pos_hdg }}<br></div>
              <div v-if="selected.pos_rot"><span class="small-gray-card">ROT:</span> {{ selected.pos_rot }}<br></div>
            </div>
          </b-card>

          <b-card class="mt-2 small-black" bg-variant="light" text-variant="black" header="MAPS">
            <div v-if="selected.device_id && !selected.device_email">
              <b-icon icon="exclamation-circle" animation="throb" variant="warning"></b-icon>
              <span class="small-gray-card"> NOT REGISTRATED</span>
            </div>
            <div v-else>
              <div v-if="geocontent_list" v-for="(item, index) in geocontent_list_sorted()">
                <span :class="isActiveProduct(item) ? 'small-black' : 'small-gray-card' ">{{ item.title }}</span>
                <span v-if="isActiveProduct(item)"> (active)</span>
              </div>
              <hr>
<!--              <b-link href="/charts">To purchase...</b-link>-->
              <b-button v-if="!$store.state.current.user_type.is_mconnect" size="sm" href="/charts">To purchase...</b-button>
            </div>
          </b-card>

          <b-card class="mt-2 small-black" bg-variant="light" text-variant="black" header="SHIP">
            <div v-if="selected.app_active"><span class="small-gray-card">Active: </span>
              <font-awesome-icon class="smt4" v-if="selected.app_active" icon="fa-solid fa-check"/>
            </div>
            <div v-if="selected.ship_name"><span class="small-gray-card">Name:</span> {{ selected.ship_name }}<br></div>
            <div v-if="selected.ship_imo"><span class="small-gray-card">IMO:</span> {{ selected.ship_imo }}<br></div>
            <div v-if="selected.ship_mmsi"><span class="small-gray-card">MMSI:</span> {{ selected.ship_mmsi }}<br></div>
            <div v-if="selected.ship_length"><span class="small-gray-card">Dimensions:</span> L:{{ selected.ship_length
              }},
              W:{{ selected.ship_width }}, H:{{ selected.ship_height }} m<br></div>
            <div v-if="selected.ship_draught"><span class="small-gray-card">Draught:</span> {{ selected.ship_draught }}
              m<br></div>
            <div v-if="selected.app_route"><span class="small-gray-card">Route:</span> {{ selected.app_route }}<br>
            </div>
          </b-card>

          <b-card v-if="$store.state.current.profile.app_name === 'ECDIS' || $store.state.current.profile.app_name === 'm-Connect'" class="mt-2 small-black" bg-variant="light" text-variant="black" header="ECDIS">
            <div v-if="selected.ecids_permit"><span class="small-gray-card">Permit:</span> {{ selected.ecids_permit }}<br></div>

<!--            <div v-if="selected.ecids_backup_permit.length > 0">-->
<!--              <span class="small-gray-card">Backup permit:</span>-->
<!--              <div v-for="(item, index) in selected.ecids_backup_permit">-->
<!--              <span>{{item}}</span>-->
<!--                </div>-->
<!--            </div>-->

            <div v-if="selected.ecids_backup_permit.length > 0"><span class="small-gray-card">Backup permit:</span> {{ selected.ecids_backup_permit }}<br></div>
            <div v-if="selected.ecids_emails"><span class="small-gray-card">Emails:</span> {{ selected.ecids_emails }}<br></div>
            <div v-if="selected.ecids_ship_name"><span class="small-gray-card">Ship name:</span> {{ selected.ecids_ship_name }}<br></div>
            <div v-if="selected.ecids_ship_imo"><span class="small-gray-card">Ship IMO:</span> {{ selected.ecids_ship_imo }}<br></div>
            <div v-if="selected.ecids_ship_mmsi"><span class="small-gray-card">Ship MMSI:</span> {{ selected.ecids_ship_mmsi }}<br></div>
            <div v-if="selected.ecids_ship_callsign"><span class="small-gray-card">Ship Call Sign:</span> {{ selected.ecids_ship_callsign }}<br></div>
            <div v-if="selected.ecids_ship_category"><span class="small-gray-card">Ship category:</span> {{ selected.ecids_ship_category }}<br></div>
            <div v-if="selected.ecids_brand"><span class="small-gray-card">ECDIS brand:</span> {{ selected.ecids_brand }}<br></div>
            <div v-if="selected.ecids_os"><span class="small-gray-card">ECDIS OS:</span> {{ selected.ecids_os }}<br></div>
            <div v-if="selected.ecids_type"><span class="small-gray-card">ECDIS type:</span> {{ selected.ecids_type }}<br></div>
            <div v-if="selected.country"><span class="small-gray-card">Country:</span> {{ selected.country }}<br></div>
            <br>
            <b-button size="sm" @click="editEcdis">EDIT</b-button>

          </b-card>

          <b-card class="mt-2 small-black" bg-variant="light" text-variant="black" header="PROGRAM">
            <div v-if="selected.device_id"><span class="small-gray-card">Device ID:</span> {{ selected.device_id }}<br>
            </div>
            <div v-if="selected.user_id"><span class="small-gray-card">User ID:</span> {{ selected.user_id }}<br></div>
            <div v-if="selected.ecids_ship_pid"><span class="small-gray-card">PID:</span> {{ selected.ecids_ship_pid }}<br></div>
            <div v-if="selected.app_reqkey"><span class="small-gray-card">ReqKey:</span> {{ selected.app_reqkey }}<br>
            </div>
            <div v-if="selected.device_email"><span class="small-gray-card">Email:</span> {{ selected.device_email
              }}<br></div>
            <div v-if="selected.device_platform"><span class="small-gray-card">Platform:</span>
              {{ selected.device_platform }}<br></div>
            <div v-if="selected.app_name"><span class="small-gray-card">Name:</span> {{ selected.app_name }}<br></div>
            <div v-if="selected.app_ver"><span class="small-gray-card">Ver:</span> {{ selected.app_ver }}<br></div>
          </b-card>

          <b-card v-if="IS_NP()" class="mt-2 small-black" bg-variant="light" text-variant="black" header="DATA">
              <b-form-select
                  v-model="selectedFileType"
                  v-on:change="filesTypeSelected"
                  :options="filesTypes"
                  class="mb-0"
                  size="sm"
                  value-field="item"
                  text-field="name"
              ></b-form-select>
              <br><br>

              <all-files :files_list="filesList()"></all-files>
          </b-card>

        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import router from "@/router";
import AllFiles from "@/views/manage/files/AllFiles";
import {IS_NP} from "@/utils/build-config";
import {file} from "paths.macro";
import {getCatalog} from "@/requests/axios-requests";
import store from "@/store";

export default {
  components: {AllFiles},
  data() {
    return {
      selected: {
        device_id: "",
      },
      selected_item_id: -1,
      selectedFileType: 'empty',
      filesTypes: [
        {item: 'empty', name: 'Select...'},
        {item: 'poi', name: 'Bookmarks'},
        {item: 'logbook', name: 'Logbooks'},
        {item: 'navalcatalog', name: 'Naval Catalog'},
        {item: 'nmealog', name: 'NMEA Logs'},
        {item: 'profile', name: 'Profiles'},
        {item: 'routes', name: 'Routes'},
        {item: 'settings', name: 'Settings'},
        {item: 'shipslibrary', name: 'Ships Library'},
        {item: 'userlayers', name: 'User Layers'},
        // {item: 'statisticsdata', name: 'Statistics', notEnabled: false},
      ],
    }
  },
  props: ["geocontent_list", "map"],
  watch: {
    isUserHasInstallations: function(newval) {
      if (newval === -1) {
        // alert(newval)
        // TODO: GETCATALOG
        let catalog = JSON.parse("{\"catalog\":[{\"id\": \"yn2ZW0lNzE\",\"title\":\"Navicom\",\"publisher\":\"МИТ\",\"products\":[{\"id\": \"yn3WqdENzR\",\"contents\":[{\"type\":\"enc\"},{\"type\":\"weather\"}],\"licenseType\":\"com\",\"active\":false,\"price\":\"EURO 0,00\"}]},{\"id\": \"EmRbKAm2jv\",\"title\":\"PRIMAR Data (S63)\",\"publisher\":\"published by user\",\"products\":[{\"id\": \"Yz2XvzQbD6\",\"contents\":[{\"type\":\"enc\",\"url\":\"http://service.probki.net/mkart/geocontents/mkart_gc/enc/22876/enc_catalog.zip\"},{\"type\":\"weather\",\"url\":[{\"area\":\"NOAA\",\"content\":\"Wind\",\"uri\":\"http://service.probki.net/mkart/geocontents/mkart_gc/weather/noaa/uv/catalog.json\",\"description\":\"72 hours forecast\",\"aDate\":\"2023-05-19T00:00:00Z\"},{\"area\":\"NOAA\",\"content\":\"Air_Temp\",\"uri\":\"http://service.probki.net/mkart/geocontents/mkart_gc/weather/noaa/tm/catalog.json\",\"description\":\"72 hours forecast\",\"aDate\":\"2023-05-19T00:00:00Z\"}]}],\"licenseType\":\"corp\",\"license\":{\"deviceNum\":\"1 of 1\",\"isValid\":true},\"active\":true,\"price\":\"0\"}],\"productType\":\"custom\"}]}")
        store.commit("addGeoContent",{
          "geocontent": catalog,
          "aindex": 0,
          "deviceid": "",

        });

        store.commit("setSelectedGeocontent", catalog["catalog"])


        // getCatalog("0D19-7114-5C31-3C02", 0);
      }
    }
  },
  computed: {
    isUserHasInstallations() {
      let retVal = 0;

      if (!this.$store.state.manage.is_loading) {
        retVal = this.$store.state.manage.installations[0] && this.$store.state.manage.installations[0].length > 0 ? 1 : -1;
      }
      return retVal;
    },
  },
  methods: {
    IS_NP() {
      return IS_NP
    },
    editEcdis() {
      this.$store.commit('current/setEditECIDSFlag', true);
      this.$store.commit('current/setECDIS', {
        ecids_permit: this.selected.ecids_permit,
        ecids_backup_permit: this.selected.ecids_backup_permit,
        ecids_emails: this.selected.ecids_emails,
        ecids_ship_name: this.selected.ecids_ship_name,
        ecids_ship_imo: this.selected.ecids_ship_imo,
        ecids_ship_mmsi: this.selected.ecids_ship_mmsi,
        ecids_ship_callsign: this.selected.ecids_ship_callsign,
        ecids_ship_pid: this.selected.ecids_ship_pid,
        ecids_ship_category: this.selected.ecids_ship_category,
        ecids_brand: this.selected.ecids_brand,
        ecids_os: this.selected.ecids_os,
        country: this.selected.country
      });

      router.push({name: 'ecdis'});
    },
    geocontent_list_sorted() {
      let sorted_geocontent = [];

      if (!this.geocontent_list) {
        return sorted_geocontent;
      }

      // shift active product front
      this.geocontent_list.forEach(element => {
        if (element.products.at(0).id === this.$store.state.manage.appstate.programInfo.activeProductId)
          sorted_geocontent.unshift(element);
        else
          sorted_geocontent.push(element);
      });
      return sorted_geocontent;
    },
    isActiveProduct(item) {
      return this.$store.state.manage.appstate.programInfo.activeProductId === item.products.at(0).id;
    },
    products_selected(index, content, title) {
      if (title !== "PRIMAR Data (S63)" && this.$route.name !== 'manage') {
        let id = "";
        let url1 = "";
        let rec1 = "";

        console.log(content)
        content.forEach((rec) => {
          if (rec.type === "enc") {
            url1 = rec.url;
            id = rec.url.split('enc/').pop().split('/enc_catalog.zip')[0]
            rec1 = rec;
          }
        });

        this.$store.dispatch('getENCDetalis', id).then(() => {
          this.$root.$emit('bv::refresh::table', 'content-table');
        });
      }
    },
    filesList() {

      return this.$store.state.manage.files_list[this.selected_item_id];
    },
    filesTypeSelected(option) {
      console.log(file, "filesTypeSelected: " + option);
      this.$store.dispatch('changeFilesFilter', option).then(() => {
        this.$root.$emit('bv::refresh::table', 'files-table')
      })
    },
    getCoordinates() {
      return utils.trimCoordinates(this.selected.pos_lat) + ", " + utils.trimCoordinates(this.selected.pos_lon);
    },
  },
  created() {

    eventBus.$on('item_selected', data => {
      this.selected_item_id = data.aindex;
    });

    eventBus.$on('show_details', data => {

      this.$store.commit('current/setCurrentDraught', data.ship_draught);

      this.selected.user_id = data.user_id,
      this.selected.device_id = data.device_id;
      this.selected.device_platform = data.device_platform;
      this.selected.device_email = data.device_email;
      this.selected.device_license = data.device_license;

      this.selected.app_name = data.app_name;
      this.selected.app_ver = data.app_ver;
      this.selected.app_reqkey = data.app_reqkey;
      this.selected.app_route = data.app_route;
      this.selected.app_active = data.app_active;

      this.selected.ship_name = data.ship_name;
      this.selected.ship_mmsi = data.ship_mmsi;
      this.selected.ship_imo = data.ship_imo;
      this.selected.ship_length = data.ship_length;
      this.selected.ship_width = data.ship_width;
      this.selected.ship_height = data.ship_height;
      this.selected.ship_draught = data.ship_draught;
      this.selected.ship_sensore = data.ship_sensore;

      this.selected.pos_hdop = data.pos_hdop;
      this.selected.pos_lat = data.pos_lat;
      this.selected.pos_lon = data.pos_lon;
      this.selected.pos_cog = data.pos_cog;
      this.selected.pos_sog = data.pos_sog;
      this.selected.pos_hdg = data.pos_hdg;
      this.selected.pos_speed = data.pos_speed;
      this.selected.pos_mac = data.pos_mac;
      this.selected.pos_rot = data.pos_rot;
      this.selected.pos_datetime = data.pos_datetime;
      this.selected.pos_date = data.pos_date;
      this.selected.pos_time = data.pos_time;
      this.selected.pos_tick = data.pos_tick;
      this.selected.pos_lost = data.pos_lost;
      this.selected.pos_satellites = data.pos_satellites;

      this.selected.ecids_permit = data.ecids_permit;
      this.selected.ecids_backup_permit = data.ecids_backup_permit;
      this.selected.ecids_emails = data.ecids_emails;
      this.selected.ecids_ship_name = data.ecids_ship_name;
      this.selected.ecids_ship_imo = data.ecids_ship_imo;
      this.selected.ecids_ship_mmsi = data.ecids_ship_mmsi;
      this.selected.ecids_ship_callsign = data.ecids_ship_callsign;
      this.selected.ecids_ship_pid = data.ecids_ship_pid;
      this.selected.ecids_ship_category = data.ecids_ship_category;
      this.selected.ecids_brand = data.ecids_brand;
      this.selected.ecids_os = data.ecids_os;
      this.selected.ecids_type = data.ecids_type;
      this.selected.country = data.country;

      this.selected.products = data.device_products;

      this.appState = {
        lat: data.lat,
        lon: data.lon,
        cog: data.cog,
        id: data.id,
        days: data.days,
        aindex: data.aindex,
        name: data.name,
        route: data.route,
        sog: data.sog,
        email: data.email,
        isActive: data.isActive,
        routeJson: data.routeJson
      }
      console.log(this.appState)
      localStorage.setItem('selected', JSON.stringify(this.selected));

      this.$store.dispatch('setProfile', {
        app_name: this.selected.app_name,
        device_id: this.selected.device_id,
        user_id: this.selected.user_id,
        email: this.selected.device_email,
        device_os: this.selected.device_platform,
        appState: this.appState
      })
    })
  },
}
</script>

<style>
span {
  cursor: pointer;
}
</style>