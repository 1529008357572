<template>
  <div>
    <b-modal id="modal-payment-warning" title="Warning" v-model="openPaymentWarning" ok-only @ok="goTransactions">Agreement payment isn't realised yet. Select device without Agreement</b-modal>

    <payment ref="payment"></payment>

    <b-modal title="List of cells" v-model="openCellsList" ok-only @ok="primarMapBoosterByList">
      <b-form-textarea v-model="cellList" placeholder="Past comma or new line separated cells names..."></b-form-textarea>
    </b-modal>

    <div class="small-black" v-if="$store.state.proto.order.step !== ''">
      <b-row>
        <b-col>
          Order №
        </b-col>
        <b-col class="text-sm-right">
          <b>{{ order_with_date }}</b>
        </b-col>
      </b-row>
      <hr>
      <b-row v-if="!$store.state.proto.agreement.buzy">
        <b-col>
          My status:
        </b-col>
        <b-col class="text-sm-right">
          Customer
        </b-col>
      </b-row>

      <b-row v-if="$store.state.proto.agreement.buzy">
        <b-col>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>&nbsp;Loading...</strong>
          </div>
        </b-col>
      </b-row>


<!--      <b-row v-if="!$store.state.proto.agreement.buzy && hasValidAgreement">-->
<!--        <b-col>-->
<!--          Valid agreement:-->
<!--        </b-col>-->
<!--        <b-col class="text-sm-right">-->
<!--          Yes-->
<!--        </b-col>-->
<!--      </b-row>-->
      <b-row v-if="!$store.state.proto.agreement.buzy">
        <b-col>
          Payment method:
        </b-col>
        <b-col class="text-sm-right">
          {{ payment_system }}
        </b-col>
      </b-row>
      <hr>
      <b-row v-if="currentCart.length > 0 && !is_request" class="mt-2">
        <b-col>
          Total price:
        </b-col>
        <b-col class="text-sm-right">
          <div v-if="$store.state.current.cart.totalPriceByPrimar">
            <b>
              <span v-if="disabledCellsCount() !== currentCart.length">
              {{ $store.state.current.cart.totalPriceByPrimar }} €</span>
              <span v-else>
                0 €
              </span>
            </b>
          </div>
          <div v-else>
            calculating...
          </div>
        </b-col>
      </b-row>
      <b-row v-if="currentCart.length > 0 && !is_request" class="mt-2">
        <b-col>
          NET price:
        </b-col>
        <b-col class="text-sm-right">
          <div v-if="$store.state.current.cart.totalPriceByPrimarNet">
            <b>{{ net }} €</b>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="currentCart.length > 0 && !is_request" class="mt-2">
        <b-col>
          VAT:
        </b-col>
        <b-col class="text-sm-right">
          <div v-if="$store.state.current.cart.totalPriceByPrimarNet">
            <b>{{ vat }} €</b>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="currentCart.length > 0 && !is_request" class="mt-2">
        <b-col>
          Fee:
        </b-col>
        <b-col class="text-sm-right">
          <div v-if="$store.state.current.cart.totalPriceByPrimarNet">
            <b> {{ fee }} €</b>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="currentCart.length > 0 || $store.state.proto.order.step === 'placed'" class="mt-2">
        <b-col>
          Order status:
        </b-col>
        <b-col class="text-sm-right" v-if="payment_system === 'Agreement'">
          <b-dropdown block size="sm" variant="outline-info" :text="$store.state.proto.order.order_status">
            <b-dropdown-item @click="setStatus('Request')">Request</b-dropdown-item>
            <b-dropdown-item @click="setStatus('Order')">Order</b-dropdown-item>
            <b-dropdown-item @click="setStatus('To pay')">To pay</b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col class="text-sm-right" v-else>
          <b-dropdown block size="sm" variant="outline-info" text="To pay" disabled>
            <b-dropdown-item @click="setStatus('Request')">Request</b-dropdown-item>
            <b-dropdown-item @click="setStatus('Order')">Order</b-dropdown-item>
            <b-dropdown-item @click="setStatus('To pay')">To pay</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row v-if="currentCart.length > 0 && $store.state.proto.order.step !== 'placed'" class="mt-2">
        <b-col>
          ENC updating period:
        </b-col>
        <b-col class="text-sm-right">
          <b-dropdown block size="sm" variant="outline-info" :text="selectedPeriod.period + ' months'">
            <b-dropdown-item @click="setPeriod(3)">3 months</b-dropdown-item>
            <b-dropdown-item @click="setPeriod(6)">6 months</b-dropdown-item>
            <b-dropdown-item @click="setPeriod(12)">12 months</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-row v-if="$store.state.proto.order.step !== 'placed'" class="mt-2">
        <b-col class="text-sm-right">
          <b-button size="sm" variant="danger" @click="cancelOrder()">Cancel</b-button>&nbsp;
          <b-button v-if="currentCart.length > 0 && disabledCellsCount() !== currentCart.length" size="sm" variant="info" @click="placeOrder()" :disabled="!$store.state.current.cart.totalPriceByPrimar">Submit</b-button>
        </b-col>
      </b-row>
      <b-row v-if="$store.state.proto.order.step === 'placed'" class="mt-2">
        <b-col class="text-sm-right">
          <b-button size="sm" variant="danger" @click="backToEdit()">Back</b-button>&nbsp;
<!--          <b-button size="sm" variant="warning" @click="cancelOrder()">New</b-button>&nbsp;-->
          <b-button size="sm" variant="info" @click="sendOrder()">Ok</b-button>
        </b-col>
      </b-row>

      <br><br>
      <b-card v-if="$store.state.proto.order.step !== 'placed'" title="" sub-title="ENCs">
        <b-card-text>
          <div v-if="currentCart.length === 0">
            <a href="#" @click="showPrimarMapBoosterByList()">Click</a> to paste from clipboard, or import <a href="#" @click="">Excel</a> file
          </div>

          <div v-else>
            <div :key="index" v-for="(product, index) in currentCart.slice().reverse()">
              <b-row>
                <b-col cols="10">
                  <a class="small-cyan" href="#" @click="showCellOnMap(product.id, summary)">{{ product.id }}</a>&nbsp;
                  <span class="small-gray-card">{{ bandName(cellById(product.cells.at(0).id).usageBand) }}</span> <span v-if="!product.disabled">{{ getCellPrice(product.id) }}</span><br>
                  {{ product.name }}
                </b-col>
                <b-col cols="2">
                  <p class="h4 mb-2">
                    <a href="#" @click="removeCellFromOrder(product.id)">
                      <b-icon v-if="product.disabled" icon="toggle-off" variant="dark"></b-icon>
                      <b-icon v-else icon="toggle-on" variant="success"></b-icon>
                    </a>
                  </p>
                </b-col>
              </b-row>
            </div>
          </div>

        </b-card-text>
      </b-card>
    </div>

    <div v-if="$store.state.proto.order.step === 'placed'">
      <b-card>
        <b-card-text>
          <span class="small-gray-card">Order Name:</span> {{ order_with_date }}<br>
          <span class="small-gray-card">Vessel name:</span> {{ $store.state.proto.order.ecdis_name }}<br>
          <span class="small-gray-card">IMO number:</span> {{ $store.state.proto.order.ecdis_imo }}<br>
          <span class="small-gray-card">License group:</span><br>
          <span class="small-gray-card">Customer:</span> {{ $store.state.proto.order.customer }}<br>
          <div v-if="!is_request || payment_system !== 'Agreement'" >
            <span class="small-gray-card">Total order value: </span>{{ $store.state.proto.order.total_price }} €<br>
          </div>
          <br>
          <span class="small-gray-card">Chart Database:</span><br>
          <span class="small-gray-card">Charts in order:</span> {{ $store.state.proto.order.cells.length }}<br>
          <span class="small-gray-card">Overview:</span> {{ cellsBands[0] }}<br>
          <span class="small-gray-card">General:</span> {{ cellsBands[1] }}<br>
          <span class="small-gray-card">Coastal:</span> {{ cellsBands[2] }}<br>
          <span class="small-gray-card">Approach:</span> {{ cellsBands[3] }}<br>
          <span class="small-gray-card">Harbour:</span> {{ cellsBands[4] }}<br>
          <span class="small-gray-card">Berthing:</span> {{ cellsBands[5] }}<br>
          <span class="small-gray-card">Licensing period:</span> {{ $store.state.proto.order.period.period }}<br>
          <br>
          <div v-if="!is_request || payment_system !== 'Agreement'" >
            <span class="small-gray-card">Subtotal value: </span>{{ $store.state.proto.order.total_price }} €<br>
            <span class="small-gray-card">NET value: </span>
            {{ net_review }} €<br>
            <span class="small-gray-card">VAT: </span>
            {{ vat_review }} €<br>
            <span class="small-gray-card">Fee: </span>
            {{ fee_review }} €
          </div>
<!--          <span class="small-gray-card">Graphical view of ordered charts:</span><br>-->
        </b-card-text>
      </b-card>
      <br>
    </div>

  </div>
</template>

<script>
import {daysOldWithSign, formatDate, getAppType, getS63CKTInfo, getUUID} from "@/utils/utils";
import store from "@/store";
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import myAxios from "@/services/myAxios";
import router from "@/router";
import {file} from "paths.macro";
import Payment from "@/views/charts/Payment.vue";
import {CURRENT_PRODUCT} from "@/utils/build-config";
import {payWithStripe} from "@/helpers/pay";

export default {
  name: "Order",
  props: ['map', 'purchase', 'hideHistory', 'parentData'],
  components: {Payment},
  data() {
    return {
      openPaymentWarning: false,
      // selectedPeriod: CURRENT_PRODUCT.is_navicom ? "0" : "4",
      cellsBands: [0,0,0,0,0,0],
      cellList: "",
      openCellsList: false,
      stateButtons: [
        {caption: 'Request', state: true, variant: "light"},
        {caption: 'Order', state: false, variant: "light"},
        {caption: 'To pay', state: true, variant: "light"},
      ],
      selectedPeriod: {period_code: "4", period: "3"}
    }
  },
  watch: {
    currentCart() {
      this.countTotalPrice();
    },
    '$store.state.proto.agreement.exist': function() {
      if (this.$store.state.proto.agreement && !this.$store.state.proto.agreement.exist && !this.$store.state.proto.agreement.buzy) {
        this.setStatus('To pay')
      }

    },
  },
  mounted() {
    store.dispatch('proto/cancelOrder')

    eventBus.$on('ship-selected-from-chart', () => {
      let x = setTimeout(() => {
        this.purchase.isPrimarOrderCreating = true;
        this.map.makeChartsSelectable();
        // this.map.selectChartsByRoute();
      }, 1000)
    })
  },
  computed: {
    net() {
      // return Number(this.$store.state.current.cart.totalPriceByPrimar -  this.$store.state.current.cart.totalPriceByPrimarAdd).toFixed(2)
      return Number(this.$store.state.current.cart.totalPriceByPrimarNet).toFixed(2)
    },
    net_review() {
      return Number(this.$store.state.proto.order.total_priceNet).toFixed(2)
    },
    vat() {
      return Number((this.$store.state.current.cart.totalPriceByPrimarNet)/100*24).toFixed(2)
    },
    vat_review() {
      return Number((this.$store.state.proto.order.total_priceNet)/100*24).toFixed(2)
    },
    fee() {
      let net = Number(this.$store.state.current.cart.totalPriceByPrimarNet)
      let vat = net/100*24
      return Number((net + vat)/100*3).toFixed(2)
    },
    fee_review() {
      let net = Number(this.$store.state.proto.order.total_priceNet)
      let vat = net/100*24
      return Number((net + vat)/100*3).toFixed(2)
    },
    payment_system() {
      let val = "Stripe, $"
      if (!this.hasValidAgreement) {
        if (this.$store.state.current.webuser.location === 'RU') {
          val = "Yoomoney, ₽ or Stripe, €"
        }
      } else {
        val = "Agreement №" + this.$store.state.proto.order.agreement.id + " with " + this.$store.state.proto.order.agreement.distributorName + " from  " + formatDate(this.$store.state.proto.order.agreement.validTill).date + ")"

      }
      return val
    },
    is_request() {
      return this.$store.state.proto.order.order_status === 'Request'
    },
    order_with_date() {
      let date = this.$store.state.proto.order.order_date
      let order = this.$store.state.proto.order.order_number ? this.$store.state.proto.order.order_number : ""
      return order + "/" + date
    },
    currentCart() {
      // if (this.$store.state.proto.order.step === 'creating')
        return this.$store.state.current.cart.s63cells
      // if (this.$store.state.proto.order.step === 'editing')
      //   return this.$store.state.proto.order.cells
    },
    editingCart() {
      // if (this.$store.state.proto.order.step === 'creating')
      // return this.$store.state.current.cart.s63cells
      // if (this.$store.state.proto.order.step === 'editing')
      return this.$store.state.proto.order.cells
    },
    is_orders_loading() {
      return this.$store.state.current.loading.orders;
    },
    is_primar_loading() {
      return this.$store.state.current.loading.primarInfo;
    },
    is_drawing() {
      return this.$store.state.current.loading.drawing;
    },
    orders() {
      return this.$store.state.charts.s63.orders;
    },
    ordersList() {
      return this.$store.state.charts.s63.orders_list;
    },
    summary() {
      return this.$store.state.charts.s63.summary;
    },
    ecdis_summary() {
      return this.$store.state.charts.s63.ecdis_summary;
    },
    completedOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'complete';
      });

      return list;
    },
    errorOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'error';
      });

      return list;
    },
    processingOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status != 'new' && e.status != 'complete' && e.status != 'error';
      });

      return list;
    },
    unpaidOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'new';
      });

      return list;
    },
    periodsList() {
      return this.$store.state.current.cart.currentBandPeriods;
    },
    subTypesList() {
      let pList = this.periodsList;
      let myList = [];
      Object.keys(pList).forEach(x => {
        myList.push({value: x, text: pList[x]})
      })
      console.log(myList, "my list")
      return myList.reverse()
    },
    hasValidAgreement() {
      // return this.$store.state.current.agreement.id !== -1 && daysOldWithSign(this.$store.state.current.agreement.validdate) > 0;
      return this.$store.state.proto.agreement.exist && daysOldWithSign(this.$store.state.proto.agreement.validUntil) > 0;
    },

  },
  methods: {
    disabledCellsCount() {
        let count = 0;
        this.currentCart.slice().forEach(item => {
          if (item.disabled) {
            count++
          }
        })
        return count;
    },
    // showModal() {
    //   this.$refs['payment-modal'].show()
    // },
    // hideModal() {
    //   this.$refs['payment-modal'].hide()
    // },
    // payYoomoney() {
    //   this.$refs['payment-modal'].hide()
    //   this.paymentProcess('yoomoney')
    // },
    // payStripe() {
    //   this.$refs['payment-modal'].hide()
    //   this.paymentProcess('stripe')
    // },


    showPrimarMapBoosterByList() {
      this.openCellsList = true;
    },
    primarMapBoosterByList(type) {
      this.openCellsList = false;
      let cells = this.cellList.split(/[\n,]/);

      cells.forEach((item) => {
        this.map.onCellClicked(item.trim());
      });
      this.map.dispatchSelectedCells();

      this.primarOrderCreatingStart()
    },
    getPrice() {
      return this.$store.state.current.cart.s63cells.length + " charts for " + this.$store.state.current.cart.totalPriceByPrimarRUB + " ₽ (" + this.selectedPeriod.period + " months)"
    },
    setStatus(status) {
      this.$store.commit('proto/setOrderStatus', status)
    },
    setPeriod(period) {
      if (period === 12)
        this.selectedPeriod = {period_code: "0", period: "12"}
      if (period === 6)
        this.selectedPeriod = {period_code: "1", period: "6"}
      if (period === 3)
        this.selectedPeriod = {period_code: "4", period: "3"}

      this.countTotalPrice()
    },
    placeOrder() {

      let cellsList = this.$store.state.current.cart.s63cells.filter(function (e) {
        return e.disabled === false;
      });

      let chartsCatalog = this.$store.state.charts.s63.primar_map_info
      let objIndex, band
      this.cellsBands = [0,0,0,0,0,0]
      cellsList.forEach((item) => {
        objIndex = chartsCatalog.findIndex((obj => obj.id === item.id));
        band = chartsCatalog[objIndex].usageBand
        // let bandName = utils.getBandName(band)
        // console.log(band, bandName)

        this.cellsBands[band - 1] = this.cellsBands[band - 1] + 1

      });

      store.dispatch('proto/updateOrder',
          {
            cells: cellsList,
            period: this.selectedPeriod,
            total_price: this.$store.state.current.cart.totalPriceByPrimar,
            total_priceNet: this.$store.state.current.cart.totalPriceByPrimarNet,
            total_priceAdd: this.$store.state.current.cart.totalPriceByPrimarAdd,
            total_priceRUB: this.$store.state.current.cart.totalPriceByPrimarRUB,
            step: "placed",
            customer: this.$store.state.auth.user.email + ' (web)',
            product: 'Primar',
            payment: 'agreement №11/2023-06-23 ',
            bands: this.cellsBands
          })

      store.dispatch("current/addS63Cart", [])

      this.map.makeChartsUnselectable();
      this.map.clearRoute();
      // eventBus.$emit("s63-order-finished");

    },
    backToEdit() {
      eventBus.$emit("s63-order-finished");
      store.dispatch('proto/updateOrder',
          {
            step: "editing"
          })

      // store.dispatch("current/addS63Cart", this.$store.state.proto.order.cells)

      this.purchase.isPrimarOrderCreating = true;
      this.map.makeChartsSelectable();
      this.map.selectChartsByRoute();

      this.$store.state.proto.order.cells.forEach((item) => {
        this.map.onCellClicked(item.id);
      });
      this.map.dispatchSelectedCells();

    },
    goTransactions() {
      this.$store.dispatch('proto/addTransaction', this.$store.state.proto.order).then(() => {
        setTimeout(() => {
          this.cancelOrder()
          router.push({name: 'transactions'});
        }, 1000);
      });
    },
    sendOrder() {

      if (this.$store.state.proto.order.order_status === 'To pay' || this.payment_system !== 'Agreement') {

        if (this.hasValidAgreement) {
          this.openPaymentWarning = true;
        } else {
          this.$refs.payment.showModal()
        }
      }
    },
    cancelOrder() {

      console.log("Cancel Order")

      this.selectedPeriod = {period_code: "4", period: "3"}
      this.cellsBands = [0,0,0,0,0,0]
      this.$store.commit('proto/setOrderStatus', 'Request')

      store.dispatch('proto/cancelOrder')
      eventBus.$emit('clear-order-text-on-chart')


      // store.dispatch('proto/updateOrder',
      //     {
      //       step: "",
      //       order_status: "Request",
      //       order_number: null,
      //       order_date: null,
      //       ecdis_name: null,
      //       ecdis_imo: null,
      //       total_price: null,
      //       cells: [],
      //       period: null,
      //     })


      store.dispatch("current/addS63Cart", [])
      // store.commit("proto/setOrderNumber", null)
      this.map.makeChartsUnselectable();
      this.map.clearRoute();
      eventBus.$emit("s63-order-finished");

      // let x = setTimeout(() => {
      //   this.purchase.isPrimarOrderCreating = true;
      //   this.map.makeChartsSelectable();
      //   this.map.selectChartsByRoute();
      // }, 1000)

    },
    cellById(id) {
      return this.$store.getters.cellById(id)
    },
    countTotalPrice() {
      console.log("a2")
      // hide price while change period
      this.$store.commit("current/setTotalPriceByPrimar",
          {usd: '?', rub: '?', rubineuro: '?'}
      );

      let agreementID = this.hasValidAgreement ? this.$store.state.current.agreement.id : -1
      let deviceID = this.$store.state.current.profile.device_id
      let appType = getAppType(this.$store.state.current.profile.app_name)

      if (this.currentCart.length > 0) {
        let items = [];
        this.currentCart.forEach(x => {
          if (!x.disabled)
            this.$store.dispatch("current/getCellPrice", {cell: x, periodBand: this.selectedPeriod.period_code, agreementID: agreementID, deviceID: deviceID, appType: appType})
        })
      }

      console.log("-------------")
      console.log("-------------")
      console.log("-------------")
      console.log("-------------")

      console.log({products: this.currentCart, periodBand: this.selectedPeriod.period_code, agreementID: agreementID, deviceID: deviceID, appType: appType})

      this.$store.dispatch("current/getTotalPrice", {products: this.currentCart, periodBand: this.selectedPeriod.period_code, agreementID: agreementID, deviceID: deviceID, appType: appType});
    },
    removeCellFromOrder(id) {
      // eventBus.$emit('cell-clicked', id);
      store.dispatch('current/updateCellStaus', id);
      eventBus.$emit('disable-cell', id)
      this.map.onCellClicked(id);
      this.countTotalPrice()
    },
    bandName(code) {
      return utils.getBandName(code);
    },
    showCellOnMap(id, items) {

      eventBus.$emit('pane2cell', {id: id, items: items, color: 'yellow'})

      const url = 'https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}'

      // this.map.setSpecialTiles(url);
      eventBus.$emit('show-cell-on-map', url)
    },
    showTilesOnMap(items) {

      let id = this.$store.state.current.geocontent.s63_id;

      myAxios.get('https://win1hetz.bgeo.fi:5005/run?id=' + id + '&isIdFromGc=true').then(resp => {
        if (resp.status === 200) {
          const url = resp.data + "map/{z}/{x}/{y}";
          this.map.setSpecialTiles(url)
        }
        if (resp.status === 204) {
          console.log(" 204 error")
        }
      });
    },
    getCellPrice(id) {

      let cellPrice = '?'

      let cellArray = this.$store.state.current.cart.cellPrices
      let objIndex = cellArray.findIndex((obj => obj.id === id));
      if (objIndex !== -1)
        cellPrice = cellArray[objIndex].price + ' €'

      return cellPrice
    },
    cellExpiredDate(cell) {
      return utils.formatDate(cell.expiredAt);
    },
    isCellOutdated(date) {
      let days = utils.daysOldWithSign(date);
      return days <= 0;
    },
    isDetailsOpen(index) {
      if (index > 0) {
        return false;
      }

      return true;
    },
    summaryOpen() {
      // this.map.ChartsLayers.unavailable.addTo(this.map)
      eventBus.$emit('summary-open')
    },
    countPrice(cell) {
      // let k = this.selectedPeriod < 10 ? this.selectedPeriod / 10 : 1;
      // cell.price = Number((cell.pricePerYear * k).toFixed(2));

      let quotate = {
        items: [cell.id],
        subscriptionType: this.selectedPeriod.period_code
      };
      // alert(4)
      myAxios.post('/api/v1/orders/primar/quotate/v2', quotate).then(resp => {
        cell.price = Number(resp.data.userEuro).toFixed(2);
      })
    },
    async countPriceInit(cell) {
      // let k = selectedPeriod < 10 ? selectedPeriod / 10 : 1;
      // let price = Number((pricePerYear * k).toFixed(2));

      let quotate = {
        items: [cell.id],
        subscriptionType: this.selectedPeriod.period_code
      };
      let res
      // alert(5)
      myAxios.post('/api/v1/orders/primar/quotate/v2', quotate).then(resp => {
        // cell.price = price;
        res = Number(resp.data.userEuro).toFixed(2);
      })
      return res;
    },
    onOrderCreation() {
      this.$emit('purchase-clicked');
    },
    cellInfo(cellName) {
      // console.log(cellName)
      // console.log(getS63CKTInfo(cellName))
      return getS63CKTInfo(cellName);
    }
  },
}
</script>

<style scoped>

</style>