<template>
  <b-sidebar width="700px" id="price-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">PRICE</b-badge><br><br>
      <b-card v-if="data">
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Products:</b></b-col>
          <b-col class="listsmall">
            <b-table striped :items=data.products :fields="products_fields">
            </b-table>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Price:</b></b-col>
          <b-col class="listsmall">{{ data.total_price }}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Currency:</b></b-col>
          <b-col class="listsmall">USD</b-col>
        </b-row>
        <b-row class="mb-2" v-if="is_agreement_owner">
          <b-col sm="3" class="text-sm-right"><b>Distributor %:</b></b-col>
          <b-col class="listsmall">{{(data.total_price * 8.63 / 100).toFixed(2) }} (8.63%)</b-col>
        </b-row>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: "Price",
  props: ["data"],
  computed: {
    products_fields() {
      return [
        {key: 'provider', label: 'Provider', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'type', label: 'Type', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'price', label: 'End price', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'netPrice', label: 'Net price', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
    is_agreement_owner() {
      return this.$store.state.auth.user.displayId === 74350 //TODO
    }
  }
}
</script>

<style scoped>

</style>