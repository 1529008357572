<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card header="ORDERS">
          <orders></orders>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Orders from "@/views/transactions/Orders.vue";
export default {
  components: {Orders}
}
</script>

<style scoped>
</style>