import myAxios from "@/services/myAxios";
import {file} from "paths.macro";
import axios from "axios";
import store from '../store'

export function getAgreementData(deviceID, appType, userID, _this) {
    const url = "/api/v1/agreement/" + deviceID + "/" + appType + (userID ? "?userId=" + userID : "");
    console.log(file, "getAgreementData", url, arguments)

    myAxios.get(url, {
        headers: {
            "content-type": "application/json",
        }
    }).then((resp) => {
        _this.agreement.checking = false;
        if (resp.data.at(0).id) {
            _this.agreement.exist = true;
            _this.agreement.id = resp.data.at(0).id;
            _this.agreement.distributor = resp.data.at(0).distributorTitle;
            _this.agreement.validUntil = resp.data.at(0).validTill;
            _this.agreement.currency = resp.data.at(0).currency;

            store.commit('current/setAgreement', {id: resp.data.at(0).id, currency: resp.data.at(0).currency, validtill: resp.data.at(0).validTill});
        }
    }).catch((error) => {
        _this.agreement.exist = false;
    })
}

export function getCatalog(deviceID, aindex) {
    const url = "/api/v1/catalogue/" + deviceID;
    console.log(file, "getCatalog", url, deviceID)

    myAxios.get(url, {
        headers: {
            "content-type": "application/json",
        }
    }).then(resp => {
        if (resp.status == 200) {
            console.log(resp.data);

            // remove other products
            let filteredJSON = {}; filteredJSON["catalog"] = resp.data["catalog"].filter(function (e) {
                return e["title"] === "PRIMAR Data (S63)" || e["title"] === "Navicom";
            });

            store.commit("addGeoContent",{
                "geocontent": filteredJSON,
                "aindex": aindex,
                "deviceid": deviceID,
            });
        }
    });
}

export function getFilesList(deviceID, aindex) {
    const url = "/api/v1/userfiles?deviceId=" + deviceID;
    console.log(file, "getFilesList", url, deviceID)

    myAxios.get(url, {
        headers: {
            "content-type": "application/json",
        }
    }).then(resp => {
        console.log("------------- 21")
        if (resp.status == 200) {
            console.log(resp.data);

            store.commit("addFilesList",{
                "userFiles": resp.data.userFiles,
                "aindex": aindex,
                "deviceid": deviceID,
            });
        }
    });
}

export function postQuotate(payment_method, selectedPeriod, cart) {
    return new Promise(resolve => {
        let items = [];

        for (let i = 0; i < cart.length; i++) {
            const item = cart[i];
            const quotate = {items: [item.id], subscriptionType: selectedPeriod}

            myAxios.post('/api/v1/orders/primar/quotate/v2', quotate).then((resp) => {

                let priceByLocation = payment_method === 'yoomoney' ? resp.data.userRub : resp.data.userEuro;

                console.log("jjjjjjjj")
                console.log("jjjjjjjj")
                console.log("jjjjjjjj")
                console.log("jjjjjjjj")
                console.log(item.id)


                items.push({
                    id: item.id,
                    primarOrderTypeId: selectedPeriod,
                    monthCount: {0: 12, 1: 6, 4: 3}[selectedPeriod],
                    price: priceByLocation,
                    type: item.id.startsWith("102") ? "s102 maps" : "s63 maps"
                });

                console.log(items)

                console.log(items.length, items)
                if (items.length === cart.length) {
                    console.log(items, 'items');
                    resolve(items);
                }
            })
        }
    })
}
// export function getCellsByRoute(lat, lon, item) {
//     axios.get('https://nroutes.bgeo.fi:6003/mapsByPoint?lat=' + lat + '&lon=' + lon).then(resp => {
//         console.log(resp.data);
//
//         resp.data.forEach((item) => {
//             console.log(item.mapName)
//             ids.push(item.mapName)
//         });
// }