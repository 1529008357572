<template>
  <b-container fluid>
    <b-row>
      <b-col cols="5">
        <b-card header="AGREEMENTS">
          <agreements></agreements>
        </b-card>
<!--        <br>-->
<!--        <b-card header="SHIPS">-->
<!--          <users></users>-->
<!--        </b-card>-->
      </b-col>
      <b-col cols="7">
        <b-card header="ORDERS">
          <orders></orders>
        </b-card>
      </b-col>
<!--      <b-col cols="4">-->
<!--        <b-card header="DETAILS">-->
<!--          <order></order>-->
<!--        </b-card>-->
<!--      </b-col>-->
    </b-row>
<!--    <b-row>-->
<!--      <b-col cols="3">-->
<!--        <b-badge variant="light">SHIPS</b-badge>-->
<!--        <br><br>-->
<!--        <users></users>-->
<!--      </b-col>-->
<!--    </b-row>-->
  </b-container>
</template>

<script>
import Agreements from "@/views/distributor/Agreements.vue";
import Users from "@/views/distributor/Users.vue";
import Orders from "@/views/distributor/Orders.vue";
import Order from "@/views/distributor/Order.vue";

export default {
  components: {Agreements, Orders, Order, Users}
}
</script>

<style scoped>
</style>