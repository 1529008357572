<template>
  <div>
<!--    Price: {{ priceRU }}-->

    <!-- modal for add cell by copy/paste -->
    <b-modal title="List of cells" v-model="openCellsList" ok-only @ok="primarMapBoosterByList">
      <b-form-textarea v-model="cellList" placeholder="Enter comma or new line separated cells names..."></b-form-textarea>
    </b-modal>

    <!-- agreement sidebar -->
    <b-sidebar width="400px" id="agreement-sidebar" shadow>
      <div class="px-3 py-2">
        <b-badge variant="light">AGREEMENT</b-badge><br><br>
        <div v-if="agreement.exist">
          ID: {{ agreement.id }} <br>
          Valid until: {{ formatDateTime(agreement.validUntil) }}<br>
          Provider: {{ agreement.distributor }}<br><br>

          <b-button v-if="hasValidAgreement" class="mt-3" v-b-toggle.agreement-sidebar @click="primarOrderCreatingFinish('agreement')" variant="outline-info">
            <b-icon icon="clock-history"></b-icon> Purchase
          </b-button>
        </div>
        <div v-else>
          <div v-if="!IS_NP()">
            <div v-if="agreement.checking">
              <b-spinner small type="grow" variant="warning"></b-spinner><span class="small-gray"> checking agreement...</span>
            </div>
            <div v-else>
              <p class="my-4">No any agreement for the user. For any questions please write us to <a href="mailto:support@mkart.fi">support@mkart.fi</a></p>
            </div>
          </div>
          <div v-if="IS_NP()">
            <div v-if="agreement.checking">
              <b-spinner small type="grow" variant="warning"></b-spinner><span class="small-gray"> checking agreement...</span>
            </div>
            <div v-else>
              <p class="my-4">No any agreement for the user. For any questions please write us to <a href="mailto:support@njordpilot.com">support@njordpilot.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>


    <order :map=map :parentData="this.$data" :hide-history=false :purchase=purchase ref=mycart
    ></order>

    <!-- Latest order -->
<!--    <div v-if="!isCellsNotInitYet &&-->
<!--               !isOrdersLoading &&-->
<!--               !isPrimarLoading &&-->
<!--               $store.state.charts.s63.latest_order.isExist &&-->
<!--               $store.state.current.product.current === 'PRIMAR Data (S63)'">-->

<!--      <b-card style="min-width: 18rem;" class="mt-2 small-black" bg-variant="light" text-variant="black" header="LATEST ORDER">-->
<!--        <div class="small-gray">-->
<!--          Date: <span class="small-black">{{ formatDateTime($store.state.charts.s63.latest_order.date) }}</span><br>-->
<!--          ID: <span class="small-black">{{ $store.state.charts.s63.latest_order.id }}</span><br>-->
<!--          Paid: <span :class="$store.state.charts.s63.latest_order.isPaid ? 'smt11' : 'smt14'"> {{ $store.state.charts.s63.latest_order.isPaid ? "yes" : "no" }} </span><br>-->
<!--          Status: <span :class="getStatus($store.state.charts.s63.latest_order.status)"> {{ translateStatus() }} </span>-->
<!--          <b-spinner v-if="!['error', 'completed'].includes(translateStatus())" small type="grow" variant="warning"></b-spinner>-->
<!--        </div>-->
<!--      </b-card>-->

<!--    </div>-->

    <!-- New order button -->
    <div v-if="$store.state.proto.show && !isCellsNotInitYet &&
               !isOrdersLoading &&
               !isPrimarLoading &&
               !isDrawing">

      <div v-if="purchase.isPrimar && !purchase.isPrimarOrderCreating">

        <b-dropdown class="mt-3" variant="info" size="sm" v-if="!hide" v-show="!isPrimarLoading && !isDrawing">
          <template #button-content>
<!--            <b-icon icon="bag-plus" aria-hidden="true"></b-icon>-->New order
          </template>

          <b-dropdown-group header="Map booster" class="small">
            <b-dropdown-item-button @click="primarMapBoosterByPoint(1)">
              <b-icon icon="blank" aria-hidden="true"></b-icon>By ship location <span class="sr-only">(Not selected)</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="primarMapBoosterByPoint(2)">
              <b-icon icon="blank" aria-hidden="true"></b-icon>By map center <span class="sr-only">(Not selected)</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="primarMapBoosterByRoute()">
              <b-icon icon="blank" aria-hidden="true"></b-icon>By route <span class="sr-only">(Not selected)</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="showPrimarMapBoosterByList">
              <b-icon icon="blank" aria-hidden="true"></b-icon>By list of cells<span class="sr-only">(Not selected)</span>
            </b-dropdown-item-button>
          </b-dropdown-group>

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-group header="Manually" class="small">
            <b-dropdown-item-button @click="primarOrderCreatingStart">
              <b-icon icon="blank" aria-hidden="true"></b-icon>From map <span class="sr-only">(Not selected)</span>
            </b-dropdown-item-button>
          </b-dropdown-group>
        </b-dropdown>

      </div>
      <div class="small-black" v-if="purchase.isPrimar && purchase.isPrimarOrderCreating">
<!--        <span class="small-cyan">NEW ORDER:</span><br><br>-->

        <b-button-group v-if="debug" size="sm" class="mb-2">
          <b-button @click="primarOrderCreatingCancel" v-if="!hide" variant="danger"><b-icon icon="x-circle"></b-icon>
          </b-button>&nbsp;

          <div class="mt-1" v-if="price === '?'">&nbsp;
            <b-spinner small type="grow" variant="warning"></b-spinner><span class="small-gray"> calculating...</span>
          </div>

          <b-button v-if="price > 0 && !hide" @click="togglePayPressed();" :disabled="price === 0" variant="info">
            <b-icon icon="coin"></b-icon>
            <div v-if="hasValidAgreement">
              <span v-if="isRUBAgreement"> Pay {{ priceAgreementRUB }} ₽</span>
              <span v-else> Pay ${{ priceAgreementUSD }}</span>
            </div>
            <div v-else>
              <span v-if="isRULocation"> Pay {{ priceRU }} ₽</span>
              <span v-if="!isRULocation"> Pay {{ price }} €</span>
            </div>
          </b-button>&nbsp;
        </b-button-group>

<!--        <br>-->
        <div v-if="purchase.isPayPressed">
          <br>
          <b-button-group vertical size="sm">

            <b-button v-if="!hasValidAgreement && isRULocation && price > 0 && !IS_NP()" @click="primarOrderCreatingFinish('yoomoney')" variant="outline-info">
              <b-icon icon="credit-card"></b-icon> Yoomoney {{ priceRU }} ₽
            </b-button>&nbsp;

            <b-button v-if="!hasValidAgreement && price > 0  && !IS_NP()" @click="primarOrderCreatingFinish('stripe')" variant="outline-info">
              <b-icon icon="credit-card"></b-icon> Stipe {{ price }} €
            </b-button>&nbsp;

            <b-button v-if="price > 0 && !CURRENT_PRODUCT.is_navicom" @click="showAgreement()" variant="outline-info">
              <b-icon icon="file-text"></b-icon>
              <span v-if="isRUBAgreement"> Agreement <div v-if="hasValidAgreement">{{ priceAgreementRUB }} ₽</div></span>
              <span v-else> Agreement <div v-if="hasValidAgreement">${{ priceAgreementUSD }}</div></span>
            </b-button>&nbsp;

<!--            TODO trial for other admins-->
            <b-button v-if="price > 0 && isAdmin && (!IS_NP() || (IS_NP() && $store.state.current.profile.email.startsWith('joss.ru')))" @click="primarOrderCreatingFinish('trial')" variant="outline-info">
              <b-icon icon="clock-history"></b-icon> Trial
            </b-button>&nbsp;

            <b-alert :show="is_trial_purchasing_process" variant="warning">
              Please wait a few seconds...
              <b-spinner small type="grow"></b-spinner>
            </b-alert>

          </b-button-group>

        </div>

<!--        <div v-if="purchase.isPayPressed && $store.state.current.webuser.location != 'RU'">-->
<!--          <br>-->
<!--          <b-button-group vertical size="sm">-->
<!--            <b-button v-if="price > 0" @click="primarOrderCreatingFinish('stripe')"-->
<!--                      variant="outline-info">-->
<!--              <b-icon icon="credit-card"></b-icon>-->
<!--              Stipe {{ price }} €-->
<!--            </b-button>&nbsp;-->

<!--            <b-button v-if="price > 0" @click="showAgreement()"-->
<!--                      variant="outline-info">-->
<!--              <b-icon icon="file-text"></b-icon>-->
<!--              Agreement {{ price }} €-->
<!--            </b-button>&nbsp;-->

<!--            <b-button v-if="price > 0 && isAdmin" v-b-toggle.charts-sidebar-2-->
<!--                      variant="outline-info">-->
<!--              <b-icon icon="clock-history"></b-icon>-->
<!--              Trial-->
<!--            </b-button>&nbsp;-->

<!--            <b-alert :show="is_trial_purchasing_process" variant="warning">-->
<!--              Please wait a few seconds...-->
<!--              <b-spinner small type="grow"></b-spinner>-->
<!--            </b-alert>-->


<!--          </b-button-group>-->
<!--        </div>-->

        <code v-if="debug">
          Select/unselect cells by click or make a route to select the required cells
        </code>
<!--        <br><br>-->

        <b-alert v-if="debug && $store.state.charts.s63.items.length === 0" show>
          NO SELECTED CELLS
        </b-alert>

      </div>

      <div class="small-black" v-if="purchase.isPrimar && purchase.isPrimarOrderCreating && price === 0">
        <b-button size="sm" @click="primarOrderCreatingCancel" v-if="!hide" variant="outline-danger">
          Cancel
        </b-button>&nbsp;
        <br>
        <b-button size="sm" @click="toggleDrawRoute" v-if="!hide" variant="outline-info">
          {{ drawing ? "Return tu manual selecting" : "Draw Route" }}
        </b-button>&nbsp;

        <code>
          Select/deselect cells by click or make a route to select the required cells
        </code>
        <br><br>
      </div>




      <div v-if="!purchase.isPrimar">

        <br>
<!--        <div v-if="purchase.isPayPressed && $store.state.current.webuser.location === 'RU'">-->
<!--          <br>-->
<!--          <b-button-group size="sm">-->
<!--            <b-button pill size="sm" @click="bueProductPack()"-->
<!--                      variant="outline-success">-->
<!--              <font-awesome-icon icon="fa-solid fa-credit-card"/>-->
<!--              Yoomoney-->
<!--            </b-button>&nbsp;-->

<!--          </b-button-group>-->
        </div>

<!--        <div v-if="purchase.isPayPressed && $store.state.current.webuser.location != 'RU'">-->
<!--          <br>-->
<!--          <b-button-group size="sm">-->
<!--            <b-button pill size="sm" @click="bueProductPack()"-->
<!--                      variant="outline-success">-->
<!--              <font-awesome-icon icon="fa-solid fa-credit-card"/>-->
<!--              Stipe-->
<!--            </b-button>&nbsp;-->
<!--          </b-button-group>-->
<!--        </div>-->
<!--      </div>-->


      1212
      <order :map=map :parentData="this.$data" :hide-history=false :purchase=purchase ref=mycart
             ></order>


<!--      <div v-show='!routeProcessing && !isCellsNotInitYet'>-->
<!--&lt;!&ndash;        <S63 :map=map :parentData="this.$data" :hide-history=false :purchase=purchase ref=mycart&ndash;&gt;-->
<!--&lt;!&ndash;             v-show=purchase.isPrimar></S63>        &ndash;&gt;-->
<!--        <order :map=map :parentData="this.$data" :hide-history=false :purchase=purchase ref=mycart-->
<!--             v-show=purchase.isPrimar></order>-->
<!--      </div>-->
      <div v-show=routeProcessing>
        <b-spinner small type="grow" variant="warning"></b-spinner>
        <span class="small-gray">  Processing route...</span>
      </div>
    </div>

<!--    <S63 :map=map :parentData="this.$data" :hide-history=false :purchase=purchase ref=mycart-->
<!--         v-show=purchase.isPrimar></S63>-->

  </div>
</template>

<script>
import {eventBus} from "@/main";
import {clearJSONValues, daysOldWithSign, getAppType, getUUID} from "@/utils/utils";
import S63 from "@/views/charts/S63";
import Order from "@/views/charts/Order.vue"
import myAxios from "@/services/myAxios";
import {payWithStripe} from "@/helpers/pay";
import {file} from "paths.macro";
import * as utils from "@/utils/utils";
import axios from "axios";
import {CURRENT_PRODUCT, IS_NP} from "@/utils/build-config";
import {getAgreementData} from "@/requests/axios-requests";

export default {
  props: ['map'],
  components: {Order},
  // components: {S63, Order},
  data() {
    return {
      debug: false,
      cellList: "",
      openCellsList: false,
      isCellsNotInitYet: true,

      counter: 0,
      drawing: false,
      hide: true,
      routeProcessing: false,
      purchase: {
        isPrimar: false,
        isPrimarOrderCreating: false,
        isPayPressed: false,
      },
      timer: '',
      mapCenter: '',
      routePoints: [],
      is_trial_purchasing_process: false,
      agreement: {
        exist: false,
        checking: false,
        id: -1,
        distributor: '',
        validUntil: '',
        currency: ''
      }
    }
  },
  mounted() {


    // let x = setTimeout(() => {
    //   this.purchase.isPrimarOrderCreating = true;
    //   this.map.makeChartsSelectable();
    //   this.map.selectChartsByRoute();
    // }, 1000)



    if (this.$store.state.manage.appstate) {
      this.handleGetAgreementForDevice();
      this.autoUpdate();
      this.timer = setInterval(this.autoUpdate, 4000);
    }

  },
  computed: {
    CURRENT_PRODUCT() {
      return CURRENT_PRODUCT
    },
    isAdmin() {
      return this.$store.state.auth.user.role === 'admin';
    },
    isRULocation() {
      return this.$store.state.current.webuser.location === 'RU';
    },
    hasValidAgreement() {
      return this.$store.state.current.agreement.id !== -1 && daysOldWithSign(this.$store.state.current.agreement.validdate) > 0;
    },
    isRUBAgreement() {
      return this.$store.state.current.agreement.currency === 'RUB';
    },
    isOrdersLoading() {
      return this.$store.state.current.loading.orders;
    },
    isPrimarLoading() {
      return this.$store.state.current.loading.primarInfo;
    },
    isDrawing() {
      return this.$store.state.current.loading.drawing;
    },
    // charts() {
    //   return this.$store.state.charts.s63.items;
    // },
    price() {
      return this.$store.state.current.cart.totalPriceByPrimar;
    },
    priceRU() {
      return this.$store.state.current.cart.totalPriceByPrimarRUB;
    },
    priceAgreementUSD() {
      return this.$store.state.current.cart.totalPriceByPrimarAgreementUSD;
    },
    priceAgreementRUB() {
      return this.$store.state.current.cart.totalPriceByPrimarAgreementRUB;
    },
    // period() {
    //   return this.$refs.mycart.selectedPeriod;
    // },
  },
  methods: {
    daysOldWithSign,
    IS_NP() {
      return IS_NP
    },
    autoUpdate() {
      if (this.$route.name !== 'charts'
          || !this.purchase.isPrimar
          || this.$store.state.charts.s63.latest_order.status === ''
          || this.$store.state.charts.s63.latest_order.status === 'complete'
          || this.$store.state.charts.s63.latest_order.status === 'error'
          || (this.$store.state.charts.s63.latest_order.status === 'new' &&
              this.$store.state.charts.s63.latest_order.method !== 'agreement')) {
        console.log(file, "autoUpdate canceled")
        clearInterval(this.timer);
      } else {
        console.log(file, " **** autoUpdate latest order")

        this.$store.dispatch('getS63OrdersList', {
          device_id: this.$store.state.current.profile.device_id,
          user_id: this.$store.state.current.profile.user_id
        }).then(() => {
        });

        this.$store.dispatch('getS63Summary', {
          device_id: this.$store.state.current.profile.device_id,
          user_id: this.$store.state.current.profile.user_id
        }).then(() => {

        });

        this.$store.dispatch('getLatestOrder', {
          order_id: this.$store.state.charts.s63.latest_order.id,
          user_id: this.$store.state.current.profile.user_id
        }).then(() => {
          console.log(" getLatestOrder finished")

          let newStatus = this.$store.state.charts.s63.latest_order.status;
          let oldStatus = this.$store.state.charts.s63.orders_list.at(this.$store.state.charts.s63.orders_list.length - 1).status;

          if (newStatus === 'complete' && oldStatus != 'complete') {
            window.location.reload()
          }

        });

      }
    },

    showAgreement() {
      this.$root.$emit('bv::toggle::collapse', 'agreement-sidebar');
    },

    handleGetAgreementForDevice() {
      let deviceID = this.$store.state.current.profile.device_id;
      let userID = this.$store.state.current.profile.user_id;
      let appName = this.$store.state.current.profile.app_name;
      let appType = getAppType(appName);

      // get appType
      // if (appName === 'ECDIS')
      //   appType = 11;
      // else if (appName === 'm-Connect')
      //   appType = 10;
      // else if (appName === 'Vega')
      //   appType = 2;
      // else if (appName === 'Njord Pilot')
      //   appType = 1;
      // else if (appName === 'mKart')
      //   appType = 0;

      this.agreement.checking = true;
      getAgreementData(deviceID, appType, userID, this);
    },


    getStatus(value) {
      let status = "";

      if (value === 'new') {
        status = 'smt11'
      }

      if (value === 'processing') {
        status = 'smt12'
      }

      if (value === 'error') {
        status = 'smt14'
      }

      if (value === 'complete') {
        status = 'smt11'
      }

      return status;
    },
    translateStatus() {
      let status = this.$store.state.charts.s63.latest_order.status;
      let newStatus = "";
      switch (String(status)) {
        case "new":
          newStatus = "new";
          break;
        case "payment_processing":
          newStatus = "payment processing";
          break;
        case "paid":
          newStatus = "paid";
          break;
        case "ready_to_publish":
          newStatus = "maps conversion";
          break;
        case "complete":
          newStatus = "completed";
          break;
        case "processing":
          newStatus = "processing [1]";
          break;
        case "awaiting_charts":
          newStatus = "maps publishing";
          break;
        case "adding_charts":
          newStatus = "processing [2]";
          break;
        case "error":
          newStatus = "error";
          break;
      }

      return newStatus;
    },
    formatDateTime(date) {
      return utils.formatDate(date).datetime;
    },
    toggleDrawRoute() {
      this.map.pMeasure._toggleMeasure();
    },

    primarMapBoosterByRoute() {
      const len = this.$store.state.routes.route.length
      if (len === 0) {
        alert('Make a route in Routes tab first')
        return;
      }
      const get_ids_set = () => {
        return new Promise(resolve => {
          let count = 0;
          let ids = []
          this.$store.state.routes.route.forEach((items) => {
            console.log(items)
            let lat = items.lat;
            let lon = items.lon;
            axios.get('https://nroutes.bgeo.fi:6003/mapsByPoint?lat=' + lat + '&lon=' + lon).then(resp => {
              console.log(resp.data);

              resp.data.forEach((item) => {
                console.log(item.mapName)
                ids.push(item.mapName)
              });
              count++;
              if (count >= len) {
                resolve(new Set(ids))
              }
            });
          })
        })
      }
      get_ids_set().then(mapNames => {
        console.log(mapNames)
        mapNames.forEach(name => {
          this.map.onCellClicked(name)
        })
        this.map.dispatchSelectedCells();
        this.purchase.isPrimarOrderCreating = true;
        this.map.makeChartsSelectable();
      })
    },
    showPrimarMapBoosterByList() {
      this.openCellsList = true;
    },

    primarMapBoosterByList(type) {
      this.openCellsList = false;
      let cells = this.cellList.split(/[\n,]/);

      cells.forEach((item) => {
        this.map.onCellClicked(item.trim());
      });
      this.map.dispatchSelectedCells();

      this.primarOrderCreatingStart()
    },

    primarMapBoosterByPoint(type) {

      console.log("MapBooster type: " + type)
      console.log("MapBooster center: " + this.mapCenter)

      let lat = 0;
      let lon = 0;

      if (type === 1) {
        lat = this.$store.state.current.profile.appState.lat;
        lon = this.$store.state.current.profile.appState.lon;
      }

      if (type === 2) {

        lat = this.$store.state.leaflet.map.lat;
        lon = this.$store.state.leaflet.map.lon;


      }


      console.log("MapBooster: " + lat + " " + lon)

      axios.get('https://nroutes.bgeo.fi:6003/mapsByPoint?lat=' + lat + '&lon=' + lon).then(resp => {
        console.log(resp.data);

        resp.data.forEach((item) => {
          console.log(item.mapName)

          this.map.onCellClicked(item.mapName);
        });
        this.map.dispatchSelectedCells();

      });


      //

      this.primarOrderCreatingStart()
    },

    primarOrderCreatingStart() {
      this.purchase.isPrimarOrderCreating = true;
      this.map.makeChartsSelectable();
      this.map.selectChartsByRoute();
//      this.map.setDefaultTiles();
    },
    togglePayPressed() {
      if (this.$store.state.manage.is_user_has_installation !== false)
        this.purchase.isPayPressed = !this.purchase.isPayPressed;
    },
    primarOrderCreatingFinish(payment_method) {
      console.log('pay: start', file)

      const cart = this.$store.state.current.cart.s63cells
      const selectedPeriod = this.$refs.mycart.selectedPeriod
      if (cart.length > 0) {

        if (payment_method !== 'trial' && payment_method !== 'agreement')
          this.$router.push({name: 'loader'});
        else
          this.is_trial_purchasing_process = true;

        const uuidHash = getUUID(this.$store.state.current.profile.email);

        let agreementID = this.hasValidAgreement ? this.$store.state.current.agreement.id : -1
        let deviceID = this.$store.state.current.profile.device_id
        let appType = getAppType(this.$store.state.current.profile.app_name)
        this.$store.dispatch("current/getTotalPrice", {products: cart, periodBand: selectedPeriod, agreementID: agreementID, deviceID: deviceID, appType: appType})
            .then(async () => {
              let totalPrice = payment_method === 'stripe' ? this.$store.state.current.cart.totalPriceByPrimar.toString() : this.$store.state.current.cart.totalPriceByPrimarRUB.toString()
              if (this.hasValidAgreement) {
                totalPrice = this.isRUBAgreement ? this.$store.state.current.cart.totalPriceByPrimarAgreementRUB.toString() : this.$store.state.current.cart.totalPriceByPrimarAgreementUSD.toString();
              }


              const getItems = () => {
                return new Promise(resolve => {
                  let items = [];

                  for (let i = 0; i < cart.length; i++) {
                    const item = cart[i];
                    const quotate = {items: [item.id],
                      subscriptionType: selectedPeriod,
                      agreementId: agreementID,
                      deviceId: deviceID,
                      applicationTypeId: appType
                    }

                    if (CURRENT_PRODUCT.is_navicom) {

                      let priceByLocation = payment_method === 'yoomoney' ? "350" : "4";
                      console.log(item.id)

                      items.push({
                        id: item.id,
                        primarOrderTypeId: selectedPeriod,
                        monthCount: {0: 12, 1: 6, 4: 3}[selectedPeriod],
                        price: priceByLocation,
                        type: "navicom maps"
                      });

                      console.log(items)

                      console.log(items.length, items)
                      if (items.length === cart.length) {
                        console.log(items, 'items');
                        resolve(items);
                      }




                    } else {
                      myAxios.post('/api/v1/orders/primar/quotate/v2', quotate).then((resp) => {
                        let priceByLocation = payment_method === 'yoomoney' ? resp.data.userRub : resp.data.userEuro;
                        if (this.hasValidAgreement) {
                          priceByLocation = this.isRUBAgreement ? resp.data.agreementRub : resp.data.agreementUsd;
                        }

                        console.log("hhhhhhhhhhh")
                        console.log("hhhhhhhhhhh")
                        console.log("hhhhhhhhhhh")
                        console.log("hhhhhhhhhhh")
                        console.log(priceByLocation)
                        console.log(this.hasValidAgreement)
                        console.log(this.isRUBAgreement)
                        console.log(resp.data.agreementRub)
                        console.log(resp.data.agreementUsd)


                        console.log(item.id)

                        items.push({
                          id: item.id,
                          primarOrderTypeId: selectedPeriod,
                          monthCount: {0: 12, 1: 6, 4: 3}[selectedPeriod],
                          price: priceByLocation,
                          type: item.id.startsWith("102") ? "s102 maps" : "s63 maps"
                        });

                        console.log(items)

                        console.log(items.length, items)
                        if (items.length === cart.length) {
                          console.log(items, 'items');
                          resolve(items);
                        }
                      })
                    }
                  }
                })
              }
              const userId = this.$store.state.current.profile.user_id;

              const getOrder = async (items) => {

                let order = {};

                order.deviceId = this.$store.state.current.profile.device_id;
                order.price = totalPrice;
                order.items = items;
                order.comment = uuidHash + " | " + this.$store.state.current.profile.email;
                order.paymentMethod = payment_method;
                order.agreementId = this.$store.state.current.agreement.id;
                order.applicationTypeId = appType

                return order
              }
              getItems().then(items => {
                getOrder(items).then(order => {
                  console.log('await order', order);
                  let purchaseUrl;
                  if (userId) {
                    purchaseUrl = '/api/v1/purchase/todeviceid';
                    order.userId = userId;
                  } else {
                    purchaseUrl = '/api/v1/purchase/charts/todevice';
                  }
                  console.log('pUrl', purchaseUrl);
                  const a = order.items
                  console.log(a)
                  if (order.items[0]) {
                    myAxios.post(purchaseUrl, order,
                        {
                          headers: {
                            'content-type': 'application/json',
                          }
                        }
                    ).then((resp) => {
                      payWithStripe(resp.data, payment_method);
                    })
                        .catch((error) => {
                          alert('something went wrong [2]', file)
                        })
                        .finally(() => {
                          this.is_trial_purchasing_process = false;
                          console.log('pay: end', file)
                        })
                  }
                })
              })
            })

        // })
      } else this.primarOrderCreatingCancel();

    },
    primarOrderCreatingCancel() {
      this.purchase.isPrimarOrderCreating = false;

      this.map.makeChartsUnselectable();
      this.map.clearRoute();
      eventBus.$emit("s63-order-finished");
    },
  },
  created() {

    eventBus.$on("map-center-moved", data => {
      this.mapCenter = data;
      console.log(data)
      console.log(data.lat)
      console.log(data.lng)
    });

    eventBus.$on("charts-tab-exit", data => {
      this.purchase.isPrimarOrderCreating = false;

      this.map.makeChartsUnselectable();
      this.map.clearRoute();
      eventBus.$emit("s63-order-finished");

    });

    //TODO: РАЗОБРАТЬСЯ
    eventBus.$on('go-spinny', (status => {
      this.routeProcessing = status;
      console.log('aaa ', status)
      console.log(this.routeProcessing)
    }))
    eventBus.$on('purchasing_update', data => {
      this.hide = data.hide;
      this.purchase.price = data.price;
      this.purchase.isPrimar = data.primar;
    });
    eventBus.$on('draw-control-toggle', (status) => {
      this.drawing = status;
    })
    eventBus.$on('loading-data', status => {
      this.isCellsNotInitYet = status
    })
  },

}
</script>

<style scoped>

</style>