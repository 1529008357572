<template>
  <b-sidebar width="700px" id="ship-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">SHIP</b-badge><br><br>
      <b-card v-if="data">
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Ship name:</b></b-col>
          <b-col class="listsmall">{{data.ecdis_name}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Device ID:</b></b-col>
          <b-col class="listsmall"></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Type:</b></b-col>
          <b-col class="listsmall">ECDIS</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Permit:</b></b-col>
          <b-col class="listsmall"></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Owner:</b></b-col>
          <b-col class="listsmall">{{data.customer}}</b-col>
        </b-row>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import {getAppName} from "@/utils/utils";

export default {
  name: "Ship",
  methods: {getAppName},
  props: ["data"],
}
</script>

<style scoped>

</style>