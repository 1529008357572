<template>
  <div>
    <b-card no-body>
      <div v-if="content" v-for="(item, index) in content" :key="'products-' + product_type + index" class="accordion"
           role="tablist">
        <b-card class="mb-1" no-body>

          <b-card-header class="p-1" header-tag="header" role="tab">
            <b-button squared v-b-toggle="'accordion-a3' + product_type + index" block :id="'xxx' + product_type" :ref="'xxx' + product_type"
                      :variant="item.products.at(0).id === activeProductId ? 'info' : 'info'">
              {{ item.title }}<br><span class="small-gray">by {{ item.publisher }}</span><br>

              <b-badge v-if="item.products.at(0).license"
                       :variant="parentIsOutdated(item.products.at(0).license.expire, item.products.at(0).licenseType)">
                <span v-if="item.products.at(0).licenseType === 'com'" class="smt10">{{ item.products.at(0).price
                  }}</span>
                <span v-if="item.products.at(0).licenseType === 'corp'" class="smt10">Corporative</span>
                <span v-if="item.products.at(0).licenseType === 'free'" class="smt10">Free</span>
              </b-badge>
              &nbsp;
              <b-badge v-if="item.products.at(0).license && product_type != 's63'"
                       :variant="parentIsOutdated(item.products.at(0).license.expire, item.products.at(0).licenseType)">
                        <span v-if="item.products.at(0).licenseType === 'com'" class="smt10">
                          {{ formatDate(item.products.at(0).license.expire) }}
                        </span>
                <span v-if="item.products.at(0).licenseType === 'corp'"
                      class="smt10">{{ formatDate(item.products.at(0).license.expire)
                  }}</span>
              </b-badge>

            </b-button>
          </b-card-header>

          <b-collapse :id="'accordion-a3' + product_type + index" accordion="my-accordion" role="tabpanel"
                      @hide="clearSelectedProducts()"
                      @show="products_selected(index, item.products.at(0).contents, item)"
          >
            <b-card-body>
              <b-card-text>
                <span class="small-gray">Content:</span><br>
                <div class="small-black" v-for="(item1, index1) in productsByProduct(item.products.at(0).contents)">
                  <div v-if="item1 === 'ENC'">
                    -
                    <b-link v-b-toggle.charts-sidebar-1 class="small-cyan">{{ item1 }}</b-link>
                  </div>
                  <div v-else>
                    - {{ item1 }}
                  </div>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>

        </b-card>
      </div>
    </b-card>
  </div>
</template>

<script>
import * as utils from "@/utils/utils";
import myAxios from "@/services/myAxios";
import {eventBus} from "@/main";
import {file} from "paths.macro";
import store from "@/store";
import {CURRENT_PRODUCT, IS_NAVICOM, NAVICOM_DATA} from "@/utils/build-config";

export default {
  data() {
    return {}
  },
  props: ["parentIsOutdated", "content", "product_type", "map"],
  created() {
  },
  computed: {
    activeProductId() {
      if (this.$store.state.manage.is_user_has_installation !== false)
        return this.$store.state.manage.appstate.programInfo.activeProductId;
      else
        return 0;
    }
  },
  mounted() {
    // TODO CATALOG
    let x = setTimeout(() => {
      if (this.$store.state.manage.is_user_has_installation !== false && this.product_type === 's63') {
        const elem = this.$refs['xxxs63'][0];
        elem.click();
      }
    }, 1)

    console.log(" mounted " + this.$store.state.current.s63_payment.payment_success);
  },
  // updated() {
  //   console.log("updated " + this.$store.state.current.s63_payment.payment_success);
  // },
  // activated() {
  //   console.log("activated " + this.$store.state.current.s63_payment.payment_success);
  // },

  watch: {
    // xxxx (newCount, oldCount) {
    //   // Our fancy notification (2).
    //   console.log(`We have ${newCount} fruits now, yay!`)
    // },
    //
    // '$store.state.current.s63_payment.payment_success': {
    //   deep: true,
    //   handler(newVal) {
    //     console.log('queryParameter changed');
    //   }
    // },

    // '$store.state.current.s63_payment.payment_success': function() {
    //   console.log("******************")
    //   console.log("******************")
    //   console.log("******************")
    //   console.log("******************")
    //   console.log(this.$store.state.current.s63_payment.payment_success)
    // }
  },
  methods: {
    clearSelectedProducts() {
      console.log("clearSelectedProducts");
      // this.$store.commit('dropS63LatestOrder');
      this.map.clearCellslayer();
    },
    productsByProduct(product) {
      let str = [];
      if (product) {
        product.forEach((prod) => {
          str.push(prod.type.toUpperCase());
        });
      }
      return str;
    },
    formatDate(date) {
      return utils.formatDate(date).date;
    },
    products_selected(index, content, item) {
//      utils.dumpFile("s63_products_selected", content);

      // let a = document.createElement("a");
      // let jsonString = [JSON.stringify(content, null, 2)];
      // let file = new Blob([jsonString], {type: 'text/plain'});
      // a.href = URL.createObjectURL(file);
      // a.download = "a.txt";
      // a.click();
      // console.log(index, content, item)



        let id = "";
        let url1 = "";
        let rec1 = "";
        content.forEach((rec) => {
          if (rec.url && rec.type === "enc") {
            url1 = rec.url;
            id = rec.url.split('enc/').pop().split('/enc_catalog.zip')[0]
            rec1 = rec;
          }
        });

      if (id != "http://empty.link") {

        this.$store.dispatch('current/addGeoContent', {s63_id: id});

        //TODO 12 // TODO 2424
        // this.$store.dispatch('getENCDetalis', id).then(() => {
        //   this.$root.$emit('bv::refresh::table', 'content-table');
        // });
      }

      this.$store.commit('current/setCurrentProduct', item.title);

      if (item.title.startsWith("Navicom") /*&& this.$store.state.current.profile.email === "joss.ru+navicom@gmail.com"*/) {
        CURRENT_PRODUCT.is_navicom = true;
      } else {
        CURRENT_PRODUCT.is_navicom = false;
      }

      if (CURRENT_PRODUCT.is_navicom) {
        eventBus.$emit('remove-primar-tiles')
        eventBus.$emit('remove-primar-controls')
      }

      if (!CURRENT_PRODUCT.is_navicom) {
        eventBus.$emit('restore-primar-tiles')
        eventBus.$emit('add-primar-controls')
      }

      // alert(CURRENT_PRODUCT.is_navicom)


      // TODO NAVICOM
      if (!(item.title == "PRIMAR Data (S63)" || item.title.startsWith("Navicom"))) {
        console.log("no primar")

        if (item.products.at(0).price ) {
        // if (item.products.at(0).price && this.parentIsOutdated(item.products.at(0).license.expire, item.products.at(0).licenseType) != 'success' ) {
          eventBus.$emit('purchasing_update', {"hide": false, "primar": false, "price": item.products.at(0).price});
        } else {
          eventBus.$emit('purchasing_update', {"hide": false, "primar": false, "price": ""});
        }

        eventBus.$emit('licensing_update', item);

        console.log('https://win1hetz.bgeo.fi:5005/run?id=' + id + "&isIdFromGc=true");

        let url = "";
        this.map.clearCellslayer();
        let map_settings = this.getChartDrawSettings(item.title);

        console.log("https://win1hetz.bgeo.fi:5005/run?id=" + id + "&isIdFromGc=true")
        myAxios.get('https://win1hetz.bgeo.fi:5005/run?id=' + id + "&isIdFromGc=true").then(resp => {
          if (resp.status === 200) {
            url = resp.data + "map/{z}/{x}/{y}";
          }
          if (resp.status === 204) {
            console.log("204 response")
          }
        });
        this.map.clearRoute();
        this.map.rmChartLayers();
        this.map.setMultiTiles(true)
      }
      else {
        // alert("primar")
        // this.$store.dispatch('current/drawing', true);
        // this.$store.dispatch('current/addLoadingMarker', "drawing");
        console.log("primar")
        eventBus.$emit('licensing_update', item);
        eventBus.$emit('purchasing_update', {"hide": false, "price": "", "primar": true})

        // this.$store.dispatch("clearECDIS")

        if (this.$store.state.manage.is_user_has_installation !== false) {
          this.$store.dispatch("getECDISSummary", {
            device_id: this.$store.state.current.profile.device_id,
            user_id: this.$store.state.current.profile.user_id,
          })

          this.$store.dispatch("getS63Summary", {
            device_id: this.$store.state.current.profile.device_id,
            user_id: this.$store.state.current.profile.user_id,
          }).then(() => {
            // if (1 != 0 && !this.$store.state.current.s63_primar.info_loaded) {
            // TODO NAVICOM alert("bbbb")
            this.$store.dispatch('getENCs').then(() => {
              console.log('init draw all cells from', file)
              this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
              this.$store.dispatch('current/drawing', false);
            });
            // } else {
            //   console.log('init draw all cells from', file)
            //   this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
            //   this.$store.dispatch('current/drawing', false);
            // }
          })
        }

        // TODO CATALOG
        if (this.$store.state.manage.is_user_has_installation === false) {
          this.$store.dispatch('getENCs').then(() => {
            console.log('init draw all cells from', file)
            this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
            this.$store.dispatch('current/drawing', false);
          });
        }
        // JJJJ


//        this.$store.dispatch('current/addLoadingMarker', "getENCs 1");
////        this.$store.dispatch('getENCs').then(() => {
          // this.$store.dispatch('current/removeLoadingMarker', "getENCs 1");
//          this.$store.dispatch('current/addLoadingMarker', "getS63Summary 1");
//           this.$store.dispatch("getS63Summary", {
//             device_id: this.$store.state.current.profile.device_id,
//             user_id: this.$store.state.current.profile.user_id,
//           }).then( () => {
//            this.$store.dispatch('current/removeLoadingMarker', "getS63Summary 1");
////            console.log('init draw all cells from', file)
////            this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
////            this.$store.dispatch('current/drawing', false);
            // this.$store.dispatch('current/removeLoadingMarker', "drawing");
////          })
        // })
      }
    },

    getChartDrawSettings(title) {
      let map_settings = {};
      map_settings.map_center = [60.00000, 5.00000];
      map_settings.map_zoom_level = 7;

      if (title.startsWith("USA")) {
        map_settings.map_center = [28.00000, -78.00000];
        map_settings.map_zoom_level = 4;
      }

      if (title.startsWith("Estonia") || title.startsWith("Latvia")) {
        map_settings.map_center = [59.00000, 24.00000];
        map_settings.map_zoom_level = 7;
      }

      if (title.startsWith("Norway")) {
        map_settings.map_center = [60.00000, 5.00000];
        map_settings.map_zoom_level = 6;
      }

      if (title.startsWith("Germany")) {
        map_settings.map_center = [54.00000, 8.00000];
        map_settings.map_zoom_level = 8;
      }

      if (title.startsWith("Sweden")) {
        map_settings.map_center = [62.00000, 17.00000];
        map_settings.map_zoom_level = 5;
      }

      if (title.startsWith("Italy")) {
        map_settings.map_center = [44.20000, 8.50000];
        map_settings.map_zoom_level = 10;
      }

      if (title.startsWith("Рыбинское")) {
        map_settings.map_center = [58.00000, 38.50000];
        map_settings.map_zoom_level = 9;
      }

      return map_settings;
    },
  },
}
</script>

<style scoped>

</style>