<template>
  <div>
    <b-table
        ref="usersTable"
        class="secondary"
        :items="visible_rows()"
        :fields="visible_fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        select-mode="single"
        selectable
        show-empty
        responsive
        sort-icon-left
        small
        hover
        @row-selected="onRowSelected"
    >
    </b-table>
  </div>
</template>

<script>
import store from "@/store";
import {eventBus} from "@/main";

export default {
  name: "Users",
  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
    }
  },
  created() {
    eventBus.$on('clearUsersTableSelection', () => {
      this.$refs.usersTable.clearSelected()
    });
  },
  computed: {
    // hide email column for own devices
    visible_fields() {
      return [
        { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'email', label: 'Email', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'ecdis_type', label: 'Type', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'device_id', label: 'Device ID', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
      ]

    }
  },
  methods: {
    visible_rows() {
      let selectedUsers = this.$store.state.distributor.users;
      let selectedAgreementId = this.$store.state.distributor.selected.agreement_id;

      if (selectedAgreementId !== "") {
        selectedUsers = selectedUsers.filter(function (e) {
          return e.agreement_id === selectedAgreementId;
        });
      }

      return selectedUsers;
    },

    onRowSelected(items) {
      console.log("bbbbbb")
      console.log(items)
      store.commit("distributor/setCurrentUser", {id: items[0].id, email: items[0].email, device_id: items[0].device_id});
    },
  }
}
</script>

<style scoped>

</style>