<template>
  <div>
    <div class="small-black">

      <div class="alert alert-warning" v-if="!selected.device_id">Please, select the device first!</div>

      <div v-if="selected.pos_datetime"><span class="small-gray">Time:</span> {{ selected.pos_datetime }} <br>
      </div>

<!--      <div v-if="selected.pos_lost"><span class="small-gray">Lost position:</span><span class="smt14"> True</span><br></div>-->
      <div v-if="selected.pos_lost"><b-icon icon="exclamation-circle" animation="throb" variant="danger"></b-icon><span class="small-gray"> POSITION LOST</span><br></div>

      <div v-if="!selected.pos_lost">
        <div v-if="selected.pos_lat"><span class="small-gray">Position:</span> {{ getCoordinates() }}<br></div>

        <div v-if="selected.pos_sog"><span class="small-gray">SOG:</span> {{ selected.pos_sog }}<br></div>
        <div v-if="selected.pos_cog"><span class="small-gray">COG:</span> {{ selected.pos_cog }}<br></div>
        <div v-if="selected.pos_hdg"><span class="small-gray">HDG:</span> {{ selected.pos_hdg }}<br></div>
        <div v-if="selected.pos_rot"><span class="small-gray">ROT:</span> {{ selected.pos_rot }}<br></div>
      </div>

      <div v-if="selected.device_id && (selected.ship_name || selected.ship_mmsi || selected.ship_length)">
        <hr/>
      </div>

      <div v-if="selected.app_active"><span class="small-gray">Active: </span>
        <font-awesome-icon class="smt4" v-if="selected.app_active" icon="fa-solid fa-check"/>
      </div>
      <div v-if="selected.ship_name"><span class="small-gray">Name:</span> {{ selected.ship_name }}<br></div>
      <div v-if="selected.ship_imo"><span class="small-gray">IMO:</span> {{ selected.ship_imo }}<br></div>
      <div v-if="selected.ship_mmsi"><span class="small-gray">MMSI:</span> {{ selected.ship_mmsi }}<br></div>
      <div v-if="selected.ship_length"><span class="small-gray">Dimensions:</span> L:{{ selected.ship_length }},
        W:{{ selected.ship_width }}, H:{{ selected.ship_height }} m<br></div>
      <div v-if="selected.ship_draught"><span class="small-gray">Draught:</span> {{ selected.ship_draught }} m<br></div>

      <div v-if="selected.device_id && selected.ship_sensore">
        <hr/>
      </div>

      <div v-if="selected.ship_sensore"><span class="small-gray">Sensore:</span> {{ selected.ship_sensore }}<br></div>

      <div v-if="selected.device_id && selected.app_route">
        <hr/>
      </div>

      <div v-if="selected.app_route"><span class="small-gray">Route:</span> {{ selected.app_route }}<br></div>

      <div v-if="selected.device_id">
        <hr/>
      </div>

      <div v-if="selected.device_id"><span class="small-gray">Device ID:</span> {{ selected.device_id }}<br></div>
      <div v-if="selected.ecids_ship_pid"><span class="small-gray">PID:</span> {{ selected.ecids_ship_pid }}<br></div>
      <div v-if="selected.user_id"><span class="small-gray">User ID:</span> {{ selected.user_id }}<br></div>
      <div v-if="selected.app_reqkey"><span class="small-gray">ReqKey:</span> {{ selected.app_reqkey }}<br></div>
      <div v-if="selected.device_email"><span class="small-gray">Email:</span> {{ selected.device_email }}<br></div>
      <div v-if="selected.device_platform"><span class="small-gray">Platform:</span> {{ selected.device_platform }}<br></div>
      <div v-if="selected.app_name"><span class="small-gray">Name:</span> {{ selected.app_name }}<br></div>
      <div v-if="selected.app_ver"><span class="small-gray">Ver:</span> {{ selected.app_ver }}<br></div>

      <div v-if="selected.app_name && (selected.app_name === 'ECDIS' || selected.app_name === 'm-Connect')">
      <div v-if="selected.device_id">
        <hr/>
      </div>

        <div v-if="selected.ecids_permit"><span class="small-gray-card">Permit:</span> {{ selected.ecids_permit }}<br></div>
        <div v-if="selected.ecids_ship_pid"><span class="small-gray-card">PID:</span> {{ selected.ecids_ship_pid }}<br></div>
        <div v-if="selected.ecids_backup_permit && selected.ecids_backup_permit.length > 0"><span class="small-gray-card">Backup permit:</span> {{ selected.ecids_backup_permit }}<br></div>
        <div v-if="selected.ecids_emails"><span class="small-gray-card">Emails:</span> {{ selected.ecids_emails }}<br></div>
        <div v-if="selected.ecids_ship_name"><span class="small-gray-card">Ship name:</span> {{ selected.ecids_ship_name }}<br></div>
        <div v-if="selected.ecids_ship_imo"><span class="small-gray-card">Ship IMO:</span> {{ selected.ecids_ship_imo }}<br></div>
        <div v-if="selected.ecids_ship_mmsi"><span class="small-gray-card">Ship MMSI:</span> {{ selected.ecids_ship_mmsi }}<br></div>
        <div v-if="selected.ecids_ship_callsign"><span class="small-gray-card">Ship Call Sign:</span> {{ selected.ecids_ship_callsign }}<br></div>
        <div v-if="selected.ecids_ship_category"><span class="small-gray-card">Ship category:</span> {{ selected.ecids_ship_category }}<br></div>
        <div v-if="selected.ecids_brand"><span class="small-gray-card">ECDIS brand:</span> {{ selected.ecids_brand }}<br></div>
        <div v-if="selected.ecids_os"><span class="small-gray-card">ECDIS OS:</span> {{ selected.ecids_os }}<br></div>
        <div v-if="selected.ecids_type"><span class="small-gray-card">ECDIS type:</span> {{ selected.ecids_type }}<br></div>
        <div v-if="selected.country"><span class="small-gray-card">Country:</span> {{ selected.country }}<br></div>


      </div>


      <div v-if="selected.device_license">
        <hr/>
      </div>

      <div v-if="selected.device_id && !selected.device_email">

        <b-container>
          <b-row>
            <b-col>
              <b-badge variant="danger"><a v-b-toggle="'collapse-unreg'" class="smt8">not registered</a>
              </b-badge>
            </b-col>
            <b-col class="col-1">
              <div align="right" class="small-cyan">
              </div>
            </b-col>
          </b-row>
        </b-container>

<!--        {{ selected.prod_title }}-->

        <b-collapse v-bind:id="'collapse-unreg'" class="mt-2">
          <b-card>
            <div>
              <details open>
                <summary>
                  <b-icon icon="check-square" variant="success"></b-icon>
<!--                  <b-icon v-if="!item1.appState" icon="square" variant="secondary"></b-icon>-->
                  {{ selected.prod_title }}
                </summary>
                <span class="small-gray">Type:</span> {{ selected.prod_licenseType }}<br>
<!--                <div v-if="item1.productInfo.licenses.at(0).issuerType === 'trial'">-->
<!--                  <span class="small-gray">Trial:</span> yes<br>-->
<!--                </div>-->
                <span class="small-gray">Provider:</span> {{ selected.prod_provider }}<br>
<!--                <span class="small-gray">Issued Date:</span> {{ formatDate(item1.productInfo.licenses.at(0).issuedDate) }}<br>-->
<!--                <span class="small-gray">Expiration Date:</span> {{ formatDate(item1.productInfo.licenses.at(0).expirationDate) }}<br>-->

                <div v-if="selected.prod_enc"><span class="small-gray">- ENCs</span><br></div>
                <div v-if="selected.prod_s102"><span class="small-gray">- S102</span><br></div>
                <div v-if="selected.prod_poi"><span class="small-gray">- POI</span><br></div>
                <div v-if="selected.prod_ntm"><span class="small-gray">- NTM</span><br></div>
                <div v-if="selected.prod_ais"><span class="small-gray">- AIS</span><br></div>
                <div v-if="selected.prod_weather"><span class="small-gray">- Weather</span><br></div>
                <div v-if="selected.prod_navMsg"><span class="small-gray">- Nav.Messages</span><br></div>
                <div v-if="selected.prod_noaaWeather"><span class="small-gray">- NOAA Weather</span><br></div>
                <div v-if="selected.prod_pollution"><span class="small-gray">- Pollution</span><br></div>
                <br>
              </details>
            </div>
          </b-card>
        </b-collapse>

      </div>

      <div v-if="selected.device_id && geocontent_list">
        <hr/>
      </div>

      <div v-if="is_geocontent_loading()">
          <hr/>
          <b-spinner small type="grow" variant="warning"></b-spinner>
          <span class="small-gray">  loading...</span>
      </div>
      <div v-else>
              <div v-if="selected.device_id && !geocontent_list">
                <hr/>
                  <b-icon icon="exclamation-circle" animation="throb" variant="warning"></b-icon>
                  NO GEOCONTENT
              </div>
      </div>

      <div v-if="selected.device_id && !geocontent_list">
<!--        <hr/>-->
<!--        <b-spinner small type="grow" variant="warning"></b-spinner>-->
<!--        <span class="small-gray">  loading...</span>-->

<!--        NO GEOCONTENT-->
      </div>
<!--      <div v-if="selected.device_id && !geocontent_list">-->
<!--        <hr/>-->
<!--        NO GEOCONTENT-->
<!--      </div>-->

      <div class=accordion>
        <div v-if="geocontent_list" v-for="(item, index) in geocontent_list_sorted()">
          <b-container>
            <b-row>
              <b-col>
                <b-badge :variant="isActiveProduct(item) ? 'success' : 'secondary'">
                  <a v-b-toggle="'collapse-' + index" class="smt8">
                    {{ item.title }}
                  </a>
                </b-badge>
              </b-col>
              <!--            <b-col class="col-1">-->
              <!--              <div align="right" class="small-cyan">-->
              <!--                <b-link @click="chartsByProfile(item.email)">-->
              <!--                  <b-icon icon="gear-fill" aria-hidden="true"></b-icon>-->
              <!--                </b-link>-->
              <!--              </div>-->
              <!--            </b-col>-->
            </b-row>
          </b-container>

          <b-collapse v-bind:id="'collapse-' + index" class="mt-2"
                      accordion="products-accordion"
                      @hide="clearSelectedProducts()"
                      @show="products_selected1(index,  item.products.at(0).contents, item.title)"
                      :visible="isActiveProduct(item)"
          >
            <b-card>
              <span class="small-gray">License ID (dev):</span> {{ item.products.at(0).id }}<br>

              <span class="small-gray">Publisher:</span> {{ item.publisher }}<br>
              <span class="small-gray">Type:</span> {{ item.products.at(0).licenseType }}<br>
              <span class="small-gray">Price:</span> {{ item.products.at(0).price }}<br>
              <div v-if="item.products.at(0).license">
                <div v-if="item.products.at(0).license.expire">
                  <span class="small-gray">Expire:</span> {{ formatDate(item.products.at(0).license.expire) }}<br>
                </div>
                <div v-if="item.products.at(0).license.deviceNum">
                  <span class="small-gray">Devices:</span> {{ item.products.at(0).license.deviceNum }}<br>
                </div>
                <div v-if="item.products.at(0).license.isValid">
                  <span class="small-gray">Valid:</span> {{ item.products.at(0).license.isValid }}<br>
                </div>

                <div v-if="item.products.at(0).contents">
                  <details>
                    <summary class="small-gray">Content:</summary>
                    <div v-for="(item1, index1) in productsByProduct(item.products.at(0).contents)">
                      {{ item1 }}
                    </div>
                  </details>
                </div>

                <!--              {{ productsByProduct(item.products.at(0).contents) }}-->

              </div>
            </b-card>
          </b-collapse>
        </div>
      </div>

      <div v-if="selected.device_id">
        <hr/>
      </div>

      <div v-if="item.email" v-for="(item, index) in emails()">
        <b-container>
          <b-row>
            <b-col>
              <b-badge :variant="hasAppstate(item.email) ? 'success' : 'secondary'"><a v-b-toggle="'collapse' + index"
                                                                                       class="smt8">{{ item.email }}</a>
              </b-badge>
            </b-col>
            <b-col class="col-1">
              <div align="right" class="small-cyan">
                <b-link @click="chartsByProfile(item.email)">
                  <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                </b-link>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-collapse v-bind:id="'collapse' + index" class="mt-2">
          <b-card>
            <!--            <b-container>-->
            <!--              <b-row>-->
            <!--                <b-col class="col-14">-->
            <div v-if="item1.productInfo" v-for="(item1, index1) in productsByEmail(item.email)">
              <details v-if="item1.productInfo" v-bind:open="item1.appState">
                <summary>
                  <b-icon v-if="item1.appState" icon="check-square" variant="success"></b-icon>
                  <b-icon v-if="!item1.appState" icon="square" variant="secondary"></b-icon>
                  {{ item1.productInfo.title }}
                </summary>
                <!--                      <div v-if="isActiveProduct(item1.email, item1.productInfo.title)">aaaa</div>-->
                <!--                      <div v-if="item1.appState">aaaa</div>-->
                <span class="small-gray">Type:</span> {{ item1.productInfo.licenseType }}<br>
                <div v-if="item1.productInfo.licenses.length > 0 && item1.productInfo.licenses.at(0).issuerType === 'trial'">
                  <span class="small-gray">Trial:</span> yes<br>
                </div>
                <div v-if="item1.productInfo.licenses.length > 0 && item1.productInfo.licenses.at(0).issuerType === 'agreement'">
                  <span class="small-gray">Agreement:</span> yes<br>
                </div>
<!--                <span class="small-gray" v-if="item1.productInfo.licenses.at(0).issuerType === 'trial'">Trial: yes</span><br>-->
<!--                <span class="small-gray">Issuer type:</span> {{ item1.productInfo.licenses.at(0).issuerType }}<br>-->
                <span class="small-gray">Provider:</span> {{ item1.productInfo.provider }}<br>
                <div v-if="item1.productInfo.licenses.length > 0">
                  <span class="small-gray">Issued Date:</span> {{ formatDate(item1.productInfo.licenses.at(0).issuedDate) }}<br>
                </div>
                <div v-if="item1.productInfo.licenses.length > 0">
                  <span class="small-gray">Expiration Date:</span> {{ formatDate(item1.productInfo.licenses.at(0).expirationDate) }}<br>
                </div>

                <div v-if="item1.productInfo.enc"><span class="small-gray">- ENCs</span><br></div>
                <div v-if="item1.productInfo.s102"><span class="small-gray">- S102</span><br></div>
                <div v-if="item1.productInfo.poi"><span class="small-gray">- POI</span><br></div>
                <div v-if="item1.productInfo.ntm"><span class="small-gray">- NTM</span><br></div>
                <div v-if="item1.productInfo.ais"><span class="small-gray">- AIS</span><br></div>
                <div v-if="item1.productInfo.weather"><span class="small-gray">- Weather</span><br></div>
                <div v-if="item1.productInfo.navMsg"><span class="small-gray">- Nav.Messages</span><br></div>
                <div v-if="item1.productInfo.noaaWeather"><span class="small-gray">- NOAA Weather</span><br></div>
                <div v-if="item1.productInfo.pollution"><span class="small-gray">- Pollution</span><br></div>
                <br>
              </details>
            </div>
          </b-card>
        </b-collapse>

      </div>
    </div>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import {uniqBy} from "lodash";
import router from "@/router";
import myAxios from "@/services/myAxios";
import {file} from "paths.macro";
import store from "@/store";

export default {
  components: {},
  data() {
    return {
      selected: {
        device_id: "",
        statistic: [],
      },
    }
  },
  props: ["geocontent_list", "map"],
  methods: {
    geocontent_list_sorted() {
      let sorted_geocontent = [];

      if(!this.geocontent_list) {
        return sorted_geocontent;
      }

      // shift active product front
      this.geocontent_list.forEach(element => {
        if (element.products.at(0).id === this.$store.state.manage.appstate.programInfo.activeProductId)
          sorted_geocontent.unshift(element);
        else
          sorted_geocontent.push(element);
      });
      return sorted_geocontent;
    },
    is_geocontent_loading() {
      return this.$store.state.current.loading.geocontent;
    },
    clearSelectedProducts() {
      // console.log("clearSelectedProducts");
      // this.map.resetAll();
    },
    isActiveProduct(item) {
      if (this.$store.state.manage.appstate) {
        return this.$store.state.manage.appstate.programInfo.activeProductId === item.products.at(0).id;
      } else {
        return false;
      }

    },
    getChartDrawSettings(title) {
      let map_settings = {};
      map_settings.map_center = [60.00000, 5.00000];
      map_settings.map_zoom_level = 7;

      if (title.startsWith("USA")) {
        map_settings.map_center = [28.00000, -78.00000];
        map_settings.map_zoom_level = 4;
      }

      if (title.startsWith("Estonia") || title.startsWith("Latvia")) {
        map_settings.map_center = [59.00000, 24.00000];
        map_settings.map_zoom_level = 7;
      }

      if (title.startsWith("Norway")) {
        map_settings.map_center = [60.00000, 5.00000];
        map_settings.map_zoom_level = 6;
      }

      if (title.startsWith("Germany")) {
        map_settings.map_center = [54.00000, 8.00000];
        map_settings.map_zoom_level = 8;
      }

      if (title.startsWith("Sweden")) {
        map_settings.map_center = [62.00000, 17.00000];
        map_settings.map_zoom_level = 5;
      }

      if (title.startsWith("Italy")) {
        map_settings.map_center = [44.20000, 8.50000];
        map_settings.map_zoom_level = 10;
      }

      if (title.startsWith("Рыбинское")) {
        map_settings.map_center = [58.00000, 38.50000];
        map_settings.map_zoom_level = 9;
      }

      return map_settings;
    },
    productContent(product) {
      let str = "";

      if (product.enc)
        str += "ENCs, ";
      if (product.s102)
        str += "S102, ";
      if (product.poi)
        str += "POI, ";
      if (product.ntm)
        str += "NTM, ";
      if (product.ais)
        str += "AIS, ";
      if (product.weather)
        str += "Weather, ";
      if (product.navMsg)
        str += "Nav.Messages, ";
      if (product.noaaWeather)
        str += "NOAA Weather, ";
      if (product.pollution)
        str += "Pollution, ";

      str = utils.truncStrng(str, 20);

      return str;
    },
    products_selected1(index, content, title) {
      if (title !== "PRIMAR Data (S63)" && this.$route.name !== 'manage') {
        let id = "";
        let url1 = "";
        let rec1 = "";
        content.forEach((rec) => {
          if (rec.type === "enc") {
            url1 = rec.url;
            id = rec.url.split('enc/').pop().split('/enc_catalog.zip')[0]
            rec1 = rec;
          }
        });

        this.$store.dispatch('getENCDetalis', id).then(() => {
          this.$root.$emit('bv::refresh::table', 'content-table');
        });
        // this.$root.$emit('bv::refresh::table', 'content-table');


        // console.log('https://win1hetz.bgeo.fi:5005/run?id=' + id + "&isIdFromGc=true");
        // this.$emit('log', "Request tiles url: " + 'https://win1hetz.bgeo.fi:5005/run?id=' + id + "&isIdFromGc=true");
        //
        // let url = "";
        // this.map.clearCellslayer();
        // let map_settings = this.getChartDrawSettings(title);
        //
        // console.log("https://win1hetz.bgeo.fi:5005/run?id=" + id + "&isIdFromGc=true")
        // myAxios.get('https://win1hetz.bgeo.fi:5005/run?id=' + id + "&isIdFromGc=true").then(resp => {
        //   if (resp.status === 200) {
        //     url = resp.data + "map/{z}/{x}/{y}";
        //     this.map.redrawMap(url, map_settings.map_center, map_settings.map_zoom_level);
        //   }
        //   if (resp.status === 204) {
        //     console.log(" 204 error")
        //   }
        // });
      }
      // else if (title === "PRIMAR Data (S63)") {
        // this.$store.dispatch('getENCs').then(() => {
        //   this.$store.dispatch("getS63Summary", {
        //     device_id: this.$store.state.current.profile.device_id,
        //     user_id: this.$store.state.current.profile.user_id,
        //   }).then( () => {
        //     console.log('init draw all cells from', file)
        //
        //     this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        //   })
        // })
      // }
    },
    chartsByProfile(email) {

      this.$store.dispatch('getUserProducts', {userid: this.selected.user_id, deviceid: this.selected.device_id}).then((data) => {
        console.log(data)
      });

      this.$store.dispatch('setProfile', {
        email: email,
        app_name: this.selected.app_name,
        device_id: this.selected.device_id,
        user_id: this.selected.user_id,
        device_os: this.selected.device_platform,
        products: this.productsByEmail(email),
        appState: this.appState
      }).then(() => {
          }
      )
      router.push({path: 'charts'})

    },
    emails() {
      return uniqBy(this.selected.products, 'email');
    },
    productsByEmail(email) {
      return utils.productsByEmail(this.selected.products, email);
    },
    productsByProduct(product) {
      let str = [];
      product.forEach((prod) => {
            // str += prod.type + ", ";
            str.push(prod.type);
            });
      return str;
    },
    hasAppstate(email) {
      let insts = this.selected.products.filter(function (e) {
        return e["appState"] != null && e["email"] === email;
      })

      return insts.length > 0;
    },
    isActiveProduct222(email, productName) {
      let insts = this.selected.products.filter(function (e) {
        return e["appState"] != null && e["email"] === email && e["productInfo.title"] === productName;
      });
      return insts.length > 0;
    },
    formatDate(date) {
      return utils.formatDate(date).date;
    },
    getCoordinates() {
      return utils.trimCoordinates(this.selected.pos_lat) + ", " + utils.trimCoordinates(this.selected.pos_lon);
    },
    activeProductId() {
      console.log(" 17171717  " + this.$store.state.manage.appstate.programInfo.activeProductId)
      console.log(" 17171717  " + this.$store.state.manage.appstate.programInfo.activeProductId)
      return this.$store.state.manage.appstate.programInfo.activeProductId;
    },

  },
  watch : {
    geocontent_list(val){

      if (val) {
        let activeOne = val.find(x => this.isActiveProduct(x));

        if (activeOne) {
          this.products_selected1('aa', activeOne.products.at(0).contents, activeOne.title);
        }
      }
    }
  },
  computed: {
    // activeProductId() {
    //   console.log(" 17171717  " + this.$store.state.manage.appstate.programInfo.activeProductId)
    //   console.log(" 17171717  " + this.$store.state.manage.appstate.programInfo.activeProductId)
    //   return this.$store.state.manage.appstate.programInfo.activeProductId;
    // },
    // emails() {
    //   //  return uniq(this.selected.products.map(({ category }) => category))
    //   let x = uniqBy(this.selected.products, 'email');
    //   console.log(" ------- ----------- ---------- -----");
    //   console.log(x);
    //   return x;
    // },
    getLicenes() {
      // let licenses = this.selected.device_license === '-1' ? "no" : "yes";
      // licenses = this.selected.device_license === '-2' ? "no" : "yes";
      // return licenses;


    },

  },
  created() {

    eventBus.$on('show_details', data => {
      // this.map.clearCellslayer();
      console.log(data)

      this.$store.commit('current/setCurrentDraught', data.ship_draught);

      this.selected.user_id = data.user_id,

      this.selected.device_id = data.device_id;
      this.selected.device_platform = data.device_platform;
      this.selected.device_email = data.device_email;
      this.selected.device_license = data.device_license;

      this.selected.prod_id = data.prod_id;
      this.selected.prod_title = data.prod_title;
      this.selected.prod_licenseType = data.prod_licenseType;
      this.selected.prod_provider = data.prod_provider;
      this.selected.prod_price = data.prod_price;
      this.selected.prod_enc = data.prod_enc;
      this.selected.prod_s102 = data.prod_s102;
      this.selected.prod_poi = data.prod_poi;
      this.selected.prod_ntm = data.prod_ntm;
      this.selected.prod_ais = data.prod_ais;
      this.selected.prod_weather = data.prod_weather;
      this.selected.prod_navMsg = data.prod_navMsg;
      this.selected.prod_noaaWeather = data.prod_noaaWeather.prod_price;
      this.selected.prod_pollution = data.prod_pollution;

      this.selected.app_name = data.app_name;
      this.selected.app_ver = data.app_ver;
      this.selected.app_reqkey = data.app_reqkey;
      this.selected.app_route = data.app_route;
      this.selected.app_active = data.app_active;

      this.selected.ship_name = data.ship_name;
      this.selected.ship_mmsi = data.ship_mmsi;
      this.selected.ship_imo = data.ship_imo;
      this.selected.ship_length = data.ship_length;
      this.selected.ship_width = data.ship_width;
      this.selected.ship_height = data.ship_height;
      this.selected.ship_draught = data.ship_draught;
      this.selected.ship_sensore = data.ship_sensore;

      this.selected.pos_hdop = data.pos_hdop;
      this.selected.pos_lat = data.pos_lat;
      this.selected.pos_lon = data.pos_lon;
      this.selected.pos_cog = data.pos_cog;
      this.selected.pos_sog = data.pos_sog;
      this.selected.pos_hdg = data.pos_hdg;
      this.selected.pos_speed = data.pos_speed;
      this.selected.pos_mac = data.pos_mac;
      this.selected.pos_rot = data.pos_rot;
      this.selected.pos_datetime = data.pos_datetime;
      this.selected.pos_date = data.pos_date;
      this.selected.pos_time = data.pos_time;
      this.selected.pos_tick = data.pos_tick;
      this.selected.pos_lost = data.pos_lost;
      this.selected.pos_satellites = data.pos_satellites;

      this.selected.ecids_permit = data.ecids_permit;
      this.selected.ecids_backup_permit = data.ecids_backup_permit;
      this.selected.ecids_emails = data.ecids_emails;
      this.selected.ecids_ship_name = data.ecids_ship_name;
      this.selected.ecids_ship_imo = data.ecids_ship_imo;
      this.selected.ecids_ship_mmsi = data.ecids_ship_mmsi;
      this.selected.ecids_ship_callsign = data.ecids_ship_callsign;
      this.selected.ecids_ship_pid = data.ecids_ship_pid;
      this.selected.ecids_ship_category = data.ecids_ship_category;
      this.selected.ecids_brand = data.ecids_brand;
      this.selected.ecids_os = data.ecids_os;
      this.selected.ecids_type = data.ecids_type;
      this.selected.country = data.country;


      this.selected.products = data.device_products;

      this.appState = {
        lat : data.lat,
            lon : data.lon,
            cog : data.cog,
            id : data.id,
            days : data.days,
            aindex : data.aindex,
            name : data.name,
            route : data.route,
            sog : data.sog,
            email : data.email,
            isActive : data.isActive,
            routeJson : data.routeJson
      }
      console.log(this.appState)
      localStorage.setItem('selected', JSON.stringify(this.selected));

      this.$store.dispatch('setProfile', {
        app_name: this.selected.app_name,
        device_id: this.selected.device_id,
        user_id: this.selected.user_id,
        email: this.selected.device_email,
        device_os: this.selected.device_platform,
        appState: this.appState
      })
    })
  },
}
</script>

<style>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
span{
  cursor: pointer;
}
</style>