import {ActiveTab} from "@/utils/config";
import myAxios from "@/services/myAxios";
import {file} from 'paths.macro';

import {addIndexToArray, currentRouterId} from '@/utils/utils';
import Vue from "vue";
import * as utils from "@/utils/utils";
import {eventBus} from "@/main";

import {DEBUG_LOCAL_RESPONSE, DEBUG_DOWNLOAD_RESPONSE, DEBUG_FILTER, IS_NP} from "@/utils/build-config"
import {getCatalog} from "@/requests/axios-requests";

// const DEBUG_LOCAL_RESPONSE = true;
// const DEBUG_DOWNLOAD_RESPONSE = false;
// const DEBUG_FILTER = false;

const initialState = {
    // active_tab: ActiveTab.OWN,
    // app_type: AppType.NP,
    is_loading: false,
    // apps_filter: "all",
    files_filter: "appstate",
    apps_filter: ["all", "np"],
    installations: [],
    user_installations:[],
    is_user_has_installation: undefined,
    appstate: {},
    products: {},
    s63_products: {},
    user_products: {},
    charts_collection: {},
    geocontent_list: {},
    selected_geocontent: [],
    files_list: {},
}

function download_json(content, fileName, contentType) {
    console.log(file, "download_json");

    if (DEBUG_DOWNLOAD_RESPONSE && process.env.NODE_ENV === "development") {
        utils.dumpFile("installation", content);
        // let a = document.createElement("a");
        // let jsonString = [JSON.stringify(content, null, 2)];
        // let file = new Blob([jsonString], {type: contentType});
        // a.href = URL.createObjectURL(file);
        // a.download = fileName;
        // a.click();
    }
}

export const manage = {
    state: initialState,
    getters: {
        // getActiveTab: state => () => {
        //     return state.active_tab;
        // }
    },
    actions: {
        // getGeoContent({commit}, payload) {
        //     // let fileid = payload.fileid;
        //     let aindex = payload.aindex;
        //     let deviceid = payload.deviceid;

        //     return getCatalog(deviceid, aindex);
        //     // return new Promise(resolve => {
        //     //     myAxios.get('api/v1/catalogue/' + deviceid).then(resp => {
        //     //         console.log(file, "getGeoContent: " + fileid + " " + aindex);
        //     //         if (resp.status == 200) {
        //     //             console.log(resp.data);
        //     //
        //     //             commit("addGeoContent", {
        //     //                 "geocontent": resp.data,
        //     //                 "aindex": aindex,
        //     //                 "deviceid": deviceid,
        //     //             });
        //     //
        //     //         }
        //     //     });
        //     // });
        //
        //     // return new Promise(resolve => {
        //     //     myAxios.get('api/v1/userfile/' + fileid).then(resp => {
        //     //         console.log(file, "getGeoContent: " + fileid + " " + aindex);
        //     //         if (resp.status == 200) {
        //     //             console.log(resp.data);
        //     //
        //     //             commit("addGeoContent", {
        //     //                 "geocontent": resp.data,
        //     //                 "aindex": aindex,
        //     //                 "deviceid": deviceid,
        //     //             });
        //     //
        //     //         }
        //     //     });
        //     // });
        //
        // },
        // getFilesList({commit}, payload) {
        //     let deviceId = payload.deviceId;
        //     let aindex = payload.aindex;
        //
        //     commit("current/pushLoadingMarker", "manage|getFilesList")
        //     return new Promise(resolve => {
        //         myAxios.get('api/v1/userfiles?deviceId=' + deviceId).then(resp => {
        //             console.log(file, "getFilesList: " + deviceId + " " + aindex);
        //             if (resp.status == 200) {
        //
        //                 let items = resp.data.userFiles.filter(function (e) {
        //                     return e.type === "geocontentcatalog";
        //                 });
        //
        //                 if (items.length > 0) {
        //
        //                     let geocontentid = items.at(0).id;
        //                     let geocontentname = items.at(0).name;
        //
        //                     // getCatalog(deviceId, aindex);
        //
        //                     // this.dispatch('getGeoContent',
        //                     //     {
        //                     //         "fileid": geocontentid,
        //                     //         "aindex": aindex,
        //                     //         "deviceid": deviceId
        //                     //     }).then(() => {
        //                     //     resolve()
        //                     // });
        //
        //                     commit("addFilesList", {
        //                         "userFiles": resp.data.userFiles,
        //                         "aindex": aindex,
        //                         "deviceId": deviceId
        //                     });
        //                 } else {
        //                     // commit("addGeoContentSDK")
        //                     console.log("aaaaaaaaaa")
        //                 }
        //             }
        //
        //             commit("current/popLoadingMarker", "manage|getFilesList")
        //             resolve();
        //         })
        //     });
        // },
        getENCDetalis({commit}, id) {
            // TODO NAVICOM alert('aaaa')
            let activeTab = currentRouterId();
            console.log(file, "getENCDetalis: " + activeTab);

            console.log("hhhhhhhhhh")
            console.log(id)

            let ENCList = []
            let getURL = "/api/v1/chartcollection/" + id + "?isIdFromGc=true";

            console.log(" ********* " + getURL);

            // if (DEBUG_LOCAL_RESPONSE) {
            //     getURL = "http://localhost:8080/dev/_collections.json";
            // }

            commit("current/pushLoadingMarker", "manage|getENCDetalis")
            return new Promise(resolve => {
                myAxios.get(getURL).then(resp => {
                    console.log(file, "getENCDetalis: " + getURL);
                    ENCList = resp.data.chartInfos;

                    commit("addChartCollections", ENCList);
                    commit("current/popLoadingMarker", "manage|getENCDetalis")
                    resolve();
                })
            });
        },
        getS63Products({commit}, collectionid) {
            let activeTab = currentRouterId();
            console.log(file, "getS63Products: " + activeTab);

            let productsList = []
            let getURL = "/api/v1/chartcollection/" + collectionid;

            return new Promise(resolve => {
                myAxios.get(getURL).then(resp => {
                    console.log(file, "getS63Products: " + getURL);
                    productsList = resp.data.chartInfos;

                    commit("addS63Products", productsList);
                    resolve();
                })
            });
        },
        getAppState({commit}, deviceid) {
            let activeTab = currentRouterId();
            console.log(file, "getAppState: " + activeTab);

            let appState = {};
            let getURL = "/api/v1/appstate/" + deviceid;

            commit("current/pushLoadingMarker", "manage|getAppState")
            return new Promise(resolve => {
                myAxios.get(getURL).then(resp => {
                    console.log(file, "getAppState: " + getURL);
                    appState = resp.data.appState;

                    commit("addAppState", appState);

                    commit("current/popLoadingMarker", "manage|getAppState")
                    resolve(appState);
                })
            });
        },
        getUserProducts({commit}, payload) {
            let activeTab = currentRouterId();
            console.log(file, "getUserProducts: " + activeTab);

            let productsList = []
            let getURL = "/api/v1/userproducts/" + payload.userid + "/" + payload.deviceid;

            commit("current/pushLoadingMarker", "manage|getUserProducts")
            return new Promise(resolve => {
                myAxios.get(getURL).then(resp => {
                    console.log(file, "getUserProducts: " + getURL);
                    productsList = resp.data.productInfos;

                    commit("addUserProducts", productsList);

                    commit("current/popLoadingMarker", "manage|getUserProducts")
                    resolve();
                })
            });
        },
        getAllProducts({commit}) {
            let activeTab = currentRouterId();
            console.log(file, "getAllProducts: " + activeTab);

            let productsList = []
            let getURL = "/api/v1/allproducts";

            commit("current/pushLoadingMarker", "manage|getAllProducts")
            return new Promise(resolve => {
                myAxios.get(getURL).then(resp => {
                    console.log(file, "getAllProducts: " + getURL);
                    productsList = resp.data.productInfos;

                    commit("addProducts", productsList);

                    commit("current/popLoadingMarker", "manage|getAllProducts")
                    resolve();
                })
            });
        },

        getInstallations({commit}) {
            let activeTab = currentRouterId();
            console.log(file, "getInstallations: " + activeTab);

            commit("current/pushLoadingMarker", "manage|getInstallations")
            return new Promise(resolve => {
                let instList = []
                if (manage.state.installations[activeTab] === undefined || manage.state.installations[activeTab].length == 0) {
                    commit("setLoadingIndicator", true); //TODO

                    let getURL = activeTab === ActiveTab.MANAGE ? "/api/v1/adm/installations" : "/api/v1/installations";
                    if (DEBUG_LOCAL_RESPONSE) {
                        getURL = activeTab !== ActiveTab.MANAGE ? "/api/v1/installations" : "http://localhost:8080/dev/_installations.json";
                    }

                    myAxios.get(getURL).then(resp => {
                        console.log(file, "getInstallations: " + getURL);

                        console.log("-----------------")
                        console.log("-----------------")
                        console.log("-----------------")
                        console.log("-----------------")
                        console.log(resp.data.userInfos)
                        instList = resp.data.userInfos;

                        // if (activeTab === ActiveTab.DEVICES) {
                        //     let items = [];
                        //     resp.data.appStates.forEach(function (appstateEntry, i, arr) {
                        //         let appRecord = {
                        //             deviceId: appstateEntry.deviceId,
                        //             email: appstateEntry.email,
                        //             platformName: appstateEntry.programInfo.platform,
                        //             appState: appstateEntry
                        //         };
                        //
                        //         items.push(appRecord);
                        //     });
                        //     instList = items;
                        // } else {
                        //     instList = resp.data.userInfos;
                        // }

                        instList = addIndexToArray(instList);
                        commit("addInstallations", instList);

                        commit("current/popLoadingMarker", "manage|getInstallations")
                        resolve();
                    })
                } else {
                    commit("current/popLoadingMarker", "manage|getInstallations")
                    resolve();
                }
            })
        },


        getInstallations1({commit}) {
            let activeTab = currentRouterId();
            console.log(file, "getInstallations: " + activeTab);

            commit("current/pushLoadingMarker", "manage|getInstallations")
            return new Promise(resolve => {
                let instList = []
                if (manage.state.installations[activeTab] === undefined || manage.state.installations[activeTab].length == 0) {
                    commit("setLoadingIndicator", true); //TODO

                    let getURL = activeTab === ActiveTab.DEVICES ? "/api/v1/appstate" : "/api/v1/adm/installations";
                    if (DEBUG_LOCAL_RESPONSE) {
                        getURL = activeTab === ActiveTab.DEVICES ? "/api/v1/appstate" : "http://localhost:8080/dev/_installations.json";
                    }

                    myAxios.get(getURL).then(resp => {
                        console.log(file, "getInstallations: " + getURL);

                        if (activeTab === ActiveTab.DEVICES) {
                            let items = [];
                            resp.data.appStates.forEach(function (appstateEntry, i, arr) {
                                let appRecord = {
                                    deviceId: appstateEntry.deviceId,
                                    email: appstateEntry.email,
                                    platformName: appstateEntry.programInfo.platform,
                                    appState: appstateEntry
                                };

                                items.push(appRecord);
                            });
                            instList = items;
                        } else {
                            instList = resp.data.userInfos;
                        }

                        instList = addIndexToArray(instList);
                        commit("addInstallations", instList);

                        commit("current/popLoadingMarker", "manage|getInstallations")
                        resolve();
                    })
                } else {
                    commit("current/popLoadingMarker", "manage|getInstallations")
                    resolve();
                }
            })
        },
        changeAppsFilter({commit}, filter) {
            return new Promise(resolve => {
                console.log(file, "changeAppsFilter: " + filter);
                commit("setAppsFilter", filter);
                // console.log(this.$store.state.current.user_type)
                // console.log(this.$store.state.current.user_type.is_mconnect)
                // if (this.$store.state.current.user_type.is_mconnect)
                eventBus.$emit('tableReady');
                resolve();
            })
        },
        changeFilesFilter({commit}, filter) {
            return new Promise(resolve => {
                console.log(file, "changeFilesFilter: " + filter);
                commit("setFilesFilter", filter);
                resolve();
            })
        },
        changeSelectedGeocontent({commit}, geocontent) {

            // utils.dumpFile("geocontent_selected", geocontent);

            return new Promise(resolve => {
                console.log(file, "changeSelectedGeocontent: " + geocontent);
                if (geocontent != null)
                    commit("setSelectedGeocontent", geocontent);

                resolve();
            })
        },
    },
    mutations: {
        // setActiveTab(state, tab) {
        //     console.log(file, "setActiveTab: " + tab);
        //     state.active_tab = tab;
        // },
        setAppsFilter(state, filter) {
            console.log(file, "setAppsFilter: " + filter);
            Vue.set(state.apps_filter, currentRouterId(), filter);
        },
        setFilesFilter(state, filter) {
            console.log(file, "setFilesFilter: " + filter);
            state.files_filter = filter;
        },
        setSelectedGeocontent(state, geocontent) {
            // let navicom = {
            //     "id": "nav_1",
            //     "products": [
            //         {
            //             "active": false,
            //             "contents": [
            //                 {
            //                     "type": "enc"
            //                 }
            //             ],
            //             "id": "nav_2",
            //             "licenseType": "com",
            //             "price": "EURO 5,00",
            //             "storeId": "navicom"
            //         }
            //     ],
            //     "publisher": "Navicom",
            //     "title": "Navicom lakes"
            // }

            // // TODO NAVICOM
            // if (!IS_NP)
            //     // alert(1)
            //     geocontent.push(navicom)

            //alert(geocontent)
            console.log(file, "setSelectedGeocontent: " + JSON.stringify(geocontent));



            state.selected_geocontent = geocontent;
        },
        setLoadingIndicator(state, flag) {
            console.log(file, "setLoadingIndicator");
            state.is_loading = flag;
        },
        // addAppState(state, payload) {
        //     let apptype = currentRouter();//state.app_type;
        //     console.log(file, "addAppState");
        //
        //     let appState = payload["appState"];
        //     let deviceId = payload["deviceID"];
        //     let index = payload["index"];
        //
        //     state.installations.at(apptype).at(index).appstate_reqkey = appState.programInfo.regKey;
        //     state.installations.at(apptype).at(index).is_appstate_loaded = true;
            //     state.installations.at(apptype).at(index).appstate_pos_time = appState.positionInfo.datetimeUtc;
            //     state.installations.at(apptype).at(index).appstate_lat = appState.positionInfo.latDegree;
            //     state.installations.at(apptype).at(index).appstate_lon = appState.positionInfo.lonDegree;
            //     state.installations.at(apptype).at(index).appstate_hdg = appState.positionInfo.courseDegree;
            //     state.installations.at(apptype).at(index).appstate_sog = appState.positionInfo.sogKnot;
            //     state.installations.at(apptype).at(index).appstate_appname = appState.programInfo.name;
            //     state.installations.at(apptype).at(index).appstate_appver = appState.programInfo.version;
            //     state.installations.at(apptype).at(index).appstate_platform = appState.programInfo.platform;
            //
            //     state.installations.at(apptype).at(index).appstate_active_route = appState.activeRoute;
            //     state.installations.at(apptype).at(index).appstate_is_active = appState.isActive;
            //     state.installations.at(apptype).at(index).appstate_ship_name = appState.shipInfo.name;
            //     state.installations.at(apptype).at(index).appstate_ship_mmsi = appState.shipInfo.mmsi;
            //
            // },

            addGeoContent(state, payload) {
                console.log(file, "addGeoContent");

                let geoconent = payload["geocontent"];
                let deviceid = payload["deviceid"];
                let aindex = payload["aindex"];

                let x = geoconent.catalog;

                // alert (x)

                // TODO Why loop?
                x.forEach(function (inst, i, arr) {
                    // let value = {
                    //     "name": inst.name,
                    //     "id": inst.id,
                    //     "type": inst.type,
                    //     "time": inst.timestamp,
                    // };
                    Vue.set(state.geocontent_list, aindex, x);
                });

                // let navicom = {
                //     "id": "nav_1234",
                //     "products": [
                //         {
                //             "active": false,
                //             "contents": [
                //                 {
                //                     "type": "enc"
                //                 }
                //             ],
                //             "id": "nav_1224",
                //             "licenseType": "com",
                //             "price": "EURO 5,00",
                //             "storeId": "navicom"
                //         }
                //     ],
                //     "publisher": "Navicom",
                //     "title": "Navicom lakes"
                // }

            },

            addGeoContentSDK(state) {
                console.log(file, "addGeoContentSDK");

                let x = [{"id":"VQbqB8p3Pm","title":"PRIMAR Data (S63)","publisher":"Primar","products":[{"id":"PG36Gdl20Z","contents":[{"type":"S63ENC","url":"6C6DC09C07CB754A11A1E49D414E"}],"licenseType":"corp","license":{"deviceNum":"1 of 1","isValid":true},"active":true}]}];

                x.forEach(function (inst, i, arr) {
                    // let value = {
                    //     "name": inst.name,
                    //     "id": inst.id,
                    //     "type": inst.type,
                    //     "time": inst.timestamp,
                    // };
                    Vue.set(state.geocontent_list, 1, x);
                });

            },

            addFilesList(state, payload) {
                console.log(file, "addFilesList");

                let userFiles = payload["userFiles"];
                let deviceId = payload["deviceId"];
                let aindex = payload["aindex"];

                // let obj = state.installations.find(o => o["aindex"] === aindex);
                // console.log(obj)

                // console.log("-------", userFiles, deviceId, aindex);

                userFiles.forEach(function (inst, i, arr) {
                    let value = {
                        "name": inst.name,
                        "id": inst.id,
                        "type": inst.type,
                        "time": inst.timestamp,
                    };
                    Vue.set(state.files_list, aindex, userFiles);
                });

            },

            // addStatistic(state, payload) {
            //     let apptype = currentRouterId();
            //     console.log(file, "addStatistic");
            //
            //     let userFiles = payload["userFiles"];
            //     let deviceId = payload["deviceID"];
            //     let index = payload["index"];
            //
            //     console.log(userFiles, deviceId);
            //
            //     userFiles.forEach(function (inst, i, arr) {
            //         // if (inst.type === "statisticsdata") {
            //         //     console.log("statistic: " + inst.name);
            //         state.installations.at(apptype).at(index).is_files_loaded = true;
            //         if (inst.type === "statisticsdata") {
            //             state.installations.at(apptype).at(index).is_stats_loaded = true;
            //         }
            //         state.installations.at(apptype).at(index).files.push({
            //             "name": inst.name,
            //             "id": inst.id,
            //             "type": inst.type
            //         });
            //         // }
            //     })
            // },
            // addMkartInstallations(state, list) {
            //     console.log(file, "addMkartInstallations");
            //
            //     state.is_loading = false;
            //     Vue.set(state.installations, AppType.MKART, list);
            //
            //     download_json(list, 'mkart_json.txt', 'text/plain');
            // },

            addChartCollections(state, list) {
                let apptype = currentRouterId();
                console.log(file, "addChartCollections");

                // console.log(list);
                state.charts_collection = list;
                // Vue.set(state.products, apptype, list);
            },
            addProducts(state, list) {
                let apptype = currentRouterId();
                console.log(file, "addProducts");

                console.log(list);
                state.products = list;
                // Vue.set(state.products, apptype, list);
            },
            addS63Products(state, list) {
                let apptype = currentRouterId();
                console.log(file, "addS63Products");

                console.log(list);
                state.s63_products = list;
                // Vue.set(state.products, apptype, list);
            },
            addAppState(state, appState) {
                let apptype = currentRouterId();
                console.log(file, "addAppState");
                state.appstate = appState;
                state.is_no_reg = appState !== null;
        },
        addUserProducts(state, list) {
            let apptype = currentRouterId();
            console.log(file, "addUserProducts");

            console.log(list);
            state.user_products = list;
            // Vue.set(state.products, apptype, list);
        },
        addInstallations(state, list) {
            let apptype = currentRouterId();
            console.log(file, "addInstallations");

            state.is_user_has_installation = list.length > 0;

            state.is_loading = false;
            Vue.set(state.installations, apptype, list);

            download_json(list, apptype + '_json.txt', 'text/plain');

            let nplist = state.installations.at(apptype);
            nplist.forEach(function (inst, i, arr) {
                Vue.set(inst, "is_files_loaded", false);
                Vue.set(inst, "is_stats_loaded", false);
                Vue.set(inst, "files", []);

                Vue.set(inst, "is_appstate_loaded", false);
                Vue.set(inst, "appstate_appname", "");
                Vue.set(inst, "appstate_appver", "");
                Vue.set(inst, "appstate_platform", "");
                Vue.set(inst, "appstate_reqkey", "");

                Vue.set(inst, "appstate_pos_time", "");
                Vue.set(inst, "appstate_lat", "");
                Vue.set(inst, "appstate_lon", "");
                Vue.set(inst, "appstate_hdg", "");
                Vue.set(inst, "appstate_sog", "");

                Vue.set(inst, "appstate_active_route", "");
                Vue.set(inst, "appstate_is_active", "");

                Vue.set(inst, "appstate_ship_name", "");
                Vue.set(inst, "appstate_ship_mmsi", "");
                Vue.set(inst, "appstate_ship_imo", "");
            })
        }
    }
}