<template>
  <div>
    <b-button size="sm" variant="success">Approve</b-button>&nbsp;
    <b-button size="sm" variant="danger">Cancel</b-button><br><br>
    <div v-for="(item, index) in currentOrder()">
      <div v-for="(cell, index) in item.maps">
        {{ cell }} <br>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {eventBus} from "@/main";

export default {
  name: "Order",
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
    currentOrder() {
      let orders = this.$store.state.distributor.order;
      let selectedOrderId = this.$store.state.distributor.selected.order_id;

      if (selectedOrderId !== "") {
        orders = orders.filter(function (e) {
          return e.id === selectedOrderId;
        });
      } else {
        orders = [];
      }

      return orders;
    }
  }

}
</script>

<style scoped>

</style>