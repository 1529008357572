import { render, staticRenderFns } from "./S63order.vue?vue&type=template&id=a317030c&scoped=true&"
import script from "./S63order.vue?vue&type=script&lang=js&"
export * from "./S63order.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a317030c",
  null
  
)

export default component.exports