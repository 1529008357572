<template>
  <b-sidebar width="700px" id="items-edit-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">EDIT ORDER</b-badge><br><br>
      <b-card v-if="data">
        <div v-for="(item, index) in data.products">
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-left"><b>{{ item.provider }}:</b></b-col>
            <b-col class="listsmall">
              <b-table striped :items="item.items" :fields="fields">
                <template #cell(edit)="data">
                  <b-icon variant="danger" icon="x" font-scale="1.5"></b-icon>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
        <b-button class="sm" variant="info" @click="finishEdit()">Finish</b-button>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
export default {
  name: "Items",
  props: ["data"],
  computed: {
    fields() {
      return [
        {key: 'edit', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'id', label: 'Item', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'duration', label: 'Period', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    }
  },
  methods: {
    finishEdit() {
      this.$root.$emit('bv::toggle::collapse', 'items-edit-sidebar');
    }
  }
}
</script>

<style scoped>

</style>