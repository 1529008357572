<template>
  <b-sidebar width="700px" id="payment-sidebar" shadow>
    <div class="px-3 py-2">
      <b-badge variant="light">PAYMENT</b-badge><br><br>
      <b-card v-if="data && data.payment && data.payment === 'agreement'">
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Payment method:</b></b-col>
          <b-col class="listsmall">agreement</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Distributor:</b></b-col>
          <b-col class="listsmall">{{ data.agreement.distributorName }}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Currency:</b></b-col>
          <b-col class="listsmall">{{ data.agreement.currency }}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Type:</b></b-col>
          <b-col class="listsmall">{{data.agreement.type}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Signed at:</b> </b-col>
          <b-col class="listsmall">{{formatDate(data.agreement.signedAt).date}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Valid till:</b> </b-col>
          <b-col class="listsmall">{{formatDate(data.agreement.validTill).date}}</b-col>
        </b-row>
        <b-row class="mb-2" v-if="is_agreement_owner">
          <b-col sm="3" class="text-sm-right"><b>Dealer %:</b> </b-col>
          <b-col class="listsmall">8.63 (of end price)</b-col>
        </b-row>
        <b-row class="mb-2" v-if="is_agreement_owner">
          <b-col sm="3" class="text-sm-right"><b>Price calculation:</b></b-col>
          <b-col>
              <span class="smt2s">
                  <b-table striped :items=price_table1></b-table>
                  <b-table striped :items=price_table2></b-table>
              </span>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="data && data.payment !== 'agreement'">
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Payment method:</b></b-col>
          <b-col class="listsmall">Stripe</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>Payment fee:</b></b-col>
          <b-col class="listsmall"></b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col sm="3" class="text-sm-right"><b>GetENC fee:</b></b-col>
          <b-col class="listsmall"></b-col>
        </b-row>
      </b-card>
    </div>
  </b-sidebar>
</template>

<script>
import {formatDate} from "@/utils/utils";

export default {
  name: "Payment",
  methods: {formatDate},
  props: ["data"],
  data() {
    return {
      price_table1: [
        {Variable: "NET_PRICE_USD", Formula: "PRIMAR_PRICE"},
        {Variable: "GP_MARGIN_USD", Formula: "1% (NET_PRICE_USD)"},
        {Variable: "GP_PRICE_USD", Formula: "NET_PRICE_USD + GP_MARGIN_USD"},
        {Variable: "GP_PRICE_RUB", Formula: "GP_PRICE_USD * (CB_USD_RUB_RATE + 3%)"},
        {Variable: "AGENT_VAT", Formula: "10% (GP_PRICE_RUB)"},
        {Variable: "AGENT_MARGIN", Formula: "8% (GP_PRICE_RUB)"},
        {Variable: "AGENT_PRICE", Formula: "GP_PRICE_RUB + AGENT_VAT + AGENT_MARGIN"},
        {Variable: "MIT_VAT", Formula: "20% (AGENT_PRICE)"},
        {Variable: "MIT_MARGIN", Formula: "7% (AGENT_PRICE)"},
        {Variable: "DEALER_MARGIN", Formula: "12% (AGENT_PRICE)"},
        {Variable: "MIT_PRICE", Formula: "MIT_VAT + MIT_MARGIN + DEALER_MARGIN"},
        {Variable: "END_PRICE", Formula: "AGENT_PRICE + MIT_PRICE"},
      ],
      price_table2: [
        {Purpose: "GeoPhone margin", Procent: "1%"},
        {Purpose: "Agent VAT", Procent: "10%"},
        {Purpose: "Agent margin", Procent: "8%"},
        {Purpose: "MIT VAT", Procent: "20%"},
        {Purpose: "MIT margin", Procent: "7%"},
        {Purpose: "Dealer margin", Procent: "12%"},
      ]
    }
  },
  computed: {
    is_agreement_owner() {
      return this.$store.state.auth.user.displayId === 74350 //TODO
    }
  }
}
</script>

<style scoped>

</style>