<template>
  <div id="app">

    <b-modal id="modal-devices" title="Help" v-model="openHelp" ok-only>
      <p class="my-4">For any questions please write us to <a :href="app_email_link()">{{ app_email() }}</a> </p>
    </b-modal>

    <b-sidebar id="sidebar-right" title="Debug" right shadow>
      <debug></debug>
    </b-sidebar>

    <b-navbar v-show="this.$route.name !== 'loader' && this.$route.name !== 'pdf'" toggleable="lg" type="dark" variant="info" position: sticky>
      <b-navbar-brand v-if="IS_MKART" href="#">mKart</b-navbar-brand>
      <b-navbar-brand v-if="IS_NP" href="#">Njord Pilot</b-navbar-brand>
      <b-navbar-brand v-if="IS_GETENC" href="#">Get ENC</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="auth.status.loggedIn && !IS_GETENC">
<!--          <loc-link v-if="is_admin" :to="'ecdis'">ECDIS</loc-link>-->
<!--          <loc-link v-if="!is_mconnect_user && !IS_NP" :to="'ecdis'">ECDIS</loc-link>-->
          <loc-link v-if="!is_mconnect_user" :to="'devices'">My Devices</loc-link>
          <loc-link v-if="!is_mconnect_user && !IS_NP" :to="'ecdis'">ECDIS</loc-link>
          <loc-link v-if="!is_mconnect_user && valid_user && is_geocontent_readay && !IS_NP" :to="'routes'">Routes</loc-link>
<!--          <loc-link v-if="valid_user && is_geocontent_readay" :to="'charts'">Charts</loc-link>-->
          <loc-link :to="'charts'">Charts</loc-link>
<!-- TODO:CATALOG          <loc-link :to="'charts'">Charts</loc-link>-->
<!--          <loc-link v-if="!is_mconnect_user" :to="'viewer'">Map Viewer</loc-link>-->
          <loc-link v-if="is_admin" :to="'transactions'">Transactions</loc-link>
          <loc-link v-if="is_admin" :to="'distributor'">My Agreements</loc-link>
          <loc-link v-if="is_admin" :to="'manage'">Manage</loc-link>
          <b-nav-item @click="help()">Help</b-nav-item>
          <b-nav-item v-if="is_admin && !IS_NP" @click="show_debug()">Debug</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav v-if="auth.status.loggedIn && IS_GETENC">
          <b-nav-item href="https://get-enc.com/">Home</b-nav-item>
          <loc-link :to="'charts'">Get ENC</loc-link>
          <loc-link :to="'devices'">Ships</loc-link>
          <loc-link :to="'transactions'">History</loc-link>
          <b-nav-item @click="help()">Help</b-nav-item>
        </b-navbar-nav>


<!--        <b-navbar-nav v-if="!auth.status.loggedIn">-->
<!--          <loc-link v-if="!is_mconnect_user" :to="'viewer'">Map Viewer</loc-link>-->
<!--        </b-navbar-nav>-->

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

<!--          <b-nav-item-dropdown text="Lang" right>-->
<!--            <b-dropdown-item href="#">EN</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">NO</b-dropdown-item>-->
<!--            <b-dropdown-item href="#">IT</b-dropdown-item>-->
<!--          </b-nav-item-dropdown>-->

          <span v-if="auth.status.loggedIn">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                {{ auth.user.email }} <span v-if="auth.user.role === 'admin'"> (admin)</span>
              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </span>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="container-fluid">
      <div v-show="alert.message" class="alert alert-danger" role="alert">
        {{ alert.message }}
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import LocLink from "@/components/LocLink";
import LocLinkDrop from "@/components/LocLinkDrop";
import Debug from "@/Debug.vue";
import {IS_GETENC, IS_NP} from "@/utils/build-config";
import axios from "axios";

export default {
  data() {
    return {
      openHelp: false
    }
  },

  components: {Debug, LocLink, LocLinkDrop},
  mounted() {
    this.$store.dispatch("current/getWebUserLocation");

  },
  computed: {
    IS_MKART() {
      return !IS_GETENC && !IS_NP
    },
    IS_GETENC() {
      return IS_GETENC
    },
    IS_NP() {
      return IS_NP
    },
    is_mconnect_user() {
      return this.$store.state.current.user_type.is_mconnect;
    },
    valid_user() {
      return this.$store.state.current.profile.email;
    },
    is_geocontent_readay() {
      return !this.$store.state.current.loading.geocontent && this.$store.state.manage.selected_geocontent;
    },
    alert() {
      return this.$store.state.alert;
    },
    auth() {
      return this.$store.state.auth;
    },
    is_admin() {
      let user = JSON.parse(localStorage.getItem('user'));

      const allowedIP = ['46.252.242.42', '87.117.58.251']

      if (user.role === "admin") {
        if (IS_NP) {
          return true;
        } else {
          if (allowedIP.includes(this.$store.state.current.webuser.ip))
            return true;
        }
      }
      return false;
    }
  },
  watch: {
    $route(to) {
      // clear alert on location change
      this.$store.dispatch("alert/clear");
    },
  },
  methods: {
    app_email_link() {
      if (!this.IS_NP)
        return "mailto:info@mkart.fi"
      else
        return "mailto:support@njordpilot.com"
    },
    app_email() {
      if (!this.IS_NP)
        return 'info@mkart.fi'
      else
        return 'support@njordpilot.com'
    },

    logout() {
      this.$store.dispatch("auth/logout");
    },
    show_debug() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    help() {
      this.openHelp = true;
    }
  },
}
</script>