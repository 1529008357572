<template>
  <div>
    <imported></imported>
    <b-sidebar width="700px" id="new-order-sidebar" shadow>
      <div class="px-3 py-2">
        <b-badge variant="light">NEW ORDER</b-badge><br><br>
        <b-card>
          <b-tabs content-class="mt-3">
            <b-tab title="Select ship" active>
              <ecdis-list></ecdis-list>
              <b-dropdown v-if="selected_ecdis !== null" split size="sm" variant="info" text="Add request">
                <b-dropdown-item @click="uploadFile()">From Primar CSV</b-dropdown-item>
                <b-dropdown-item @click="uploadFile()">From Excel</b-dropdown-item>
                <b-dropdown-item @click="addFromChart()">From Map</b-dropdown-item>
              </b-dropdown>
            </b-tab>
            <b-tab title="Add ship">
              <add-ecdis></add-ecdis>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import store from "@/store";
import EcdisList from "@/views/transactions/tabs/EcdisList.vue";
import AddEcdis from "@/views/transactions/tabs/AddEcdis.vue";
import Imported from "@/views/transactions/sidebars/Imported.vue";
import {eventBus} from "@/main";
import {CSVParse} from "@/utils/utils";

export default {
  name: "AddOrder",
  components: {AddEcdis, EcdisList, Imported},
  data() {
    return {
      selected_ecdis: null,
    }
  },
  mounted() {
    eventBus.$on('ecdis-selected', (payload) => {
      this.selected_ecdis = payload.id;
      this.$store.dispatch('transactions/selectShip', payload)
    })

    if (this.$store.state.manage.installations.length === 0) {
      this.$store.dispatch('getInstallations').then(() => {
        eventBus.$emit('installationsReceived');
        this.$root.$emit('bv::refresh::table', 'orders-ecdis-table');
      })
    }

    // this.$store.dispatch('getInstallations').then(() => {
    //   eventBus.$emit('installationsReceived');
    // })
  },
  methods: {
    addFromChart() {
      this.$root.$emit('bv::toggle::collapse', 'charts-sidebar');
      this.$router.push({name: 'charts'});
      // this.$root.$emit('bv::toggle::collapse', 'new-order-sidebar');
    },
    uploadFile() {
      let input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => {
        // getting a hold of the file reference
        let file = e.target.files[0];
        console.log(file.name)

        if (!file.name.endsWith(".csv"))
          return

        // setting up the reader
        let reader = new FileReader();
        reader.readAsText(file, 'UTF-8');

        // here we tell the reader what to do when it's done reading...
        let content;
        reader.onload = readerEvent => {
          content = readerEvent.target.result; // this is the content!
          console.log(content);

          let CSVarray = CSVParse(content);
          console.log(CSVarray)
          this.$store.dispatch("transactions/importOrder", CSVarray);
          this.$root.$emit('bv::toggle::collapse', 'imported-sidebar');
          this.$root.$emit('bv::toggle::collapse', 'new-order-sidebar');
        }
      }
      input.click();
    },
  }
}
</script>

<style scoped>

</style>