<template>
  <div id="mymap" ref="mm" :style="{ height: height + 'px', minHeight: '400px'}"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import * as utils from "@/utils/utils";
import L from "leaflet";
import {ChartsLeaf} from "@/my-leaf/charts-leaf";

export default {
  props: ['height', 'map', 'showRoute'],
  // computed: {
  //   windowHeight(){
  //     if (this.$route.name === 'data') return window.innerHeight - 150;
  //     return window.innerHeight - 90;
  //   }
  // },
  mounted() {
    this.map.init(this.$refs.mm, this.showRoute)

    let appstate = this.$store.state.charts.profile.appState;
    console.log(appstate)
    if (appstate ) {
      let lat = appstate.lat
      let lon = appstate.lon
      let cog = appstate.cog;
      let id = appstate.id;
      let days = appstate.days;
      let aindex = appstate.aindex;
      let name = appstate.name;
      let route = appstate.route;
      let sog = appstate.sog;
      let email = appstate.email;
      let isActive = appstate.isActive;
      let routeJson = appstate.routeJson
      this.map.showAppState(aindex, lat, lon, cog, id, isActive, name, route, sog, email, days, routeJson);

      if (this.$store.state.leaflet.map.lat === -1)
        this.map.paneToShip(aindex);
      // if (!localStorage.getItem('mapCenter'))
      //   this.map.paneToShip(aindex);
    }

    const pts = this.$store.state.routes.route;
    if (pts.length > 1) {
      this.map.add_pMeasure();
      this.map.pMeasure._toggleMeasure();
      this.map.pMeasure._toggleMeasure();
      // start line with first point of each polyline
      this.map.pMeasure._startLine(pts[0]);
      // add subsequent points:
      pts.forEach((point, ind) => {
        const latLng = L.latLng(point);
        this.map.pMeasure._mouseMove({latLng});
        this.map.pMeasure._currentLine.addPoint(latLng);
        // on last point,
        if (ind === pts.length - 1) {
          this.map.pMeasure._finishPolylinePath();
          this.map.pMeasure._toggleMeasure();
        }
      });
    }
    //   window.addEventListener("resize", () => {
    //     if (this.$route.name === 'data') this.windowHeight = window.innerHeight - 150;
    //     else this.windowHeight = window.innerHeight - 90;
    //   });

    //map.init(this.$refs.mm);
  },
};
</script>

<style >
#mymap {
  border: solid 1px dimgray;
}
.leaflet-interactive:hover{
  stroke: #ffa011;
  stroke-width: 4;
}
/*.leaflet-interactive{*/
/*  stroke: #000000;*/
/*  stroke-width: 1;*/
/*}*/
</style>