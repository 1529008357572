<template>
  <div>

<!--    &lt;!&ndash; agreement sidebar &ndash;&gt;-->
<!--    <b-sidebar width="700px" id="agreement-sidebar" shadow>-->
<!--      <div class="px-3 py-2">-->
<!--        <b-badge variant="light">AGREEMENT</b-badge><br><br>-->
<!--        <b-link @click="showUsers()">Users...</b-link>-->
<!--      </div>-->
<!--    </b-sidebar>-->

<!--    &lt;!&ndash; users sidebar &ndash;&gt;-->
<!--    <b-sidebar width="400px" id="users-sidebar" shadow>-->
<!--      <div class="px-3 py-2">-->
<!--        <b-badge variant="light">USERS</b-badge><br><br>-->
<!--      </div>-->
<!--    </b-sidebar>-->

    <b-table
        ref="agreementsTable"
        class="secondary"
        :items="visible_rows()"
        :fields="visible_fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        select-mode="single"
        selectable
        show-empty
        responsive
        sort-icon-left
        small
        hover
        @row-selected="onRowSelected"
    >

      <template #cell(expand)="row">
        <b-button v-if="!row.detailsShowing" class="border-0 shadow-none" size="sm" variant="outline-info" @click="row.toggleDetails">
          <b-icon icon="arrows-expand" font-scale="1.0"></b-icon>
        </b-button>
        <b-button v-else class="border-0 shadow-none" size="sm" variant="outline-info" @click="row.toggleDetails">
          <b-icon icon="arrows-collapse" font-scale="1.0"></b-icon>
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>

          <b-row class="mb-2 mt-4">
            <b-col sm="12"><b>Distributor:</b> {{row.item.distributor.title}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="12"><b>Type:</b> {{row.item.type === 'com' ? 'Commercial' : 'Service' }}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="12"><b>Signed at:</b> {{ formatDate(row.item.signedAt).date }}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="12"><b>Valid till:</b> {{ formatDate(row.item.validTill).date }}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="12"><b>Currency:</b> {{row.item.currency}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="12"><b>Dealer %:</b> 8.63 (of end price)</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="12"><b>Price calculation:</b>
              <span class="smt2s">
                <b-table striped :items=price_table1></b-table>
                <b-table striped :items=price_table2></b-table>
              </span>
              </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col>
              <details>
                <summary>{{ usersForAgreement(row.item.id).length }} ships<span class="small-black"></span>
                </summary>
                <ul v-for="(item, index) in usersForAgreement(row.item.id)">
                  <li class="smt2s">
                    {{item.shipName != undefined ? item.deviceId + ', ' + item.shipName : item.deviceId }}
                  </li>
                </ul>
              </details>
            </b-col>
          </b-row>

<!--          <b-row class="mb-2 mt-4">-->
<!--            <b-col>-->
<!--              <b-button size="sm" variant="success">Add ship</b-button>-->
<!--            </b-col>-->
<!--          </b-row>-->
        </b-card>
      </template>

      <template #cell(validTill)="data">
        {{ formatDate(data.value).date }}
      </template>

<!--      <template #cell(name)="data">-->
<!--        <b-link @click="showAgreement()">{{ data.value }}</b-link>-->
<!--      </template>-->


      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner small type="grow" variant="warning"></b-spinner>
          <span class="small-gray">  loading...</span>
        </div>
      </template>

    </b-table>
  </div>
</template>

<script>
import store from "@/store";
import {eventBus} from "@/main";
import {formatDate} from "../../utils/utils";

export default {
  name: "Agreements",
  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      isBusy: false,
      price_table1: [
          {Variable:"NET_PRICE_USD", Formula:"PRIMAR_PRICE"},
          {Variable:"GP_MARGIN_USD", Formula:"1% (NET_PRICE_USD)"},
          {Variable:"GP_PRICE_USD", Formula:"NET_PRICE_USD + GP_MARGIN_USD"},
          {Variable:"GP_PRICE_RUB", Formula:"GP_PRICE_USD * (CB_USD_RUB_RATE + 3%)"},
          {Variable:"AGENT_VAT", Formula:"10% (GP_PRICE_RUB)"},
          {Variable:"AGENT_MARGIN", Formula:"8% (GP_PRICE_RUB)"},
          {Variable:"AGENT_PRICE", Formula:"GP_PRICE_RUB + AGENT_VAT + AGENT_MARGIN"},
          {Variable:"MIT_VAT", Formula:"20% (AGENT_PRICE)"},
          {Variable:"MIT_MARGIN", Formula:"7% (AGENT_PRICE)"},
          {Variable:"DEALER_MARGIN", Formula:"12% (AGENT_PRICE)"},
          {Variable:"MIT_PRICE", Formula:"MIT_VAT + MIT_MARGIN + DEALER_MARGIN"},
          {Variable:"END_PRICE", Formula:"AGENT_PRICE + MIT_PRICE"},
      ],
      price_table2: [
          {Purpose:"GeoPhone margin", Procent:"1%"},
          {Purpose:"Agent VAT", Procent:"10%"},
          {Purpose:"Agent margin", Procent:"8%"},
          {Purpose:"MIT VAT", Procent:"20%"},
          {Purpose:"MIT margin", Procent:"7%"},
          {Purpose:"Dealer margin", Procent:"12%"},
      ]
    }
  },
  created() {
    eventBus.$on('clearAgreementsTableSelection', () => {
      this.$refs.agreementsTable.clearSelected()
    });
  },
  watch: {
    '$store.state.distributor.is_agreements_loading': function () {
      this.isBusy = this.$store.state.distributor.is_agreements_loading;
    }
  },
  mounted() {
    this.$store.dispatch("distributor/getAgreements");
    this.$store.dispatch("distributor/getUsers", 9  );
  },
  computed: {
    // hide email column for own devices
    visible_fields() {
      return [
        { key: 'expand', label: '', sortable: false, tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'distributor.title', label: 'Distributor', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        { key: 'name', label: 'Agreement', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        // { key: 'link', label: 'Link', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
        // { key: 'validTill', label: 'Valid date', sortable: true, sortDirection: 'desc', tdClass: 'small-black', thClass: 'small-gray' },
      ]

    }
  },
  methods: {
    formatDate,
    // showAgreement() {
    //   this.$root.$emit('bv::toggle::collapse', 'agreement-sidebar');
    // },
    // showUsers() {
    //   this.$root.$emit('bv::toggle::collapse', 'users-sidebar');
    // },
    visible_rows() {
      let agreements = this.$store.state.distributor.agreements;
      let distibutorId = this.$store.state.auth.user.displayId;

      // let obj = agreements.find(o => o.status === 'new');


      if (distibutorId !== "") {
        agreements = agreements.filter(function (e) {
          return e.distributor.mainUserId === distibutorId;
        });
      }

      return agreements;
    },
    onRowSelected(items) {
      store.commit("distributor/setCurrentAgreement", {id: items[0].id, name: items[0].name, distributor: items[0].distributor.title});
    },
    usersForAgreement(agreementId) {
      let users = this.$store.state.distributor.users.filter(function (e) {
        return e.agreementId === agreementId;
      });
      return users;
    },
  }
}
</script>

<style scoped>

</style>