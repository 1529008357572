<template>
  <div>
      <b-sidebar width="700px" id="select-ship-sidebar" shadow>
      <b-card>
        <b-tabs content-class="mt-3" v-model="selected_tab">
          <b-tab title="Ships" active>
            <ecdis-list ref="ecdis-list" ship_type="ecdis"></ecdis-list>
            <b-row>
              <b-col>
                <b-button size="sm" variant="info" @click="shipSelected('ecdis-list')">Done</b-button>&nbsp;
              </b-col>
              <b-col class="text-sm-right">
                <b-button size="sm" variant="info" @click="showNewShipTab()" >Add Ship</b-button>
              </b-col>
            </b-row>

<!--            <b-dropdown v-if="selected_ecdis" split size="sm" variant="info" text="Add request">-->
<!--              <b-dropdown-item @click="uploadFile()">From Primar CSV</b-dropdown-item>-->
<!--              <b-dropdown-item @click="uploadFile()">From Excel</b-dropdown-item>-->
<!--              <b-dropdown-item @click="addFromChart()">From Map</b-dropdown-item>-->
<!--            </b-dropdown>-->
          </b-tab>
          <b-tab title="Devices">
            <devices-list ref="devices-list" ship_type="devices"></devices-list>
            <b-button size="sm" variant="info" @click="shipSelected('devices-list')">Done</b-button>
          </b-tab>
          <b-tab title="Add ship" :title-item-class="show_add_ship_tab">
            <add-ecdis></add-ecdis>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-sidebar>


    <b-modal id="modal-primar-error" title="Error" v-model="network.isError" ok-only>
      <p class="my-4">Error {{network.code}}: chart purchasing in temporary unavailable. Try reload the page later. </p>
    </b-modal>

    <b-modal v-if="!IS_NP()" id="modal-charts" title="Hint" v-model="showHint" ok-only @ok="okHandler">
      <p class="my-4">To purchase Primar maps press <b>New order</b> and select any preferable method (e.g. manual cell selection).</p>
      <img src="https://mkartweb.com/pics/hint_02.png" width="200">
      <p class="my-4">For any questions please write us to <a href="mailto:support@mkart.fi">support@mkart.fi</a> </p>
    </b-modal>

<!-- CATALOG   <div v-if="!hasData" class="alert alert-warning">-->
<!--      Please select the device with geo content and a registered user first!-->
<!--    </div>-->

    <!-- sidebar ------------------------------------------>
    <b-sidebar width="800px" id="charts-sidebar-1" shadow>
      <div class="px-3 py-2">
        <b-table id="content-table" class="secondary text-nowrap mt-3" tbody-class="small-black" thead-tr-class="small-gray"
                 sticky-header="550px"
                 responsive hover
                 :fields="['name', 'description', 'edition', 'update', 'releaseDate', 'scale', 'band']"
                 :items=charts_collection>

        </b-table>
      </div>
    </b-sidebar>

    <b-container fluid>
      <b-row>

<!--        <b-col cols="2">-->
<!--          <b-badge variant="light">PRODUCTS</b-badge>-->
<!--          <br><br>-->
<!--          <div class="border-right" style="height: 800px; overflow-y: scroll;">-->
            <!-- mydata ------------------------------------------>
<!--            <products v-if="mydata_list.length > 0" :map=map :parentIsOutdated="isOutdated"-->
<!--                      :content="mydata_list" :product_type="'user'">-->
<!--            </products>-->
            <!--                <hr v-if="mydata_list.length > 0">-->

            <!-- s63 --------------------------------------------->
<!--            <products v-if="s63_list.length > 0" :map=map :parentIsOutdated="isOutdated" :content="s63_list"-->
<!--                      :product_type="'s63'">-->
<!--            </products>-->
            <!--                <hr v-if="s63_list.length > 0">-->

            <!-- products ---------------------------------------->
<!--            <products v-if="products_list.length > 0" :map=map :parentIsOutdated="isOutdated"-->
<!--                      :content="products_list" :product_type="'prods'">-->
<!--            </products>-->
            <!--                <hr v-if="products_list.length > 0">-->
<!--          </div>-->
<!--        </b-col>-->

        <b-col cols="4">

<!--          <b-form-select-->
<!--              v-model="selectedProduct"-->
<!--              :options="options"-->
<!--              class="mb-3"-->
<!--              value-field="item"-->
<!--              text-field="name"-->
<!--              disabled-field="notEnabled"-->
<!--          ></b-form-select>-->


          <b-badge v-if="$store.state.proto.order_number" variant="light">ORDER №{{ $store.state.proto.order_number }}</b-badge>
          <b-badge v-else variant="light">NEW ORDER</b-badge>
          <br><br>
<!--          <b-button @click="demo">Demo</b-button>-->
          <b-row>
            <div>
              <b-button block v-if="$store.state.proto.order.step === ''" size="sm" variant="danger" v-b-toggle.select-ship-sidebar>Click to select a ship</b-button>
              <purchasing @purchase-clicked=onOrderCreation :license="licensing"
                          :parentIsOutdated="isOutdated"
                          :map=map
              ></purchasing>
            </div>
          </b-row>
<!--          <b-row v-if="$store.state.manage.is_user_has_installation !== false" >-->
<!--            &lt;!&ndash;              <b-row>&ndash;&gt;-->
<!--            <licensing></licensing>-->
<!--          </b-row>-->
<!--          <b-row v-else>-->
<!--            <div class="alert alert-warning">You haven't any installations. Please use the same credentials to login in an app first to purchase maps. </div>-->
<!--          </b-row>-->

        </b-col>

        <b-col cols="8">

          <!--          <b-row class="mb-2 small-cyan">-->
          <!--            <b-col>-->
          <!--              <b>Selected ship:</b>-->
          <!--            </b-col>-->
          <!--            <b-col>-->
          <!--              {{ $store.state.transactions.selected_ship.name }}-->
          <!--            </b-col>-->
          <!--          </b-row>-->
          <b-row>
            <b-col>
              <b-badge v-if="get_order" variant="light"><h5>{{ get_order }}</h5></b-badge>
              <br><br>
              <div>
                <div>
                  <my-map :map=map :show-route=haveRoute :height="mapHeight"></my-map>
                </div>
              </div>
            </b-col>
          </b-row>



        </b-col>



<!--        <b-col cols="5">-->
<!--          <b-row>-->
<!--            <b-col cols="6">-->
<!--              <b-badge variant="light">PRODUCTS</b-badge>-->
<!--              <br><br>-->
<!--              <div class="border-right" style="height: 800px; overflow-y: scroll;">-->
<!--                &lt;!&ndash; mydata &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--                <products v-if="mydata_list.length > 0" :map=map :parentIsOutdated="isOutdated"-->
<!--                          :content="mydata_list" :product_type="'user'">-->
<!--                </products>-->
<!--&lt;!&ndash;                <hr v-if="mydata_list.length > 0">&ndash;&gt;-->

<!--                &lt;!&ndash; s63 -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--                <products v-if="s63_list.length > 0" :map=map :parentIsOutdated="isOutdated" :content="s63_list"-->
<!--                          :product_type="'s63'">-->
<!--                </products>-->
<!--&lt;!&ndash;                <hr v-if="s63_list.length > 0">&ndash;&gt;-->

<!--                &lt;!&ndash; products &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--                <products v-if="products_list.length > 0" :map=map :parentIsOutdated="isOutdated"-->
<!--                          :content="products_list" :product_type="'prods'">-->
<!--                </products>-->
<!--&lt;!&ndash;                <hr v-if="products_list.length > 0">&ndash;&gt;-->
<!--              </div>-->
<!--            </b-col>-->

<!--            <b-col cols="6">-->
<!--              <b-badge variant="light">LICENSING</b-badge>-->
<!--              <br><br>-->
<!--              <b-row v-if="$store.state.manage.is_user_has_installation !== false" >-->
<!--&lt;!&ndash;              <b-row>&ndash;&gt;-->
<!--                <licensing></licensing>-->
<!--              </b-row>-->
<!--              <b-row v-else>-->
<!--                <div class="alert alert-warning">You haven't any installations. Please use the same credentials to login in an app first to purchase maps. </div>-->
<!--              </b-row>-->
<!--              <b-row>-->
<!--                <div>-->
<!--                  <purchasing @purchase-clicked=onOrderCreation :license="licensing"-->
<!--                              :parentIsOutdated="isOutdated"-->
<!--                              :map=map-->
<!--                  ></purchasing>-->
<!--                </div>-->
<!--              </b-row>-->
<!--            </b-col>-->
<!--          </b-row>-->
<!--        </b-col>-->
<!--        <b-col cols="7">-->
<!--          <b-badge variant="light">MAP</b-badge>-->
<!--          <br><br>-->
<!--          <div>-->
<!--            <div>-->
<!--              <my-map :map=map :show-route=haveRoute :height="mapHeight"></my-map>-->
<!--            </div>-->
<!--          </div>-->
<!--        </b-col>-->

      </b-row>
    </b-container>
  </div>
</template>

<script>
import Products from "@/views/charts/Products";
import Licensing from "@/views/charts/Licensing";
import Purchasing from "@/views/charts/Purchasing";
import ChartsMap from "@/views/charts/ChartsMap.vue";
import * as utils from "@/utils/utils";
import {ChartsLeaf} from "../../my-leaf/charts-leaf";
import {file} from "paths.macro";
import {eventBus} from "@/main";
import {IS_NP} from "@/utils/build-config";
import EcdisList from "@/views/transactions/tabs/EcdisList.vue";
import DevicesList from "@/views/transactions/tabs/DevicesList.vue";
import AddEcdis from "@/views/transactions/tabs/AddEcdis.vue";
import {getAgreementData} from "@/requests/axios-requests";

export default {
  components: {AddEcdis, EcdisList, DevicesList, Products, Licensing, Purchasing, MyMap: ChartsMap},
  data() {
    return {
      selectedProduct: 'Primar',
      options: [
        { item: 'Primar', name: 'Primar charts' },
        { item: 'Navicom', name: 'Navicom charts' },
      ],

      show_add_ship_tab: "d-none",
      selected_tab: 1,

      agreement: null,

      selected_ecdis: null,
      showHint: false,

      network: {
        isError: false,
        code: '',
      },

      // openDialog: true,
      map: new ChartsLeaf(true),
      // haveRoute: this.$store.state.charts.profile.appState.routeJson > 0,
      haveRoute: false,
      log: "",
      chart_types: [
        {title: "Products"},
        {title: "S63/Primar"},
      ],
      licensing: {},
      selected: 0,
      mapHeight: window.innerHeight / 1.2,
      bagSelected: false
    }
  },
  computed: {
    get_order() {
      let val = "";
      if (this.$store.state.proto.order.order_number) {
        val = "Order №" + this.$store.state.proto.order.order_number + " (" + this.$store.state.proto.order.order_date + "), Ship: " + this.$store.state.proto.order.ecdis_name
      }
      return val;

    },
    is_orders_loading() {
      return this.$store.state.current.loading.orders;
    },
    is_primar_loading() {
      return this.$store.state.current.loading.primarInfo;
    },
    is_drawing() {
      return this.$store.state.current.loading.drawing;
    },
    geocontent_list() {
      utils.dumpFile("selected_geocontent", this.$store.state.manage.selected_geocontent)
      return this.$store.state.manage.selected_geocontent;
    },
    products_list() {
      let items = [];

      utils.dumpFile("selected_geocontent", this.$store.state.manage.selected_geocontent)

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] != "PRIMAR Data (S63)" && e["title"] != "User's Data";
        });
      }
      return items;
    },
    s63_list() {
      let items = [];

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] === "PRIMAR Data (S63)";
        });
      }
      return items;
    },
    mydata_list() {
      let items = [];

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] === "User's Data";
        });
      }
      return items;
    },
    charts() {
      return this.$store.state.charts.s63.items;
    },
    hasData() {
      // alert(this.$store.state.current.profile.device_id.length, this.$store.state.current.profile.email.length, this.$store.state.manage.selected_geocontent)
      return this.$store.state.current.profile.device_id.length > 0 &&
          this.$store.state.current.profile.email.length > 0 &&
          this.$store.state.manage.selected_geocontent;
    },
    // selectedDevice() {
    //   return this.$store.state.devices.selectedDevice;
    // },
    // selectedGeoContent() {
    //   return this.$store.state.manage.selected_geocontent;
    // },
  },
  watch: {
    tab_selected(value) {

    }
  },
  methods: {
    demo() {
      console.log("init map")
      eventBus.$emit('restore-primar-tiles')
      eventBus.$emit('add-primar-controls')

      this.$store.dispatch('getENCs').then(() => {
        console.log('init draw all cells from', file)
        this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        this.$store.dispatch('current/drawing', false);
      });

    },
    showNewShipTab() {
      this.show_add_ship_tab = ''
      this.selected_tab = 2
    },
    shipSelected(type) {
      this.$refs[type].onDone()

      // let selectedShip = this.$refs["ecdis-list"].ship
      // this.$store.dispatch('proto/updateOrder',
      //     {
      //       device_id: selectedShip.device_id,
      //       order_number: selectedShip.order_number,
      //       order_date: selectedShip.order_date,
      //       ecdis_name: selectedShip.ecdis_name,
      //       ecdis_imo: selectedShip.ecdis_imo,
      //       application_id: selectedShip.application_id
      //     })


      eventBus.$emit('set-new-ship-text-on-chart', this.$store.state.proto.order.ecdis_name)
      eventBus.$emit('set-new-order-text-on-chart', {order: this.$store.state.proto.order.order_number, order_date: this.$store.state.proto.order.order_date})
      eventBus.$emit('ship-selected-from-chart')
      this.$store.dispatch('proto/updateOrder', {step: "creating"})

      // eventBus.$emit('can-select-charts')
      //     , () => {
      //   orderTextOnChart.updateContent("<span class='chart-text'>&nbsp;Order №1248 (20.20.2022)&nbsp;</span>")
      // })
      this.$root.$emit('bv::toggle::collapse', 'select-ship-sidebar');

      // getAgreementData(selectedShip.device_id, selectedShip.application_id, null, this);
      // console.log("77777777777")
      // console.log("77777777777")
      // console.log("77777777777")
      // console.log("77777777777")
      // console.log(this.agreement)

    },
    aaaa() {
      console.log("aaaaa")
      this.$root.$emit('bv::toggle::collapse', 'select-ship-sidebar');
    },
    IS_NP() {
      return IS_NP
    },
    productsByProduct(product) {
      let str = [];

      if (product) {
        product.forEach((prod) => {
          // str += prod.type + ", ";
          str.push(prod.type);
        });
      }
      return str;
    },
    formatDate(date) {
      return utils.formatDate(date).date;
    },
    onOrderCreation(payload) {
      let keys = [];

      this.charts.forEach(item => {
        console.log(item)
        keys.push({id: item.name, monthCount: "" + item.period, price: "" + item.price});
      })
      this.$store.dispatch('addS63Order', {keys: keys, price: payload.price, uuid: payload.uuid});
      this.$store.dispatch('getENCs').then(() => {
        this.$store.dispatch("getS63Summary", {
          device_id: this.$store.state.current.profile.device_id,
          user_id: this.$store.state.current.profile.user_id,
        }).then(() => {
          console.log('init draw all cells from', file)

          this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        })
      })
    },
    allProducts() {
      let all_prods = this.$store.state.manage.products;
      let prods = this.$store.state.charts.profile.products;

      var dataArray = [];

      prods.forEach((inst) => {
        // console.log(inst.productInfo.productId)
        dataArray.push(inst.productInfo.productId);

        all_prods = all_prods.filter(function (e) {
          return e["productId"] != inst.productInfo.productId && e["licenseType"] != "corp";
        });
      });

      // all_prods = all_prods.filter(function (e) {
      //   return e["licenseType"] != "corp";
      // });

      return all_prods;

    },
    charts_collection() {
      return this.$store.state.manage.charts_collection;
    },
    // products() {
    //   return this.$store.state.charts.profile.products;
    // },
    productContent(product) {
      let str = "";

      if (product.enc)
        str += "ENCs, ";
      if (product.s102)
        str += "S102, ";
      if (product.poi)
        str += "POI, ";
      if (product.ntm)
        str += "NTM, ";
      if (product.ais)
        str += "AIS, ";
      if (product.weather)
        str += "Weather, ";
      if (product.navMsg)
        str += "Nav.Messages, ";
      if (product.noaaWeather)
        str += "NOAA Weather, ";
      if (product.pollution)
        str += "Pollution, ";

      str = utils.truncStrng(str, 20);

      return str;
    },
    isOutdated(date, type) {
      if (type.toLowerCase() === "free") {
        return "success";
      }
      let days = utils.daysOldWithSign(date);
      return days <= 0 ? "danger" : "success";
    },
    okHandler() {
      // this.$store.commit("current/setHintsCharts", false)
      localStorage.setItem('disable-charts-hint', "true") ;
    },
    // clearECDIS() {
    //   this.$store.dispatch("clearECDIS")
    // },

  },
  created() {
  },
  mounted() {

    this.demo()

    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      console.log("-00000000000000000-")
      console.log('collapseId:', collapseId)
      console.log('isJustShown:', isJustShown)
    })

   this.$store.dispatch("proto/getInstallations")

    // eventBus.$emit("set-new-order-text-oon-chart")

    eventBus.$on('select-ship-from-chart', () => {
      console.log("clicked got")
      this.$root.$emit('bv::toggle::collapse', 'select-ship-sidebar');
      this.$root.$emit('bv::toggle::collapse', 'aaa');
      // this.$emit('bv::toggle::collapse', 'select-ship-sidebar');
    })

    this.showHint = localStorage.getItem('disable-charts-hint') === null ? true : false ;
    console.log(this.showHint)
    // localStorage.removeItem('disable-charts-hint');


    // let x = localStorage.getItem('charts-hint');
    // console.log(x)
    // localStorage.setItem('charts-hint', true);
    // x = localStorage.getItem('charts-hint');
    // console.log(x)
    // localStorage.removeItem('charts-hint');
    // x = localStorage.getItem('charts-hint');
    // console.log(x)


    eventBus.$on('network-error', (value) => {
      this.network.isError = true;
      this.network.code = value;
    })


    // this.$store.dispatch("clearECDIS")
    console.log(this.$store.state.current.profile.device_id)
    console.log(this.$store.state.current.profile.user_id)

    // if (this.$store.state.manage.is_user_has_installation !== false) {
    //   this.$store.dispatch('getS63OrdersList', {
    //     device_id: this.$store.state.current.profile.device_id,
    //     user_id: this.$store.state.current.profile.user_id
    //   }).then(() => {
    //     console.log(" getS63OrdersList finished")
    //   });
    // }


  //
  //   console.log(" ************ ")
  //   console.log(" getS63Summary started")
  //   this.$store.commit("current/pushLoadingMarker", "charts|AAAA2")
  //   this.$store.dispatch('getS63Summary', {
  //     device_id: this.$store.state.current.profile.device_id,
  //     user_id: this.$store.state.current.profile.user_id
  //   }).then(() => {
  //     console.log(" ************ ")
  //     console.log(" getS63Summary finished")
  //
  //     this.$store.dispatch('current/loadingOrders', false);
  //     this.$store.dispatch('current/removeLoadingMarker', "orders|mounted");
  //   });


    // this.orto = setInterval(() => {
    //   this.$store.dispatch('getS63OrdersList', {device_id: this.$store.state.current.profile.device_id,
    //     user_id: this.$store.state.current.profile.user_id});
    // },
    //     30000
    // )
  },
  beforeDestroy() {
    this.map.clearCellslayer()
    clearInterval(this.orto);
    this.$store.commit("setAllSummaryClear");
  }
}
</script>

<style scoped>
</style>
